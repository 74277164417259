import * as React from "react"

const MessagesIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={250}
    height={229}
    fill="none"
    {...props}
  >
    <circle cx={124.899} cy={120.52} r={90.909} fill="#EAE4FF" />
    <circle cx={39.688} cy={59.137} r={5.698} fill="#DACFFD" />
    <circle cx={80.869} cy={22.619} r={10.619} fill="#DACFFD" />
    <circle cx={41.501} cy={179.054} r={10.101} fill="#DACFFD" />
    <circle cx={222.283} cy={169.212} r={10.101} fill="#DACFFD" />
    <circle cx={202.599} cy={180.608} r={4.921} fill="#DACFFD" />
    <path
      fill="#6542DD"
      d="M124.057 41.027c-.58-.123-.58-.95 0-1.074l3.156-.671a.548.548 0 0 0 .422-.42l.683-3.15c.125-.576.948-.576 1.073 0l.683 3.15a.55.55 0 0 0 .422.42l3.156.671c.58.123.58.95 0 1.074l-3.156.671a.55.55 0 0 0-.422.421l-.683 3.149c-.125.577-.948.577-1.073 0l-.683-3.149a.548.548 0 0 0-.422-.42l-3.156-.672ZM17.576 87.587c.224-1.057 1.733-1.057 1.956 0l1.196 5.648a1 1 0 0 0 .765.77l5.63 1.226c1.05.229 1.05 1.726 0 1.954l-5.63 1.227a1 1 0 0 0-.765.77l-1.196 5.648c-.223 1.057-1.732 1.057-1.956 0l-1.196-5.648a1 1 0 0 0-.765-.77l-5.63-1.227c-1.05-.228-1.05-1.725 0-1.954l5.63-1.226a1 1 0 0 0 .765-.77l1.196-5.648ZM205.673 113.487c.223-1.057 1.733-1.057 1.956 0l.474 2.24c.082.385.382.686.766.77l2.27.495c1.05.228 1.05 1.725 0 1.954l-2.27.494a1.002 1.002 0 0 0-.766.77l-.474 2.24c-.223 1.057-1.733 1.057-1.956 0l-.474-2.24a1 1 0 0 0-.766-.77l-2.27-.494c-1.05-.229-1.05-1.726 0-1.954l2.27-.495a1 1 0 0 0 .766-.77l.474-2.24ZM227.027 138.045l1.586 7.494 7.387 1.609-7.387 1.61-1.586 7.494-1.587-7.494-7.387-1.61 7.387-1.609 1.587-7.494Z"
    />
    <path
      fill="#F2EEFF"
      d="M193.793 46.705 85.272 161.183l12.432 48.951 8.547-41.181 87.542-122.248Z"
    />
    <path
      stroke="#6542DD"
      strokeWidth={3}
      d="m84.754 160.666 12.69 50.505 8.03-41.958M97.186 210.135l32.634-30.821"
    />
    <path
      fill="#DACFFD"
      stroke="#6542DD"
      strokeWidth={3}
      d="m193.793 46.705-87.801 121.989 68.894 30.821 18.907-152.81ZM193.793 45.67 31.4 137.874l53.354 22.792L193.793 45.67Z"
    />
    <path
      fill="#6542DD"
      d="m131.374 180.359-25.016-10.888-9.172 41.958 34.188-31.07Z"
    />
  </svg>
)
export default MessagesIcon
