import axios from "axios";
import {API_URL} from "../constants";

export const apiApplicationGetData = (
  token: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.get(`${API_URL}/v3/web/hey/application.get_data`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}
