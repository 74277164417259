import React from 'react'
import {useSelector} from "react-redux";
import {apiProfileBlock} from "../../../actions/profileActions";

type ProfileBlockAreaProps = {
  block: 0 | 1
  view: 0 | 1
  onChangeBlock: (block: 0 | 1) => void,
  onChangeProfileView: (view: 0 | 1) => void
  profileId: number
}

export const ProfileBlockArea = ({view, onChangeBlock, onChangeProfileView, profileId}: ProfileBlockAreaProps) => {

  const token = useSelector(state => state.user.token)

  const openView = () => {
    onChangeProfileView(1)
  }

  const unblock = () => {

    if (token) {
      apiProfileBlock(token, profileId)
      onChangeBlock(0)
    }
  }

  return (
    <div className="profile-page-block-area">
      {view === 0 && (
        <span className="profile-page-block-area-text">Do you still want to view this profile? Viewing profile won’t unblock Jane Cooper.</span>
      )}
      <div className="profile-page-block-area-bottom">
        <button
          onClick={unblock}
          className="profile-page-block-area-unblock-button profile-border-color profile-text-color"
        >
          Unblock
        </button>
        {view === 0 && (
          <button
            className="profile-page-block-area-view-button profile-background-color profile-border-color"
            onClick={openView}
          >
            View profile
          </button>
        )}
      </div>
    </div>
  )

}
