import * as React from "react"
const BlockIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <g
      stroke="#433F4E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipPath="url(#a)"
    >
      <path d="M10.5 12.306a5.646 5.646 0 0 0 3.681 3.698m4.935-.767a5.63 5.63 0 0 0 2.038-6.546 5.632 5.632 0 0 0-5.792-3.664A5.627 5.627 0 0 0 11.25 7.42M20.001 21c-.5-.5-.785-1-1.334-1h-5.334A5.333 5.333 0 0 0 8 25.333s.002 2.334.501 2.667c4.498 3.003 12.254 2 15.254 0 .5-.334.245-2.667.245-2.667 0-.558.001-.333-.499-.833M4 5l24 24" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default BlockIcon
