import React, {useState} from "react";
import './BlockModal.scss'
import BlockIcon from "./icons/BlockIcon";
import {USER_PROFILE} from "../../types/models/profile";
import {apiProfileBlock} from "../../actions/profileActions";
import {useSelector} from "react-redux";

type BlockModalProps = {
  profile: USER_PROFILE,
  onCancel: () => void,
  onChangeBlock: (blocked: 0 | 1) => void
}

export const BlockModal = ({profile, onCancel, onChangeBlock}: BlockModalProps) => {

  const token = useSelector(state => state.user.token)

  const submit = async () => {

    if (token) {
      apiProfileBlock(token, profile.id)
      onChangeBlock(profile.block === 1 ? 0 : 1)
    }

  }

  return (
    <div className="block-modal">
      <div className="block-modal-icon">
        <BlockIcon/>
      </div>
      <span className="block-modal-title">Do you want to block {profile.name}?</span>
      <span className="block-modal-text">{profile.name} won’t be able to find and view your profile send you messages.</span>
      <div className="modal-bottom">
        <button className="modal-cancel-button profile-border-color profile-text-color" onClick={onCancel}>Cancel</button>
        <button className="modal-submit-button profile-background-color" onClick={submit}>Block</button>
      </div>
    </div>
  )

}
