export const countDistance = (distance: number) => {

  let feet = distance * 5280;

  if (feet < 250) {
    return '<250 feet';
  }

  if (feet < 5280) {
    return parseInt(feet.toString()) + ' feet';
  }

  let miles = feet / 5280;

  if (miles < 20) {
    return miles.toFixed(1) + ' mi';
  }

  return parseInt(miles.toString()) + ' mi';

}
