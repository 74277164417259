import {useEffect, useState} from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SmallProfile from "../../components/SmallProfile/SmallProfile";
import useBreakpoints from '../../hooks/useBreakpoints';
import './ChatPage.scss';
import { ChatPageTop } from "./components/ChatPageTop";
import { ChatPageWindow } from "./components/ChatPageWindow";
import ChatSendArea from "./components/ChatSendArea";

export function ChatPage() {

  const navigate = useNavigate()

  const chats = useSelector(state => state.chat.chats)
  const chatId = useSelector(state => state.chat.activeChatId)
  const chat = chats.find(_chat => _chat.id === chatId)

  useEffect(() => {

    if (chat === undefined) {
      navigate('/messages')
    }

  }, [chat]);


  const { xlUp } = useBreakpoints();

  return (
    <div className="chat-page">
      <div className="chat-page-content">
        <ChatPageTop/>
        <ChatPageWindow/>
        <ChatSendArea/>
      </div>
      {xlUp && (
        <div className="chat-page-right">
          {chat && chat.profile && (
            <SmallProfile profileId={chat.profile.id}/>
          )}
        </div>
      )}
    </div>
  )

}
