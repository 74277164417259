import { useEffect, useState } from 'react';
import { BeforeInstallPromptEvent } from 'react-pwa-add-to-homescreen/lib/esm/interfaces';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes
} from "react-router-dom";
import './App.scss';
import { userSession } from "./actions/userActions";
import { Lightbox } from './components';
import Auth from "./components/Auth/Auth";
import NoAuth from "./components/NoAuth/NoAuth";
import useBreakpoints from './hooks/useBreakpoints';
import MainLayout from './layouts/MainLayout';
import {
  BlockedUsers,
  ChatPage,
  EditProfile,
  FavoritesUsers,
  Messages,
  MyProfile,
  Notifications,
  ResetPassword,
  Settings,
  SignUp,
  Stats,
  UserPage,
  Users
} from "./pages";
import { OnBoarding } from "./pages/OnBoarding/OnBoarding";
import { RestorePassword } from "./pages/RestorePassword/RestorePassword";
import { SignIn } from "./pages/SignIn/SignIn";
import { store } from "./reducers/store";
import { userSignInAction } from "./reducers/user";
import getUserAgentInfo from './utils/getUserAgentInfo';
import InstallAppModal from './components/InstallAppModal';
import UAParser from 'ua-parser-js';

const info = getUserAgentInfo();
const parser = new UAParser();

function App() {
  const [loading, setLoading] = useState(true);

  const { lgUp } = useBreakpoints();

  useEffect(() => {
    checkSession()
  }, [])

  const checkSession = async () => {

    const session = await userSession();
    if (session === null) {
      setLoading(false);
    } else {

      store.dispatch(userSignInAction({
        user: session.user,
        profiles: session.profiles,
        token: session.token
      }));
      setLoading(false);
    }

  }

  if (loading) {
    return (
      <div/>
    )
  }

  return (
    <Provider store={store}>
      <InstallAppModal/>
      <MainLayout>
        <BrowserRouter>
          <Routes>

            <Route element={<Auth withSidebar={false} profileType={'flirt'}/>}>
              <Route path="/profile/create/friend" element={<OnBoarding profileType={'friend'}/>} />
              <Route path="/profile/create/flirt" element={<OnBoarding profileType={'flirt'}/>} />
              <Route path="/profile/create/fun" element={<OnBoarding profileType={'fun'}/>} />
            </Route>

            <Route element={<Auth withSidebar={false}/>}>
              <Route path="/on-boarding" element={<OnBoarding profileType={false}/>} />
              <Route path="/reset_password" element={<ResetPassword auth={true}/>} />
            </Route>

            <Route element={<Auth withSidebar={lgUp}/>}>
              <Route path="/profile" element={<MyProfile/>} />
              <Route path="/profile/edit/friend" element={<EditProfile type={'friend'}/>} />
              <Route path="/profile/edit/flirt" element={<EditProfile type={'flirt'}/>} />
              <Route path="/profile/edit/fun" element={<EditProfile type={'fun'}/>} />

              <Route path="/stats" element={<Stats/>} />
              <Route path="/notifications" element={<Notifications/>} />

              <Route path="/settings" element={<Settings/>} />
              <Route path="/settings/blocked_users" element={<BlockedUsers/>} />
              <Route path="/settings/favorites_users" element={<FavoritesUsers/>} />

              <Route path="/messages" element={<Messages/>} />
              <Route path="/peoples/:id" element={<UserPage/>} />

              <Route path="/peoples" element={<Users/>} />
              <Route path="/chat" element={<ChatPage/>} />

            </Route>

            <Route element={<NoAuth/>}>
              <Route path="/restore_password" element={<ResetPassword auth={false}/>} />
              <Route path="/register" element={<SignUp/>} />
              <Route path="/login" element={<SignIn/>} />
              <Route path="/restore" element={<RestorePassword/>} />
            </Route>

            <Route path='*' element={ <Navigate to={'/login'} /> } />
          </Routes>
        </BrowserRouter>
        <Lightbox/>
      </MainLayout>
    </Provider>
  );
}

export default App;
