import React from 'react'
import {PhotosInput} from "../../../components/ui/PhotosInput/PhotosInput";
import {ProgressLine} from "./ProgressLine";
import {useDispatch, useSelector} from "react-redux";
import {onBoardingUpdateAction} from "../../../reducers/onboarding";
import moment from "moment";
import {PHOTO_TYPE} from "../../../types/models/photo";

export function OnBoardingPrivatePhotos() {

  const photos = useSelector(state => state.onBoarding.privatePhotos)
  const profiles = useSelector(state => state.user.profiles)
  const profileType = useSelector(state => state.onBoarding.profileType)

  const dispatch = useDispatch()

  const onBack = () => {
    dispatch(onBoardingUpdateAction({
      values: {
        step: 'publicPhotos'
      }
    }))
  }

  const submit = () => {


    const birthdates = profiles.map(profile => profile.values.find(value => [404, 509, 619].indexOf(value.type_id) !== -1)).filter(value => value !== undefined).map(value => value?.value)
    if (birthdates.length > 0) {
      dispatch(onBoardingUpdateAction({
        values: {
          // @ts-ignore
          birthdate: moment(birthdates[0]).format('MM/DD/YYYY'),
          step: 'about'
        }
      }))
    } else {
      dispatch(onBoardingUpdateAction({
        values: {
          step: 'birthdate'
        }
      }))
    }
  }

  const changePhotos = (photos: PHOTO_TYPE[]) => {
    dispatch(onBoardingUpdateAction({
      values: {
        privatePhotos: [...photos]
      }
    }))
  }

  return (
    <>
      <div className="on-boarding-top">
        <span className="on-boarding-back-link" onClick={onBack}>
          <img src='/images/icons/common/angle-left.svg' className="on-boarding-back-link-icon"/>
          <span className="on-boarding-back-link-label">Back</span>
        </span>
      </div>
      <ProgressLine/>
      <span className="on-boarding-title">Upload additional private photos</span>
      <span className="on-boarding-text">Upload up to 4 photos into your private album. You will give or grant permissions to another member to see your private photos.</span>
      <span className="on-boarding-text">We accept JPGs and PNGs</span>
      {profileType && (
        <PhotosInput
          photos={photos}
          onChange={photos => {changePhotos(photos)}}
          maxLength={4}
          profileType={profileType}
          photoType={'private'}
        />
      )}
      <button
        className="on-boarding-submit-button"
        onClick={submit}
      >
        Continue
      </button>
      <span className="on-boarding-skip-link" onClick={submit}>Skip</span>
    </>
  )
}
