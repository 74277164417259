import thunk from 'redux-thunk';
import {USER_STATE_TYPE, userReducer} from './user';
import {applyMiddleware, combineReducers, createStore} from "redux";
import {applicationReducer} from "./application";
import {CHAT_STATE_TYPE, chatReducer} from "./chat";
import {ON_BOARDING_STATE_TYPE, onBoardingReducer} from "./onboarding";

type AppState = {
  user: USER_STATE_TYPE
  application: any
  chat: CHAT_STATE_TYPE
  onBoarding: ON_BOARDING_STATE_TYPE
}

export type ActionProps = {
  type: string,
  payload: any
}

declare module 'react-redux' {
  interface DefaultRootState extends AppState {}
}

const rootReducer = combineReducers({
  user: userReducer,
  application: applicationReducer,
  chat: chatReducer,
  onBoarding: onBoardingReducer
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
