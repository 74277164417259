import * as React from "react"
const CloseIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={35}
    fill="none"
    {...props}
  >
    <g
      stroke="#433F4E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      clipPath="url(#a)"
    >
      <path d="m26.25 8.75-17.5 17.5M8.75 8.75l17.5 17.5" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h35v35H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default CloseIcon
