import * as React from "react"

const SuccessIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <rect width={24} height={24} fill="#1EAF46" rx={12} />
      <path
        stroke="#FCFBFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m5.001 12 5 5 10-10"
      />
    </g>
    <defs>
      <clipPath id="a">
        <rect width={24} height={24} fill="#fff" rx={12} />
      </clipPath>
    </defs>
  </svg>
)
export default SuccessIcon
