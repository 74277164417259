import * as React from "react"
const ErrorIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <path
      stroke="#F03131"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15 10.563v5m0 10.687C8.787 26.25 3.75 21.213 3.75 15S8.787 3.75 15 3.75 26.25 8.787 26.25 15 21.213 26.25 15 26.25Zm.062-6.937v.125h-.124v-.125h.124Z"
    />
  </svg>
)
export default ErrorIcon
