import * as React from "react"
const HeyIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.705 2.854a.563.563 0 0 1 .69-.397c1.442.386 2.624 1.278 3.341 2.511a.563.563 0 0 1-.975.563c-.562-.973-1.505-1.68-2.657-1.988a.563.563 0 0 1-.399-.689ZM7.593 21.308c-.899-.709-1.648-1.61-2.357-2.84a.563.563 0 1 0-.975.563c.784 1.356 1.621 2.36 2.636 3.16a.562.562 0 1 0 .696-.883Zm13.943-5.719a8.073 8.073 0 0 1-7.775 5.973 8.131 8.131 0 0 1-2.1-.281 8.011 8.011 0 0 1-4.895-3.75l-3.563-6.17a2.438 2.438 0 0 1 2.22-3.657l-.469-.819a2.437 2.437 0 0 1 3.912-2.854 2.437 2.437 0 0 1 4.304-.157l1.598 2.768a2.438 2.438 0 0 1 4.274-.094l1.688 2.923a8.011 8.011 0 0 1 .806 6.114v.004Zm-1.781-5.555L18.067 7.11a1.312 1.312 0 1 0-2.273 1.313l.937 1.624a.561.561 0 0 1-.205.768 3.188 3.188 0 0 0-1.167 4.354.562.562 0 1 1-.974.563 4.32 4.32 0 0 1 1.11-5.578l-3.3-5.72A1.312 1.312 0 0 0 9.92 5.748l2.438 4.22a.562.562 0 1 1-.974.562L8.197 5.008A1.312 1.312 0 1 0 5.924 6.32l3.562 6.17a.563.563 0 1 1-.974.562L6.45 9.48a1.312 1.312 0 1 0-2.274 1.313l3.564 6.175a6.937 6.937 0 0 0 12.015-6.937v.003Z"
    />
  </svg>
)
export default HeyIcon
