import { FC, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useNavigation } from "react-router-dom";
import { InfoIcon } from "../../../components";
import { Modal } from '../../../components/ui/Modal/Modal';
import { PROFILE_TYPE_COLORS, PROFILE_TYPE_LABELS } from "../../../constants";
import TrashBinIcon from '../../../icons/TrashBinIcon';
import { profileDeleteAction, userSignOutAction } from "../../../reducers/user";
import { PROFILE_TYPE } from "../../../types/models/profile";

export function AccountManagement({}) {

  const profiles = useSelector(state => state.user.profiles)

  return (
    <div className="account-management">
      {profiles.length >= 2 && (
        <>
          {profiles.map(profile => profile.type).map(type => {
            return (
              <DeleteProfileElement
                type={type}
              />
            )
          })}
        </>
      )}
      <DeleteAccountElement/>
    </div>
  )

}

const getProfileDisplayName = (name: PROFILE_TYPE) => (
  name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
)

function DeleteProfileElement({type}: {type: PROFILE_TYPE}) {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const deleteProfile = () => {
    dispatch(profileDeleteAction({ type }))
    setModalOpen(false);
    navigate('/profile')
  }

  return (
    <div className="account-management-element">
      <span
        className="account-management-element-label"
        onClick={() => setModalOpen(true)}
      >
        Delete<span style={{color: PROFILE_TYPE_COLORS[type]}}>{PROFILE_TYPE_LABELS[type]} Profile</span>
      </span>
      <InfoIcon
        content={(
          <span>All information from your <span style={{color: PROFILE_TYPE_COLORS[type]}}>{PROFILE_TYPE_LABELS[type]} profile</span> will be deleted.</span>
        )}
      />
      <DeleteModal
        open={modalOpen}
        setOpen={setModalOpen}
        onDelete={deleteProfile}
        type={type}
      >
        <h2 className='profile-title'>
          Delete <span style={{color: PROFILE_TYPE_COLORS[type] }}>{getProfileDisplayName(type)} Profile</span>
        </h2>
        <p className='profile-base-text'>
          This will permanently delete all personal information and settings. Your profile will not appear in searchers and others will no longer see information about you.
        </p>
      </DeleteModal>
    </div>
  )
}

function DeleteAccountElement() {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profiles = useSelector(state => state.user.profiles)

  const deleteAccount = () => {
    profiles.forEach(({ type }) => {
      dispatch(profileDeleteAction({ type }))
    });
    dispatch(userSignOutAction());
    navigate('/');
  }

  const HHH = (
    <>
      <span style={{ color: PROFILE_TYPE_COLORS['friend'] }}>Hey.</span>
      <span style={{ color: PROFILE_TYPE_COLORS['flirt'] }}>Hey.</span>
      <span style={{ color: PROFILE_TYPE_COLORS['fun'] }}>Hey</span>
    </>
  )

  return (
    <span className="account-management-element">
      <span
        className="account-management-element-label"
        style={{color: 'rgba(240, 49, 49, 1)'}}
        onClick={() => setModalOpen(true)}
      >
        Delete Account
      </span>
      <InfoIcon
        content={(
          <span>All your created accounts will be deleted.</span>
        )}
      />
      <DeleteModal
        open={modalOpen}
        setOpen={setModalOpen}
        onDelete={deleteAccount}
      >
        <h2 className='profile-title'>
          Delete {HHH} Account
        </h2>
        <p className='profile-base-text'>
          This will permanently delete all your {HHH} profiles with personal information and settings.
        </p>
      </DeleteModal>
    </span>
  )
}

interface DeleteModalI {
  open: boolean,
  setOpen: (val: boolean) => void;
  type?: PROFILE_TYPE,
  onDelete: () => void;
  children?: any;
}

const DeleteModal: FC<DeleteModalI> = ({ open, setOpen, onDelete, type, children }) => {
  return (
    <Modal
        open={open}
        content={(
          <div className='delete-profile-container'>
            <TrashBinIcon/>
            {children}
            <div className='btns-container'>
              <button
                className='btn'
                style={type ? {color: PROFILE_TYPE_COLORS[type], borderColor: PROFILE_TYPE_COLORS[type] } : {}}   
                onClick={() => setOpen(false)}           
              >
                Cancel
              </button>
              <button className='btn delete-btn' onClick={onDelete}>
                Delete
              </button>
            </div>
          </div>
        )}
        onClose={() => setOpen(false)}
      />
  )
}