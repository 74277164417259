import React, {useState} from 'react'
import {NavLink} from "react-router-dom";
import {useDispatch} from "react-redux";
import {apiUserSendResetPasswordEmail} from "../../../actions/userActions";
import {sendSmallNotification} from "../../../utils";
import LogoWithIcon from "../../../icons/LogoWithIcon";

export function RestorePasswordActivation({email}: {email: string}) {

  const [isResend, setIsResend] = useState(false)
  const dispatch = useDispatch()

  const resend = async () => {

    await apiUserSendResetPasswordEmail(email)
    setIsResend(true)
    sendSmallNotification(dispatch, {
      title: 'New reset email sent!',
      type: 'success'
    })

  }


  return (
    <div className="sign-up">
      <div className="sign-up-inner">
        <div className="sign-up-logo-with-icon">
          <LogoWithIcon/>
        </div>
        <span className="sign-up-title">Restore password</span>
        <span className="sign-up-text">We sent the password recovery link to your e-mail <b>{email}</b>. Please check your email.</span>
        {!isResend && (
          <span className="sign-up-resend">Didn't get link? <span onClick={resend} className="sign-up-resend-link">Resend</span></span>
        )}
        <NavLink to={'/login'} className="sign-up-back">Back</NavLink>
      </div>
    </div>
  )

}
