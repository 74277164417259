import React, {useState} from 'react'
import {ProgressLine} from "./ProgressLine";
import {useDispatch, useSelector} from "react-redux";
import {onBoardingUpdateAction} from "../../../reducers/onboarding";
import {DateField} from "../../../components/ui/DateField/DateField";
import {validateDate} from "../../../utils";

export function OnBoardingBirthdate() {
  const birthdate = useSelector(state => state.onBoarding.birthdate);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useDispatch()

  const onBack = () => {
    dispatch(onBoardingUpdateAction({
      values: {
        step: 'privatePhotos'
      }
    }))
  }

  const submit = () => {
    const { error } = validateDate(birthdate);
    setError(error);
    if (!error) {
      dispatch(onBoardingUpdateAction({
        values: {
          step: 'about'
        }
      }))
    }
  }

  const changeBirthdate = (value: string) => {
    dispatch(onBoardingUpdateAction({
      values: {
        birthdate: value
      }
    }))
  }

  return (
    <>
      <div className="on-boarding-top">
        <span className="on-boarding-back-link" onClick={onBack}>
          <img src='/images/icons/common/angle-left.svg' className="on-boarding-back-link-icon"/>
          <span className="on-boarding-back-link-label">Back</span>
        </span>
      </div>
      <ProgressLine/> 
      <span className="on-boarding-title">When is your birthday?</span>
      <div className="on-boarding-input-area">
        <span className='on-boarding-error'>{error}</span>
        <DateField
          value={birthdate}
          onChange={changeBirthdate}
        />
      </div>
      <button
        // disabled={continueDisabled}
        className="on-boarding-submit-button"
        onClick={submit}
      >
        Continue
      </button>
    </>
  )
}
