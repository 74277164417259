import React from 'react'
import {ProgressLine} from "./ProgressLine";
import {useDispatch, useSelector} from "react-redux";
import {onBoardingCleanAction, onBoardingUpdateAction} from "../../../reducers/onboarding";
import {ProfileTabs} from "../../../components/ProfileTabs/ProfileTabs";
import {profileCreateAction} from "../../../reducers/user";
import {convertToValues} from "../../../utils/convertToValues";
import {useNavigate} from "react-router-dom";
import moment from "moment";

export function OnBoardingAbout() {

  const values = useSelector(state => state.onBoarding.values)
  const type = useSelector(state => state.onBoarding.profileType)
  const onBoarding = useSelector(state => state.onBoarding)
  const profiles = useSelector(state => state.user.profiles)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onBack = () => {

    const birthdates = profiles.map(profile => profile.values.find(value => [404, 509, 619].indexOf(value.type_id) !== -1)).filter(value => value !== undefined).map(value => value?.value)
    if (birthdates.length > 0) {
      dispatch(onBoardingUpdateAction({
        values: {
          // @ts-ignore
          birthdate: moment(birthdates[0]).format('MM/DD/YYYY'),
          step: 'privatePhotos'
        }
      }))
    } else {
      dispatch(onBoardingUpdateAction({
        values: {
          step: 'birthdate'
        }
      }))
    }

  }

  const createProfile = () => {
    dispatch(profileCreateAction({
      profile: {
        name: onBoarding.name,
        publicPhotos: onBoarding.publicPhotos,
        privatePhotos: onBoarding.privatePhotos,
        avatar: onBoarding.avatar?.path,
        adult: onBoarding.avatar?.adult ? +onBoarding.avatar?.adult : 0,
        adult_status: onBoarding.avatar?.adult ? 1 : 0,
        type: onBoarding.profileType,
        values: convertToValues(onBoarding)
      }
    }))
    dispatch(onBoardingCleanAction({
      type: null
    }))
    navigate('/profile')
  }

  return (
    <>
      <div className="on-boarding-top">
        <span className="on-boarding-back-link" onClick={onBack}>
          <img src='/images/icons/common/angle-left.svg' className="on-boarding-back-link-icon"/>
          <span className="on-boarding-back-link-label">Back</span>
        </span>
      </div>
      <ProgressLine/>
      <span className="on-boarding-title">Tell other members about you</span>
      {onBoarding.profileType && (
        <span className="on-boarding-text">Tell others about you. Remember, the more details you give the better experience you will have. But a novel is not needed 🙂</span>
      )}
      {type && (
        <div className="on-boarding-tabs">
          <ProfileTabs
            type={type}
            onChangeValues={values => {
              dispatch(onBoardingUpdateAction({
                values: {
                  values: [...values]
                }
              }))
            }}
            values={values}
            onBoarding={true}
          />
        </div>
      )}
      <button
        onClick={createProfile}
        className="on-boarding-submit-button"
      >
        Continue
      </button>
      <span className="on-boarding-skip-link" onClick={createProfile}>Skip</span>
    </>
  )
}
