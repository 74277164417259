import React from 'react'
import {Switcher} from "../../../components/ui/Switcher/Switcher";
import {MapComponent} from "../../../components";
import {USER_LOCATION_TYPE, USER_SAFE_FOR_WORK_TYPE} from "../../../types/models/user";

type SafeForWorkProps = {
  safeForWork: USER_SAFE_FOR_WORK_TYPE
  onChange: (safeForWork: USER_SAFE_FOR_WORK_TYPE) => void
  userLocation: USER_LOCATION_TYPE | undefined
}

export function SafeForWork({safeForWork, onChange, userLocation} : SafeForWorkProps) {

  const changeSafeForWork = (checked: boolean) => {

    onChange({
      ...safeForWork,
      enabled: checked ? 1 : 0
    })

  }

  const changeSafeForWorkLocation = (lat: number, lng: number) => {

    onChange({
      ...safeForWork,
      lat: lat.toString(),
      lng: lng.toString()
    })

  }

  return (
    <div className="visibility-element">
      <Switcher
        label={'Safe For Work'}
        checked={safeForWork?.enabled === 1}
        onChange={changeSafeForWork}
        info={'As with all searches, the more detail you add, the more narrow your results will be.\n\nSo keep that in mind as you craft your searches and don’t be too picky.'}
      />
      {safeForWork?.enabled ? (
        <div className="visibility-sub-element">
          <div className="visibility-map">
            {userLocation && userLocation.lat && userLocation.lng && (
              <MapComponent
                location={(safeForWork && safeForWork.lat && safeForWork.lng) ? {
                  lat: parseFloat(safeForWork.lat),
                  lng: parseFloat(safeForWork.lng)
                }: null}
                circle={{
                  center: {
                    lat: parseFloat(userLocation.lat),
                    lng: parseFloat(userLocation.lng)
                  },
                  radius: 8000
                }}
                onClick={(e) => {
                  const lat = e.latLng?.lat()
                  const lng = e.latLng?.lng()
                  if (lat && lng) {
                    changeSafeForWorkLocation(lat, lng)
                  }
                }}
              />
            )}
          </div>
        </div>
      ) : <div/>}
    </div>
  )

}
