import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Chat, SearchInput } from "../../components";
import './Messages.scss';
import MessagesIcon from "./icons/MessagesIcon";

export function Messages() {

  const chats = useSelector(state => state.chat.chats)
  const [search, setSearch] = useState('')
  const loading = useSelector(state => state.chat.isLoading)

  if (loading) {
    return (
      <div className="messages-loader">
        <div className="loader"/>
      </div>
    )

  }

  if (chats.filter(chat => chat.lastMessage !== null).length === 0) {
    return (
      <div className="messages-empty">
        <div className="messages-empty-icon">
          <MessagesIcon/>
        </div>
        <span className="messages-empty-title">No messages here yet</span>
        <span className="messages-empty-text">Search new people and communicate with them</span>
      </div>
    )
  }

  return (
    <div className="messages-container">
      <div className="messages-top">
        <SearchInput
          value={search}
          onChange={value => {
            setSearch(value)
          }}
        />
      </div>
      <div className="messages">
        {chats
          .filter(chat => !(search.length > 0 && chat.profile.name.toLowerCase().indexOf(search.toLowerCase()) === -1))
          .map(chat => {
          if (chat.lastMessage !== null) {
            return (
              <Chat chat={chat}/>
            )
          }
        })}
      </div>
    </div>
  )

}
