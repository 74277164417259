import axios from "axios";
import {useEffect, useState} from 'react';
import { apiLocationDetails, apiLocationGeoCoder, apiLocationSearch } from "../../../actions/locationActions";
import { USER_LOCATION_TYPE } from "../../../types/models/user";
import { parseLocationFromAddressComponents } from "../../../utils";
import { MapComponent } from "../Map/Map";
import './LocationSelect.scss';

const CancelToken = axios.CancelToken;
let source: any;

type Place = {
  description: string
  place_id: string
}

const defaultCenter = {
  lat: 34.052235,
  lng: -118.243683
};

type LocationSelectProps = {
  location: USER_LOCATION_TYPE
  onChange: (location: USER_LOCATION_TYPE) => void
}

export default function LocationSelect({location, onChange}: LocationSelectProps) {
  const [input, setInput] = useState(location.location ? location.location : '')
  const [places, setPlaces] = useState<Place[]>([])

  useEffect(() => {
    if (location.location === null) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((callback) => {
          geocode(callback.coords.latitude, callback.coords.longitude)
        });
      }
    }
  }, [])

  const search = async (text: string) => {
    if (source) {
      source.cancel();
    }
    setInput(text);
    if (text.length > 2) {
      source = CancelToken.source();
      let places = await apiLocationSearch({
        cancelToken: source.token,
        token: '',
        input
      });
      setPlaces(places.predictions)
    } else {
      setPlaces([])
    }

  }

  const select = async (place: Place) => {

    const placeObj = await apiLocationDetails({placeId: place.place_id, token: ''})
    const newLocationStr = parseLocationFromAddressComponents(placeObj.address_components)

    const lat = placeObj.geometry.location.lat;
    const lng = placeObj.geometry.location.lng;

    setPlaces([])
    onChange(
      {
        lat,
        lng,
        location: newLocationStr
      }
    )
    setInput(newLocationStr)

  }

  const geocode = async (lat: number | undefined, lng: number | undefined) => {

    if (lat && lng) {
      const place = await apiLocationGeoCoder({lat, lng, token: 'test'})
      const placeObj = place.results[place.results.length - 4];
      const newLocationStr = parseLocationFromAddressComponents(placeObj.address_components)

      onChange({
        lat: lat.toString(),
        lng: lng.toString(),
        location: newLocationStr
      })
      setInput(newLocationStr)
    }

  }

  return (
    <div className="location-select">
     <div className="location-select-inner">
       <div className="location-select-field">
         <img src="/images/icons/location/location.svg" alt="" className="location-select-field-icon"/>
         <input
           type="text"
           className="location-select-field-input"
           placeholder={'Enter your location'}
           value={input}
           onChange={(e) => search(e.target.value)}
         />
       </div>
       {places.length > 0 && (
         <div className="location-select-submenu">
           {places.map(place => {
             return (
               <div className="location-select-submenu-element" onClick={() => {select(place)}}>{place.description}</div>
             )
           })}
         </div>
       )}
     </div>
      <div className="location-select-map">
        <MapComponent
          location={location ? {
            lat: Number(location.lat),
            lng: Number(location.lng)
          } : defaultCenter}
          onClick={(e) => {
            geocode(e.latLng?.lat(), e.latLng?.lng())
          }}
        />
      </div>
    </div>
  )
}
