import React from "react";
import {RadioSwitcherElementType} from "../RadioSwitcher";

export default function RadioSwitcherElement({element, onClick, selected}: {
  element: RadioSwitcherElementType,
  onClick: () => void,
  selected: string | null
}) {

  const isActive = selected === element.id

  return (
    <div className="u-radio-switcher-element" onClick={onClick}>
      <div className={`u-radio-switcher-element-circle${isActive ? ' active' : ''}`}>
        {isActive && <div className="u-radio-switcher-element-circle-inner"/>}
      </div>
      <span className="u-radio-switcher-element-label">{element.label}</span>
    </div>
  )
}
