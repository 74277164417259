import axios, {CancelToken} from "axios";
import {API_URL} from "../constants";

export const apiLocationSearch = ({input, token, cancelToken}: {
  input: string
  token: string
  cancelToken: CancelToken
}): any => {

  return new Promise((resolve, reject) => {

    axios.get(`${API_URL}/v3/location.search?input=${input}`, {
      cancelToken,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.places);
    }).catch(function (error) {
      if (!axios.isCancel(error)) {
        reject(error);
      }
    })

  })

}

export const apiLocationDetails = ({placeId, token}: {
  placeId: string
  token: string
}): any => {

  return new Promise((resolve, reject) => {

    axios.get(`${API_URL}/v3/location.detail?place_id=${placeId}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.place);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiLocationGeoCoder = ({lat, lng, token}: {
  lat: number,
  lng: number,
  token: string
}): any => {

  return new Promise((resolve, reject) => {

    axios.get(`${API_URL}/v3/location.geocode?lat=${lat}&lng=${lng}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.place);
    }).catch(function (error) {
      reject(error);
    })

  })

}
