import * as React from "react"
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M31.365 16.68c0 7.335-5.325 14.205-12.6 16.215a2.94 2.94 0 0 1-1.53 0c-7.275-2.01-12.6-8.88-12.6-16.215v-6.585c0-1.23.93-2.625 2.085-3.09l8.355-3.42a7.781 7.781 0 0 1 5.865 0l8.355 3.42c1.14.465 2.085 1.86 2.085 3.09l-.015 6.585Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M18 18.75a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM18 18.75v4.5"
    />
  </svg>
)
export default SvgComponent
