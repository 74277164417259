import React from 'react'
import {EditProfileTips} from "../../../pages/EditProfile/components/EditProfileTips";
import {SearchValue} from "../FiltersModal";

export type SearchBlock = {
  label: string
  type: 'tips'
  id: number
  elements: string[]
}

export function SearchBlock({block, values, onChange}: {block: SearchBlock, values: SearchValue[], onChange: any}) {

  if (block.type === 'tips') {
    return (
      <EditProfileTips
        blockId={block.id}
        tips={block.elements}
        values={values}
        type={'multi'}
        onChange={onChange}
      />
    )
  }

  if (block.type === 'list') {
    return (
      <EditProfileTips
        blockId={block.id}
        tips={block.elements}
        values={values}
        type={'single'}
        onChange={onChange}
      />
    )
  }

  return <div/>

}

