import { useMediaQuery } from '@react-hook/media-query';

/*
  Dimensions
  X-Small	None	      xs              <576px
  Small	              sm	            ≥576px
  Medium	            md	            ≥768px
  Large	              lg	            ≥992px
  Extra large	        xl	            ≥1200px
  Extra extra large	  xxl	            ≥1400px

*/
/*Based on screen-sizes.scss */

const useBreakpoints = () => {
  const xsUp = useMediaQuery('only screen and (min-width: 0px)');
  const smUp = useMediaQuery('only screen and (min-width: 576px)');
  const mdUp = useMediaQuery('only screen and (min-width: 768px)');
  const lgUp = useMediaQuery('only screen and (min-width: 992px)');
  const xlUp = useMediaQuery('only screen and (min-width: 1200px)');
  const xlxlUp = useMediaQuery('only screen and (min-width: 1400px)');

  return { xsUp, smUp, mdUp, lgUp, xlUp, xlxlUp };
};

export default useBreakpoints;