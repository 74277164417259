import * as React from "react"

const ImageIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    fill="none"
    {...props}
  >
    <path
      stroke="#6542DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M38.04 6.416H17.854c-7.028 0-11.436 4.98-11.436 12.026v19.007c0 7.047 4.389 12.026 11.436 12.026h20.176c7.058 0 11.445-4.979 11.445-12.026V18.442c.01-7.047-4.38-12.026-11.433-12.026Z"
      clipRule="evenodd"
    />
    <path
      stroke="#6542DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M24.976 20.498a4.307 4.307 0 1 1-8.615 0 4.307 4.307 0 0 1 8.615 0Z"
      clipRule="evenodd"
    />
    <path
      stroke="#6542DD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M49.483 34.886c-2.154-2.217-6.295-6.695-10.799-6.695-4.505 0-7.102 9.877-11.435 9.877s-8.27-4.466-11.742-1.603c-3.472 2.861-6.757 8.71-6.757 8.71"
    />
  </svg>
)
export default ImageIcon
