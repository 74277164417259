import { useDispatch, useSelector } from "react-redux";
import {
  getFullImageUrl,
  getProfile,
  getProfileColor,
} from "../../../utils";
import {
  PROFILE_TYPE_ARROWS,
  PROFILE_TYPE_COLORS,
  PROFILE_TYPE_ICONS,
  PROFILE_TYPE_LABELS,
} from "../../../constants";
import "./ProfileSwitcherMobile.scss";
import { applicationToggleProfileSwitcherPopUp } from "../../../reducers/application";

const ProfileSwitcherMobile = () => {
  const activeType = useSelector((state) => state.user.activeType);
  const profiles = useSelector((state) => state.user.profiles);

  const profile = getProfile(profiles, activeType);

  const dispatch = useDispatch();

  if (profile === null) {
    return null;
  }

  const handleProfileSwitcherClick = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    dispatch(applicationToggleProfileSwitcherPopUp());
  };

  return (
    <div className="profile-switcher-mobile">
      <div
        className="profile-switcher-mobile-profile-info"
        style={{ backgroundColor: getProfileColor(activeType) }}
      >
        <img
          src={getFullImageUrl(profile.avatar)}
          alt=""
          className="profile-switcher-mobile-profile-info-image"
        />
        <span className="profile-switcher-mobile-profile-info-name">
          {profile.name}
        </span>
      </div>
      <button
        onClick={handleProfileSwitcherClick}
        className="profile-switcher-mobile-profile-picker"
      >
        <img
          src={PROFILE_TYPE_ICONS[profile.type]}
          alt=""
          className="profile-switcher-mobile-picker-icon"
        />
        <span
          style={{ color: PROFILE_TYPE_COLORS[profile.type] }}
          className="profile-switcher-mobile-picker-label"
        >
          {PROFILE_TYPE_LABELS[profile.type]}
        </span>
        <img
          src={PROFILE_TYPE_ARROWS[profile.type]}
          alt=""
          className="profile-switcher-mobile-picker-arrow"
        />
      </button>
    </div>
  );
};

export default ProfileSwitcherMobile;
