import React from 'react'
import {Switcher} from "../../../components/ui/Switcher/Switcher";
import {MapComponent} from "../../../components";
import {USER_EXPLORE_TYPE} from "../../../types/models/user";

type ExploreNewAreaProps = {
  explore: USER_EXPLORE_TYPE,
  onChange: (explore: USER_EXPLORE_TYPE) => void
}

export function ExploreNewArea({explore, onChange}: ExploreNewAreaProps) {

  const changeExplore = (checked: boolean) => {

    onChange({
      ...explore,
      enabled: checked ? 1 : 0
    })

  }

  const changeExploreLocation = (lat: number, lng: number) => {

   onChange({
     ...explore,
     lat: lat.toString(),
     lng: lng.toString()
   })

  }

  return (
    <div className="visibility-element">
      <Switcher
        label={'Explore a new area'}
        checked={explore?.enabled === 1}
        onChange={changeExplore}
      />
      {explore?.enabled ? (
        <div className="visibility-sub-element">
          <div className="visibility-map">
            <MapComponent
              location={(explore && explore.lat && explore.lng) ? {
                lat: parseFloat(explore.lat),
                lng: parseFloat(explore.lng)
              }: null}
              onClick={(e) => {
                const lat = e.latLng?.lat()
                const lng = e.latLng?.lng()
                if (lat && lng) {
                  changeExploreLocation(lat, lng)
                }
              }}
            />
          </div>
        </div>
      ): <div/>}
    </div>
  )

}
