import React from 'react'
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";

export function AccountDetails({}) {

  const user = useSelector(state => state.user.user)

  if (user && user.email) {
    return (
      <div className="account-management">
        <div className="account-management-email">
          <span className="account-management-email-label">Email</span>
          <input type="text" disabled={true} value={user.email} className="account-management-email-input"/>
        </div>
        <NavLink to={'/reset_password'} className="account-management-change-password-link">Change Password</NavLink>
      </div>
    )
  }

  return <div/>

}
