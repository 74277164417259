import React, {useState} from 'react'
import './PrivatePhotoModal.scss'
import PrivateIcon from "./icons/PrivateIcon";
import {useDispatch, useSelector} from "react-redux";
import {sendSmallNotification} from "../../utils";
import {apiProfileRequestPhotoAccess} from "../../actions/profileActions";

type PrivatePhotoStatus = {
  onCancel: () => void,
  privatePhotosStatus: 'allow' | 'request' | 'no_access'
  onChange: (privatePhotosStatus: 'allow' | 'request' | 'no_access') => void
  profileId: number
}

export const PrivatePhotoModal = ({onCancel, privatePhotosStatus, onChange, profileId}: PrivatePhotoStatus) => {

  const token = useSelector(state => state.user.token)
  const dispatch = useDispatch()

  const submit = () => {
    onChange('request')
    sendSmallNotification(dispatch, {
      title: 'Request has been sent',
      type: 'success'
    })
    if (token) {
      apiProfileRequestPhotoAccess(token, profileId)
    }
    onCancel()
  }

  return (
    <div className="private-modal">
      <div className="private-modal-icon">
        <PrivateIcon/>
      </div>
      {privatePhotosStatus === 'no_access' && (
        <span className="private-modal-text">
          This image is part of a private photos.<br/>
          Do you want to send a request to see this photo?
        </span>
      )}
      {privatePhotosStatus === 'request' && (
        <span className="private-modal-text">You have already requested to view private photos. You'll be able to see them when Jane Cooper confirms that you're allowed to view them.</span>
      )}
      {privatePhotosStatus === 'no_access' && (
        <div className="modal-bottom">
          <button className="modal-cancel-button profile-border-color profile-text-color" onClick={onCancel}>Cancel</button>
          <button className="modal-submit-button profile-background-color" onClick={submit}>Send</button>
        </div>
      )}
    </div>
  )

}
