import axios from "axios";
import { API_URL } from "../constants";
import {PROFILE_TYPE} from "../types/models/profile";

export const apiStatsGet = (
  token: string,
  type: PROFILE_TYPE,
  days: '7days' | 'total'
): any => {

  return new Promise((resolve, reject) => {

    axios.get(`${API_URL}/v3/web/stats.get?type=${type}&days=${days}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.stats);
    }).catch(function (error) {
      reject(error);
    })

  })

}
