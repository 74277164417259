import * as React from "react"
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 13.5V3l-3 3M18 3l3 3M2.97 19.5h6.616c.57 0 1.08.315 1.335.825l1.755 3.51A3 3 0 0 0 15.36 25.5h5.295a3 3 0 0 0 2.685-1.665l1.755-3.51c.255-.51.78-.825 1.335-.825h6.54"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10.5 7.695C5.19 8.475 3 11.595 3 18v4.5C3 30 6 33 13.5 33h9C30 33 33 30 33 22.5V18c0-6.405-2.19-9.525-7.5-10.305"
    />
  </svg>
)
export default SvgComponent
