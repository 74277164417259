import * as React from "react"

const NotificationIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={238}
    height={229}
    fill="none"
    {...props}
  >
    <circle cx={117.334} cy={130.107} r={95.396} fill="#EAE4FF" />
    <circle cx={27.917} cy={65.692} r={5.979} fill="#DACFFD" />
    <circle cx={182.563} cy={42.047} r={11.143} fill="#DACFFD" />
    <circle cx={29.82} cy={191.529} r={10.6} fill="#DACFFD" />
    <circle cx={219.525} cy={181.203} r={10.6} fill="#DACFFD" />
    <circle cx={198.869} cy={193.16} r={5.164} fill="#DACFFD" />
    <path
      fill="#6542DD"
      d="M16.72 136.089c.224-1.057 1.733-1.057 1.957 0l1.31 6.186a1 1 0 0 0 .765.77l6.16 1.342c1.05.228 1.05 1.725 0 1.954l-6.16 1.342a1 1 0 0 0-.766.77l-1.31 6.186c-.223 1.057-1.732 1.057-1.956 0l-1.31-6.186a1 1 0 0 0-.765-.77l-6.16-1.342c-1.05-.229-1.05-1.726 0-1.954l6.16-1.342a1 1 0 0 0 .766-.77l1.31-6.186ZM57.814 22.058c.124-.587.962-.587 1.087 0l.727 3.436a.556.556 0 0 0 .425.428l3.422.746c.583.127.583.958 0 1.085l-3.422.746a.556.556 0 0 0-.425.428l-.727 3.436c-.125.587-.963.587-1.087 0l-.728-3.436a.556.556 0 0 0-.425-.428l-3.422-.746c-.583-.127-.583-.958 0-1.085l3.422-.746a.556.556 0 0 0 .425-.428l.728-3.436ZM202.143 70.315c.224-1.057 1.733-1.057 1.957 0l.553 2.61a1 1 0 0 0 .765.77l2.635.574c1.049.229 1.049 1.725 0 1.954l-2.635.574a1 1 0 0 0-.765.77l-.553 2.61c-.224 1.057-1.733 1.057-1.957 0l-.552-2.61a1 1 0 0 0-.765-.77l-2.635-.574c-1.05-.228-1.05-1.725 0-1.954l2.635-.574a1 1 0 0 0 .765-.77l.552-2.61ZM224.503 148.496l1.665 7.864 7.751 1.689-7.751 1.689-1.665 7.864-1.664-7.864-7.752-1.689 7.752-1.689 1.664-7.864ZM134.408 49.923c-.58-.123-.58-.95 0-1.074l3.454-.734a.55.55 0 0 0 .422-.421l.747-3.444c.125-.576.948-.576 1.073 0l.747 3.444a.548.548 0 0 0 .422.42l3.454.735c.579.123.579.95 0 1.074l-3.454.735a.548.548 0 0 0-.422.42l-.747 3.444c-.125.577-.948.577-1.073 0l-.747-3.444a.55.55 0 0 0-.422-.42l-3.454-.735Z"
    />
    <path
      fill="#EAE4FF"
      stroke="#6542DD"
      strokeWidth={3}
      d="M106.704 36.95c1.668 6.156-2.22 12.702-8.886 14.508-6.666 1.807-13.328-1.879-14.997-8.035-1.668-6.156 2.22-12.702 8.886-14.509 6.666-1.806 13.328 1.88 14.997 8.036Z"
    />
    <path
      fill="#F2EEFF"
      stroke="#6542DD"
      strokeWidth={4}
      d="m90.58 40.247 4.065-1.644 7.58-2.054a38.374 38.374 0 0 1 24.042 1.31l7.677 3.009 4.581 2.47a49.547 49.547 0 0 1 18.377 17.158l4.383 6.94 8.612 18.53 5.942 13.095 9.395 15.536 7.277 12.135 5.564 7.984a12.031 12.031 0 0 0 7.144 4.841 12.041 12.041 0 0 1 5.772 3.202l2.747 2.742a8.406 8.406 0 0 1 1.475 9.915l-.187.349a9.06 9.06 0 0 1-4.475 4.077l-12.97 5.457-19.661 6.918-34.638 10.245-19.749 5.353-27.719 6.656-24.736 5.115-13.736 1.852a7.495 7.495 0 0 1-8.468-8.091l.224-2.525a9.485 9.485 0 0 1 3.546-6.587 9.487 9.487 0 0 0 3.534-6.466l1.253-12.323v-16.21l-2.054-22.294-1.092-15.8-.57-6.753a74.652 74.652 0 0 1-.034-12.14l.149-1.888a58.539 58.539 0 0 1 7.629-24.618l.591-1.026a55.322 55.322 0 0 1 16.142-17.662l.418-.294a59.914 59.914 0 0 1 11.97-6.514Z"
    />
    <path
      fill="#F2EEFF"
      stroke="#6542DD"
      strokeWidth={3}
      d="m90.576 40.23 4.065-1.644 7.58-2.055a38.374 38.374 0 0 1 24.042 1.31l7.676 3.01 4.581 2.47a49.554 49.554 0 0 1 18.378 17.157l4.383 6.94 8.611 18.531 5.942 13.094 9.396 15.536 7.277 12.136 4.945 6.766a15.02 15.02 0 0 0 9.001 5.828l.331.07a13.744 13.744 0 0 1 6.229 3.135l1.037.915a9.273 9.273 0 0 1 2.559 10.179l-.046.122a9.67 9.67 0 0 1-.909 1.831l-.188.295a13.001 13.001 0 0 1-4.909 4.524l-3.394 1.788-6.45 3.397-18.084 8.154-12.23 4.892-10.6 4.077-8.969 2.989-9.24 3.262-7.882 2.174-5.436 1.359-14.676 2.99-13.861 2.717-26.091 3.75-7.976.882a20.77 20.77 0 0 1-4.703-.015l-4.022-.471a9.477 9.477 0 0 1-8.375-9.415v-.858c0-.625.05-1.249.151-1.866l.057-.351a16.001 16.001 0 0 1 3.314-7.443l1.159-1.445a23.042 23.042 0 0 0 5.037-15.65l-.86-16.078-2.054-22.295-1.091-15.799-.57-6.753a74.66 74.66 0 0 1-.035-12.14l.15-1.889a58.537 58.537 0 0 1 7.628-24.617l.592-1.027a55.322 55.322 0 0 1 16.142-17.662l.418-.293a59.919 59.919 0 0 1 11.97-6.514Z"
    />
    <path
      fill="#DACFFD"
      stroke="#6542DD"
      d="M202.765 153.49c.184.663 0 1.49-.688 2.51-.686 1.016-1.829 2.153-3.413 3.387-3.164 2.465-7.992 5.244-14.14 8.16-12.288 5.829-29.758 12.167-49.496 17.626-19.738 5.458-37.982 8.998-51.519 10.311-6.772.657-12.342.754-16.323.265-1.993-.245-3.558-.633-4.668-1.152-1.115-.521-1.697-1.137-1.88-1.8-.184-.663 0-1.49.688-2.51.686-1.016 1.828-2.153 3.412-3.387 3.165-2.465 7.993-5.243 14.14-8.159 12.288-5.829 29.759-12.167 49.497-17.626 19.737-5.459 37.982-8.998 51.519-10.312 6.772-.657 12.342-.754 16.323-.265 1.993.245 3.557.633 4.668 1.152 1.115.522 1.697 1.137 1.88 1.8Z"
    />
    <path
      fill="#F2EEFF"
      stroke="#6542DD"
      strokeWidth={3}
      d="m119.566 177.178-.807-.863a14.25 14.25 0 0 1-3.18-5.439l-1.4-4.432 3.283-1.166 3.293-1.223 8.097-2.182 9.121-2.427 5.365-1.166.232.792a26.79 26.79 0 0 1 1.068 8.187l-.189 1.982a14.38 14.38 0 0 1-2.318 6.561l-.069.104a13.223 13.223 0 0 1-8.879 5.754l-.969.159a13.314 13.314 0 0 1-10.098-2.443l-.497-.368a15.31 15.31 0 0 1-2.053-1.83Z"
    />
  </svg>
)
export default NotificationIcon
