import { useState } from "react";
import { useNavigate } from "react-router-dom";
import './Tab.scss';

export function Tab({label, content, navigateTo, initOpen = false}: {label: string, content: any, navigateTo?: string, initOpen?: boolean}) {

  const navigate = useNavigate()
  const [open, setOpen] = useState(initOpen)

  const clickOnTab = () => {
    if (navigateTo) {
      navigate(navigateTo)
    } else {
      setOpen(!open)
    }
  }

  return (
    <div className={`tab ${open ? 'active': ''}`}>
      <div className="tab-top" onClick={clickOnTab}>
        <span className="tab-top-label">{label}</span>
        <img src="/images/icons/common/arrow-down.svg" alt="" className="tab-top-arrow"/>
      </div>
      {open && (
        <div className="tab-content">
          {content}
        </div>
      )}
    </div>
  )

}
