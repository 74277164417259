import { useRef } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import "./NavigationDrawer.scss";
import { useDispatch, useSelector } from "react-redux";
import { applicationToggleNavDrawer } from "../../reducers/application";
import { AsideMenu } from "../AsideMenu/AsideMenu";
import ProfileSwitcherMobile from "../ProfileSwitcher/ProfileSwitcherMobile/ProfileSwitcherMobile";

const NavigationDrawer = () => {
  const navigationDrawerOpened = useSelector(
    (state) => state.application.navigationDrawer.isOpened
  );

  const dispatch = useDispatch();

  const drawerContentRef = useRef(null);

  useClickOutside(drawerContentRef, () => {
    if (navigationDrawerOpened) {
      dispatch(applicationToggleNavDrawer());
    }
  });

  return (
    <div
      className={`navigation-drawer-overlay ${
        navigationDrawerOpened ? "active" : ""
      }`}
    >
      <div
        className={`navigation-drawer-content ${
          navigationDrawerOpened ? "active" : ""
        }`}
        ref={drawerContentRef}
      >
        <ProfileSwitcherMobile />
        <AsideMenu />
      </div>
    </div>
  );
};

export default NavigationDrawer;
