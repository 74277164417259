import * as React from "react"
const FilterIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#a)">
      <path d="M19.414 2.344h-2.989A2.934 2.934 0 0 0 13.555 0a2.934 2.934 0 0 0-2.87 2.344H.584a.586.586 0 1 0 0 1.172h10.1a2.934 2.934 0 0 0 2.87 2.343 2.934 2.934 0 0 0 2.87-2.343h2.99a.586.586 0 1 0 0-1.172ZM19.414 9.414H9.354a2.934 2.934 0 0 0-2.87-2.344 2.934 2.934 0 0 0-2.87 2.344H.586a.586.586 0 1 0 0 1.172h3.028a2.934 2.934 0 0 0 2.87 2.344 2.934 2.934 0 0 0 2.87-2.344h10.06a.586.586 0 1 0 0-1.172ZM19.414 16.484h-2.989a2.934 2.934 0 0 0-2.87-2.343 2.934 2.934 0 0 0-2.87 2.343H.584a.586.586 0 1 0 0 1.172h10.1A2.934 2.934 0 0 0 13.554 20a2.934 2.934 0 0 0 2.87-2.344h2.99a.586.586 0 1 0 0-1.172Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default FilterIcon
