import {Agreement, Button, TextInput} from "../../../components";
import React, {useState} from "react";
import {sendSmallNotification, validateEmail, validatePassword} from "../../../utils";
import {apiUserRegister} from "../../../actions/userActions";
import {useDispatch} from "react-redux";

export default function SignUpEmail({onSetActivationEmail}: {onSetActivationEmail: (email: string, token: string) => void}) {

  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [agreement, setAgreement] = useState(false)

  const [submitLoading, setSubmitLoading] = useState(false)

  const checkDisabledButton = () => {
    return !validatePassword(password) || !validateEmail(email) || password.length <= 6 || submitLoading || !agreement
  }

  const submit = async () => {

    setSubmitLoading(true)
    const response = await apiUserRegister(email, password);
    if (!response.status) {
      setSubmitLoading(false)
      sendSmallNotification(dispatch,{
        title: response.error,
        message: 'Try to type it once again',
        type: 'error'
      })
      return
    }
    onSetActivationEmail(email, response.token)

  }

  return (
    <>
      <div className="sign-up-field">
        <TextInput
          id={'email'}
          label={'Email'}
          type={'email'}
          value={email}
          onChange={(_email) => {
            setEmail(_email)
          }}
          placeholder={'Enter your email'}
        />
      </div>
      <div className="sign-up-field">
        <TextInput
          id={'password'}
          label={'Password'}
          type={'password'}
          value={password}
          onChange={(_password) => {
            setPassword(_password)
          }}
          placeholder={'Enter your password'}
          bottomText={'Must be at least 8 characters'}
        />
      </div>
      <div className="sign-up-agreement">
        <Agreement
          selected={agreement}
          onChange={(_agreement) => {
            setAgreement(_agreement)
          }}
        />
      </div>
      <div className="sign-up-button">
        <Button label={'Continue'} onClick={submit} disabled={checkDisabledButton()} loading={submitLoading}/>
      </div>
    </>
  )

}
