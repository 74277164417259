import {PROFILE_TYPE} from "../types/models/profile";

export const PROFILE_TYPE_ICONS = {
  friend: '/images/icons/types/friend.svg',
  flirt: '/images/icons/types/flirt.svg',
  fun: '/images/icons/types/fun.svg'
}

export const PROFILE_TYPE_ARROWS = {
  friend: '/images/icons/switcher/arrow-up-friend.svg',
  flirt: '/images/icons/switcher/arrow-up-flirt.svg',
  fun: '/images/icons/switcher/arrow-up-fun.svg'
}

export const PROFILE_TYPE_ARROWS_LEFT = {
  friend: '/images/icons/switcher/arrow-left-friend.svg',
  flirt: '/images/icons/switcher/arrow-left-flirt.svg',
  fun: '/images/icons/switcher/arrow-left-fun.svg'
}

export const PROFILE_TYPE_LABELS = {
  friend: 'Friend',
  flirt: 'Flirt',
  fun: 'Fun'
}

export const PROFILE_TYPE_COLORS = {
  friend: 'rgba(0, 98, 196, 1)',
  flirt: 'rgba(101, 66, 221, 1)',
  fun: 'rgba(167, 76, 184, 1)',
}

export const PROFILE_TYPE_BACKGROUND_COLOR = {
  friend: 'rgba(235, 246, 255, 1)',
  flirt: 'rgba(244, 241, 255, 1)',
  fun: 'rgba(255, 242, 255, 1)',
}

export const PROFILE_TYPE_RGBA_COLOR = {
  friend: 'rgba(235, 246, 255, 1)',
  flirt: 'rgba(242, 238, 255, 1)',
  fun: 'rgba(255, 242, 255, 1)'
}


export const DEFAULT_BACKGROUND_COLOR = 'rgba(244, 241, 255, 1)'

export const PROFILE_TYPES_LIST: PROFILE_TYPE[] = ['friend', 'flirt', 'fun']

export const PROFILE_TYPE_RELATIONSHIPS = {
  friend: {
    values: [
      "New Friends",
      "Happy Hour Drinks",
      "Wingmam / Wingwoman",
      "Gym Buddy",
      "Gaming Friends",
      "Concert Buddies",
      "Outdoor Partner",
      "Foodies",
      "Online Friends",
      "Cultural Events"
    ],
    type_id: 408
  },
  flirt: {
    values: ["Marriage", "Long-term relationship", "A great date or two", "Just dating"],
    type_id: 514
  },
}

export const PROFILE_TYPE_BIRTHDATE = {
  friend: 404,
  flirt: 509,
  fun: 619
}
