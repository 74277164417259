import * as React from "react"
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M24.454 4.125H11.476c-4.518 0-7.351 3.201-7.351 7.731v12.219c0 4.53 2.822 7.731 7.351 7.731h12.971c4.538 0 7.358-3.201 7.358-7.731V11.856c.006-4.53-2.816-7.731-7.35-7.731Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16.056 13.177a2.768 2.768 0 1 1-5.538 0 2.769 2.769 0 0 1 5.538 0Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M31.81 22.427c-1.384-1.425-4.046-4.304-6.941-4.304-2.897 0-4.567 6.35-7.352 6.35s-5.316-2.871-7.548-1.031c-2.232 1.839-4.344 5.6-4.344 5.6"
    />
  </svg>
)
export default SvgComponent
