import {Agreement, Button, PhoneInput, PhoneType} from "../../../components";
import React, {useMemo, useRef, useState} from "react";
import {countriesWithFlag} from "../../../components/ui/PhoneInput/contants";
import {apiUserConfirmCreate} from "../../../actions/userActions";

const convertPhone = (phone: PhoneType) => {
  let convertedPhone = phone.phone;
  convertedPhone = convertedPhone.replaceAll('-', '')
  convertedPhone = convertedPhone.replaceAll(' ', '')
  convertedPhone = convertedPhone.replaceAll('(', '')
  convertedPhone = convertedPhone.replaceAll(')', '')
  return `${phone.country.dialCode}${convertedPhone}`
}

const isPhoneValid = (phone: PhoneType) => {
  return phone.country.mask.length === phone.phone.replaceAll('_', '').length
}

interface SignUpPhoneI {
  needAgreement: boolean,
  onChange: (confirmId: number, phone: string) => void,
}

export default function SignUpPhone({ needAgreement, onChange }: SignUpPhoneI) {
  const [submitLoading, setSubmitLoading] = useState(false)
  const [agreement, setAgreement] = useState(false)
  const [phone, setPhone] = useState<PhoneType>({
    country: countriesWithFlag[0],
    phone: ''
  })
  const [phoneError, setPhoneError] = useState('')

  const onSubmit = async () => {
    setSubmitLoading(true)
    const response = await apiUserConfirmCreate(convertPhone(phone));
    if (response.status === 'ok') {
      onChange(response.confirm_id, convertPhone(phone))
    } else {
      setSubmitLoading(false)
      setPhoneError(response.error)
    }
  }

  const submitDisabled = useMemo(() => (
    !isPhoneValid(phone) || (!agreement && needAgreement) || submitLoading
  ), [phone, agreement, needAgreement, submitLoading])

  return (
    <>
      <div className="sign-up-field">
        <PhoneInput
          phone={phone}
          onChange={(_phone) => {
            setPhone({ ..._phone })
            setPhoneError('')
          }}
          error={phoneError}
          onKeyDown={(key) => {
            if (key === 'Enter' && !submitDisabled) {
              onSubmit()
            }
          }}
        />
      </div>
      {needAgreement && (
        <div className="sign-up-agreement">
          <Agreement
            selected={agreement}
            onChange={setAgreement}
          />
        </div>
      )}
      <div className="sign-up-button">
        <Button
          label={'Continue'}
          onClick={onSubmit}
          disabled={submitDisabled}
          loading={submitLoading}
        />
      </div>
    </>
  )

}
