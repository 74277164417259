import React, {useState} from 'react'
import './NoteModal.scss'
import NoteIcon from "./icons/NoteIcon";
import {apiProfileChangeNote} from "../../actions/profileActions";
import {useSelector} from "react-redux";

export const NoteModal = ({onCancel, onChange, note, profileId}: {onCancel: () => void, onChange: (note: string) => void, note: string | null, profileId: number}) => {

  const token = useSelector(state => state.user.token)
  const [value, setValue] = useState(note === null ? '' : note)

  const submit = () => {
    onChange(value)
    if (token) {
      apiProfileChangeNote(token, profileId, value)
    }
    onCancel()
  }

  return (
    <div className="note-modal">
      <div className="note-modal-icon">
        <NoteIcon/>
      </div>
      <span className="note-modal-title">Make some notes!</span>
      <span className="note-modal-text">Notes help you to save some important information or thoughts about the person. This information will be seen only by you.</span>
      <div className="note-modal-input-area">
        <label htmlFor="" className="note-modal-input-area-label">Note</label>
        <textarea
          value={value}
          className="note-modal-input-area-textarea"
          placeholder='Write what a thinking about or anything you want.'
          onChange={(event) => {
            setValue(event.target.value)
          }}
        />
      </div>
      <div className="modal-bottom">
        <button className="modal-cancel-button profile-border-color profile-text-color" onClick={onCancel}>Cancel</button>
        <button className="modal-submit-button profile-background-color" onClick={submit}>Save</button>
      </div>
    </div>
  )

}
