import React from 'react'
import './MessagesBlock.css'
import {Chat} from "../ui";
import {useSelector} from "react-redux";

export const MessagesBlock = () => {

  const chats = useSelector(state => state.chat.chats)

  return (
    <div className="messages-aside-block">
      <span className="messages-aside-block-title">Messages</span>
      <div className="messages-aside-block-elements">
        {[...chats].splice(0, 10).map(chat => {
          if (chat.lastMessage !== null) {
            return (
              <Chat chat={chat} type={'small'}/>
            )
          }
        })}
      </div>
    </div>
  )

}
