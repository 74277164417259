export const registerTypes = [
  {
    id: 'email',
    label: 'Email'
  },
  {
    id: 'phone',
    label: 'Phone Number'
  }
]
