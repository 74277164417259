import * as React from "react"
const InfoIconImage = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipPath="url(#a)"
    >
      <path d="M3.5 12a9 9 0 1 0 18.001 0A9 9 0 0 0 3.5 12ZM12.5 9h.01" />
      <path d="M11.5 12h1v4h1" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.5 0h24v24H.5z" />
      </clipPath>
    </defs>
  </svg>
)
export default InfoIconImage
