export const parseLocationFromAddressComponents = (address_components: any): string => {

  let elements: string[] = []

  address_components.forEach((component: any) => {

    if (component.types.indexOf("country") !== -1 && component.long_name) {
      elements.push(component.long_name)
    }

    if (component.types.indexOf("locality") !== -1 && component.long_name) {
      elements.push(component.long_name)
    }

  })

  return elements.join(', ')

}
