import { useState } from 'react'

const useModal = () : [boolean, (obj?: any) => void, () => void, null | any] => {
  const [isShowing, setIsShowing] = useState(false);
  const [obj, setObj] = useState(null)

  function openModal(obj = null) {
    setIsShowing(true);
    setObj(obj)
  }

  function closeModal() {
    setIsShowing(false)
    setObj(null)
  }

  return [
    isShowing,
    openModal,
    closeModal,
    obj
  ];
}

export default useModal;
