import React, {useState} from 'react'
import {apiUserResendEmail} from "../../../actions/userActions";
import {useDispatch} from "react-redux";
import {sendSmallNotification} from "../../../utils";
import LogoWithIcon from "../../../icons/LogoWithIcon";

export default function EmailConfirm({onBack, email, token}: {onBack: () => void, email: string, token: string}) {

  const [isResend, setIsResend] = useState(false)
  const dispatch = useDispatch()

  const resend = async () => {

    await apiUserResendEmail(token)
    setIsResend(true)
    sendSmallNotification(dispatch, {
      title: 'New confirmation email sent!',
      type: 'success'
    })

  }

  return (
    <div className="sign-up">
      <div className="sign-up-inner">
        <div className="sign-up-logo-with-icon">
          <LogoWithIcon/>
        </div>
        <h3 className="sign-up-title">Email Verification</h3>
        <div className="sign-up-text">
          We’ve sent a confirmation email to <b>{email}</b> with a link to confirm your address. Please check your email and click on the link there. If you don’t find it, please check your spam/junk folder.
        </div>
        {!isResend ? (
          <div className="sign-up-resend">
            Didn't get link? <span className="sign-up-resend-link" onClick={resend}>Resend</span>
          </div>
        ): null}
        <div onClick={onBack} className="sign-up-back">Back</div>
      </div>
    </div>
  )

}
