import moment from "moment";

const DATE_REGEXP = new RegExp(/^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/gm);

export function validateDate(dateString: string | null): { error: string | null } {
  if (!dateString || dateString.length !== 10 || !dateString.match(DATE_REGEXP)?.[0]) {
    return { error: 'Invalid date' };
  }

  const date = moment(dateString, "MM/DD/YYYY");

  if (date.isAfter(moment()) || date.isBefore(moment().subtract(100, 'years'))) {
    return { error: 'Invalid date' };
  }

  if (date.isAfter(moment().subtract(18, 'years'))) {
    return { error: 'You must be over 18 years old' }
  }

  return { error: null };
}
