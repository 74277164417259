import React from "react";
import CloseIcon from "../../../icons/chat/CloseIcon";
import {NavLink, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {getFullImageUrl} from "../../../utils";

export const ChatPageTop = () => {

  const chats = useSelector(state => state.chat.chats)
  const chatId = useSelector(state => state.chat.activeChatId)
  const chat = chats.find(_chat => _chat.id === chatId)

  const navigate = useNavigate()

  if (chat === undefined) {
    return <div/>
  }

  const navigateToUser = () => {
    navigate(`/peoples/${chat.profile.id}`, {
      replace: true
    })
  }

  return (
    <div className="chat-page-top">
      <span onClick={navigateToUser} className="chat-page-top-user">
        <img src={getFullImageUrl(chat.profile.photo)} className="chat-page-top-photo"/>
        <div className="chat-page-top-profile">
        <span className="chat-page-top-profile-name">{chat.profile.name}</span>
        </div></span>
      <NavLink to={'/messages'} className="chat-page-top-close-icon">
        <CloseIcon/>
      </NavLink>
    </div>
  )
}
