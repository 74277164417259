import * as React from "react"
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M22.741 18.08a4.742 4.742 0 1 1-9.484 0 4.742 4.742 0 0 1 9.484 0Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.997 29.033c5.712 0 10.936-4.107 13.878-10.953-2.942-6.846-8.166-10.953-13.878-10.953h.006c-5.712 0-10.937 4.107-13.878 10.953 2.941 6.846 8.166 10.953 13.878 10.953h-.006Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
