import React from 'react'
import {useSelector} from "react-redux";
import {getOnBoardingPercent} from "../../../reducers/onboarding";
import {PROFILE_TYPE_COLORS, PROFILE_TYPE_ICONS, PROFILE_TYPE_LABELS} from "../../../constants";

export function ProgressLine() {

  const step = useSelector(state => state.onBoarding.step)
  const type = useSelector(state => state.onBoarding.profileType)

  return (
    <>
      <div className="on-boarding-line">
        <div className="on-boarding-line-background" style={{width: `${getOnBoardingPercent(step)}%`}}/>
      </div>
      {type && step !== 'profileType' && (
        <div className="on-boarding-type-icon">
          <img
            src={PROFILE_TYPE_ICONS[type]}
            alt=""
            className="on-boarding-type-icon-image"
          />
          <span
            style={{ color: PROFILE_TYPE_COLORS[type] }}
            className="on-boarding-type-icon-label"
          >
            {PROFILE_TYPE_LABELS[type]}
          </span>
        </div>
      )}
    </>
  )
}
