import React, {useEffect, useRef, useState} from 'react'
import './TextInput.css'
import {isEmpty} from "../../../utils";

interface TextInputI {
  id: string
  label: string
  type: 'email' | 'password'
  bottomText?: string
  value: string
  onChange: (value: string) => void
  placeholder?: string,
  error?: string,
  onKeyDown?: (key: string) => void,
  onRef?: (ref: any) => void,
}

export function TextInput(props: TextInputI) {
  const { id, label, type, bottomText, value, onChange, onKeyDown, placeholder, error, onRef } = props
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && onRef) {
      onRef(ref);
    }
  }, [ref.current]);

  const [secured, setSecured] = useState(true)

  const getType = () => {

    if (type === 'password') {
      if (secured) {
        return 'password'
      } else {
        return 'text'
      }
    }

    return type

  }

  return (
    <div className="u-text-input">
      <label className="u-text-input-label" htmlFor={id}>{label}</label>
      <input
        className={`u-text-input-field${!isEmpty(error) ? ' error' : ''}`}
        type={getType()}
        id={id}
        placeholder={placeholder ? placeholder : ''}
        maxLength={200}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={onKeyDown ? (e) => onKeyDown(e.key) : undefined}
        ref={ref}
      />
      {!isEmpty(error) ? (
          <div className="u-text-input-error">
            <img className="u-text-input-error-icon" src="/images/icons/input/error.svg" alt="Error icon"/>
            <span className="u-text-input-error-message">{error}</span>
          </div>
      ) : ''}
      {bottomText && <span className="u-text-input-bottom-text">{bottomText}</span>}
      {type === 'email' && (
        <div className="u-text-input-icon">
          <img src="images/icons/input/email.svg" alt="Email icon"/>
        </div>
      )}
      {type === 'password' && (
        <div className="u-text-input-icon" onClick={() => {setSecured(!secured)}}>
          {secured ? (
            <img src="images/icons/input/eye-with-line.svg" alt="Eye with line icon"/>
          ) : (
            <img src="images/icons/input/eye.svg" alt="Eye with line icon"/>
          )}
        </div>
      )}
    </div>
  )

}
