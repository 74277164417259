export function isEmpty(value: any) {

  if (value === undefined || value === null) {
    return true
  }

  if (typeof value === 'string' && value.length === 0) {
    return true
  }

  return false

}
