import React from 'react'
import EditIcon from "../../../icons/EditIcon";
import {NavLink} from "react-router-dom";
import {PROFILE_TYPE} from "../../../types/models/profile";
import { getProfileColor } from '../../../utils';

export default function EditProfileLink({type}: {type: PROFILE_TYPE}) {
  return (
    <NavLink to={`/profile/edit/${type}`} className="profile-page-edit-profile profile-text-color"
    style={{color: getProfileColor(type)}}

>
      <EditIcon/>
      Edit profile
    </NavLink>
  )
}
