import { useDispatch, useSelector } from "react-redux";
import "./ProfileSwitcherPopUp.scss";
import { useRef } from "react";
import useClickOutside from "../../../hooks/useClickOutside";
import {
  applicationToggleNavDrawer,
  applicationToggleProfileSwitcherPopUp,
} from "../../../reducers/application";
import CloseIcon from "../../ui/Modal/icons/CloseIcon";
import { ProfileSwitcherProfile } from "../ProfileSwitcher";
import { getProfileColor } from "../../../utils";
import { NavLink, useNavigate } from "react-router-dom";
import { PROFILE_TYPE } from "../../../types/models/profile";
import { changeActiveTypeAction } from "../../../reducers/user";

const ProfileSwitcherPopUp = () => {
  const profileSwitcherPopUpOpened = useSelector(
    (state) => state.application.profileSwitcherPopUp.isOpened
  );
  const navDrawerOpened = useSelector(
    (state) => state.application.navigationDrawer.isOpened
  );
  const activeType = useSelector((state) => state.user.activeType);
  const profiles = useSelector((state) => state.user.profiles);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const popUpContentRef = useRef(null);

  const closePopUp = (e: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    if (profileSwitcherPopUpOpened) {
      dispatch(applicationToggleProfileSwitcherPopUp());
    }
  };

  useClickOutside(popUpContentRef, closePopUp);

  const changeType = (type: PROFILE_TYPE) => {
    dispatch(
      changeActiveTypeAction({
        type,
      })
    );
    dispatch(applicationToggleProfileSwitcherPopUp());
    if (navDrawerOpened) {
      dispatch(applicationToggleNavDrawer());
    }
    navigate("/profile");
  };

  if (!profileSwitcherPopUpOpened) {
    return null;
  }

  return (
    <div
      className={`switcher-pop-up-overlay ${
        profileSwitcherPopUpOpened ? "active" : ""
      }`}
    >
      <div className="switcher-pop-up-content" ref={popUpContentRef}>
        <div className="switcher-pop-up-top">
          <span className="switcher-pop-up-label">Switch account</span>
          <button
            type="button"
            className="switcher-pop-up-close"
            onClick={closePopUp}
          >
            <CloseIcon />
          </button>
        </div>
        <>
          {profiles.map((profile) => {
            return (
              <ProfileSwitcherProfile profile={profile} onClick={changeType} />
            );
          })}
        </>
        {profiles.length < 3 && (
          <NavLink
            to={"/profile/create"}
            className="switcher-pop-up-new-profile-button"
            style={{ backgroundColor: getProfileColor(activeType) }}
            onClick={() => {
              dispatch(applicationToggleProfileSwitcherPopUp());
            }}
          >
            Create new profile
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default ProfileSwitcherPopUp;
