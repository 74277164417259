import React, {useRef, useState} from "react";
import {Photo} from "../../../components/ui/PhotosInput/PhotosInput";
import {CropModal} from "../../../components/ui/PhotosInput/components/CropModal";
import {getRandomString} from "../../../utils";
import {PHOTO_TYPE} from "../../../types/models/photo";
import {PROFILE_TYPE} from "../../../types/models/profile";

export function EditProfilePhotos({
  photos,
  onChange,
  profileType,
  photoType
}: {
  photos: PHOTO_TYPE[]
  onChange: (photos: PHOTO_TYPE[]) => void,
  profileType: PROFILE_TYPE
  photoType: 'public' | 'private'
}) {

  const inputFile = useRef<any>(null)
  const [photo, setPhoto] = useState<string | null>(null)
  const [inputFileKey, setInputFileKey] = useState<string>(getRandomString())

  const openFileUpload = () => {
    inputFile.current.click();
  }

  const onFileChange = async (event: any) => {

    if (event.target.files.length > 0) {
      setInputFileKey(getRandomString())
      setPhoto(URL.createObjectURL(event.target.files[0]))
    }

  }

  const onDelete = (index: number) => {
    photos.splice(index, 1)
    onChange([...photos])
  }

  return (
    <>
      <CropModal
        photo={photo}
        onCancel={() => {
          setPhoto(null)
        }}
        onSubmit={_photo => {
          onChange([
            ...photos,
            _photo
          ])
          setPhoto(null)
        }}
        allowAdult={profileType === 'fun'}
        isPrivate={photoType === 'private'}
      />
      <input
        key={inputFileKey}
        accept={"image/jpeg, image/png"}
        type="file"
        ref={inputFile}
        style={{display: "none"}}
        onChange={onFileChange}
      />
      <div className="edit-profile-photo-area-elements">
        <div className="edit-profile-photo-area-element">
          <Photo photo={photos[0]} onUpload={openFileUpload} onDelete={() => onDelete(0)}/>
        </div>
        <div className="edit-profile-photo-area-element">
          <Photo photo={photos[1]} onUpload={openFileUpload} onDelete={() => onDelete(0)}/>
        </div>
        <div className="edit-profile-photo-area-element">
          <Photo photo={photos[2]} onUpload={openFileUpload} onDelete={() => onDelete(0)}/>
        </div>
        <div className="edit-profile-photo-area-element">
          <Photo photo={photos[3]} onUpload={openFileUpload} onDelete={() => onDelete(0)}/>
        </div>
      </div>
    </>
  )


}
