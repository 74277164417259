import React from 'react'
import {PROFILE_TYPE_COLORS, PROFILE_TYPE_ICONS, PROFILE_TYPE_LABELS} from "../../../constants";
import {PROFILE_TYPE} from "../../../types/models/profile";
import {InfoIcon} from "../../../components";

const TYPE_BACKGROUND = {
  friend: '#DFF0FF',
  flirt: '#EAE4FF',
  fun: '#FFE7FF'
}

const TYPE_INFO_TEXTS = {
  friend: 'account help you to find a gamer buddy, fellow travel enthusiasts and activity partners. New to town? Meet others to help you  enjoy your new home.',
  flirt: 'community is only for when you are looking to make an emotional connection.',
  fun: 'is an Eggplant and Taco friendly place. No need to chat about your life’s hopes and dreams, save that talk for Flirt. '
}
export function ProfileType({type, checked, onClick}: {type: PROFILE_TYPE, checked: boolean, onClick: () => void}) {

  return (
    <div className="on-boarding-profile-type">
      <div
        className="on-boarding-profile-type-inner"
        onClick={onClick}
        style={{backgroundColor: checked ? TYPE_BACKGROUND[type] : 'transparent'}}
      >
        <img src={PROFILE_TYPE_ICONS[type]} alt="" className="on-boarding-profile-type-icon"/>
        <span className="on-boarding-profile-type-label" style={{color: PROFILE_TYPE_COLORS[type]}}>
          {PROFILE_TYPE_LABELS[type]}
        </span>
        {checked &&  <img src={'/images/icons/types/check.svg'} alt="" className="on-boarding-profile-type-check"/>}
      </div>
      <InfoIcon
        content={(
          <>
            <span style={{color: PROFILE_TYPE_COLORS[type]}}>
              {PROFILE_TYPE_LABELS[type]}
            </span>{` `}{TYPE_INFO_TEXTS[type]}
          </>
        )}
      />
    </div>
  )
}
