import * as React from "react"

const VisibilityIcon = () => (
  <svg width="66" height="65" viewBox="0 0 66 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_745_20753)">
      <path d="M13.7031 21.3281C13.7031 24.2911 14.8802 27.1327 16.9753 29.2278C19.0704 31.323 21.912 32.5 24.875 32.5C27.838 32.5 30.6796 31.323 32.7747 29.2278C34.8698 27.1327 36.0469 24.2911 36.0469 21.3281C36.0469 18.3652 34.8698 15.5235 32.7747 13.4284C30.6796 11.3333 27.838 10.1563 24.875 10.1562C21.912 10.1563 19.0704 11.3333 16.9753 13.4284C14.8802 15.5235 13.7031 18.3652 13.7031 21.3281Z" stroke="#6E6A7A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.64059 56.875C8.6241 55.8595 8.625 51.4583 8.625 51.4583C8.625 48.5852 9.76636 45.8297 11.798 43.798C13.8297 41.7664 16.5852 40.625 19.4583 40.625H30.2917C33.1649 40.625 35.9204 41.7664 37.952 43.798C39.9836 45.8297 41.125 48.5852 41.125 51.4583C41.125 51.4583 41.125 55.8594 40.1092 56.875" stroke="#6E6A7A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M41.7993 10.5078C44.1296 11.1045 46.195 12.4597 47.67 14.3599C49.145 16.2601 49.9456 18.5971 49.9456 21.0026C49.9456 23.4081 49.145 25.7451 47.67 27.6453C46.195 29.5455 44.1296 30.9007 41.7993 31.4974" stroke="#6E6A7A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M47.4705 41.1016C50.5687 41.1016 52.758 43.1179 53.3125 43.6724C53.867 44.2269 55.5955 45.7036 56.3594 48.7505C56.3594 48.7505 58.3906 55.548 55.5955 56.9454C52.4026 58.5417 50.1694 58.9066 46.2031 58.9066" stroke="#6E6A7A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_745_20753">
        <rect width="65" height="65" fill="white" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>

)
export default VisibilityIcon
