import { useSelector } from "react-redux";
import { Tab } from "../../components";
import './Settings.scss';
import { AccountDetails } from "./components/AccountDetails";
import { AccountManagement } from "./components/AccountManagement";
import { MyLocation } from "./components/MyLocation";
import { Visibility } from "./components/Visibility";

export function Settings() {

  const email = useSelector(state => state.user.user?.email)

  return (
    <div className="page-with-menu-inner">
      <div className="page-with-menu-top">
        <span className="page-with-menu-top-title">Settings</span>
      </div>
      <div className="settings">
        {email && (
          <Tab
            label={'Account Details'}
            content={(
              <AccountDetails/>
            )}
          />
        )}
        <Tab
          label={'Your Location'}
          content={(<MyLocation/>)}
        />
        <Tab
          label={'Visibility'}
          content={(<Visibility/>)}
        />
        <Tab
          label={'Review Blocked users'}
          content={(<></>)}
          navigateTo={'/settings/blocked_users'}
        />
        <Tab
          label={'Account management'}
          content={(
            <AccountManagement/>
          )}
        />
      </div>
    </div>
  )

}
