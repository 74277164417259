import React from 'react'
import {Circle, GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import './Map.css'
import {distanceInMeters} from "../../../utils";

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '16px'
};

type MapProps = {
  location: {
    lat: number,
    lng: number
  } | null,
  circle?: {
    center: {
      lat: number,
      lng: number,
    },
    radius: number
  },
  onClick: (e: google.maps.MapMouseEvent) => void
}

const defaultCenter = {
  lat: 34.052235,
  lng: -118.243683
};

export function MapComponent({location, onClick, circle} : MapProps) {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ? process.env.REACT_APP_GOOGLE_API_KEY : ''
  })

  return (
    <div className="map-area">
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={location ? {
            lat: Number(location.lat),
            lng: Number(location.lng)
          } : defaultCenter}
          zoom={10}
          options={{
            mapTypeControl: false,
            controlSize: 24
          }}
          onClick={(e) => {
            if (circle) {

              let _lat = e.latLng?.lat()
              let _lng = e.latLng?.lng()

              if (_lat && _lng) {

                let distance = distanceInMeters(
                  Number(circle.center.lat),
                  Number(circle.center.lng),
                  _lat,
                  _lng,
                );
                if (distance > circle.radius) {
                  return;
                }

              }

            }
            onClick(e)
          }}
        >
          {location && (
            <Marker
              key={'selectedLocation'}
              position={{
                lat: Number(location.lat),
                lng: Number(location.lng)
              }}
              icon={{
                url: ('https://web.heyheyheyapp.com/images/icons/location/marker.svg'),
                scaledSize:  new google.maps.Size(45,45)
              }}
            />
          )}
          {location && circle && (
            <Circle
              center={circle.center}
              radius={circle.radius}
              options={{
                strokeColor: 'rgba(77,146,223,0.40)',
                fillColor: 'rgba(77,146,223,0.40)',
                clickable: false
              }}
            />
          )}
        </GoogleMap>
      )}
    </div>
  )

}
