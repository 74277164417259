import axios from "axios";
import {API_URL} from "../constants";
import {PROFILE_TYPE} from "../types/models/profile";

export const apiChatCreate = (
  token: string,
  profile_id: number
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/v3/web/chat.create`, {
      profile_id,
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.chat);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiChatSendTextMessage = (
  token: string,
  type: PROFILE_TYPE,
  chatId: number,
  text: string
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/v3/web/chat.send_message`, {
      type,
      chat_id: chatId,
      text
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiChatsGet = (
  token: string,
  type: PROFILE_TYPE,
): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/v3/web/chats.get?type=${type}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.chats);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiChatGetMessages = (
  token: string,
  chatId: number
): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/v3/web/chat.get_messages?chat_id=${chatId}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.messages);
    }).catch(function (error) {
      reject(error);
    })

  })

}
