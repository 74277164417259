import axios from "axios";
import {API_URL} from "../constants";

export const apiPhotoUpload = (token: string, file: any): any => {

  return new Promise((resolve, reject) => {

    let data = new FormData();
    data.append("file", file);

    axios.post(API_URL + '/v3/web/photo.upload', data, {
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8;',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}
