import React, {ReactElement} from 'react'

type UserIconProps = {
  icon: ReactElement
  label: string
}

export function UserIcon({icon, label}: UserIconProps) {

  return (
    <div className="user-icon profile-background-color-opacity">
      <div className="user-icon-image profile-text-color">{icon}</div>
      <span className="user-icon-label">{label}</span>
    </div>
  )

}
