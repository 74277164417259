import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  getProfile,
  isEmpty,
  sendSuccessNotification, validateDate
} from "../../utils";
import './EditProfile.scss';
import { EditProfilePhotos } from './components/EditProfilePhotos';
import {PROFILE_TYPE, PROFILE_VALUE} from "../../types/models/profile";
import {profileUpdateAction} from "../../reducers/user";
import {Tab} from "../../components";
import moment from "moment";
import {ProfileTabs} from "../../components/ProfileTabs/ProfileTabs";
import {EditProfilePhoto} from "./components/EditProfilePhoto";
import {PHOTO_TYPE} from "../../types/models/photo";
import {Modal} from "../../components/ui/Modal/Modal";
import {AlertModal} from "../../components/AlertModal/AlertModal";

export type onChangeProfileValueType = (id: number, value: string, multiply?: boolean) => void

const convertFromValues = (values: PROFILE_VALUE[]) => {

  return values.map(value => {
    if ([404, 509, 619].indexOf(value.type_id) !== -1) {
      if (!isEmpty(value)) {
        return {
          ...value,
          value: moment(value.value).format('MM/DD/YYYY')
        }
      }
    }
    return value
  })

}

const convertToValues = (values: PROFILE_VALUE[]) => {

  return values.map(value => {
    if ([404, 509, 619].indexOf(value.type_id) !== -1) {
      if (!isEmpty(value)) {
        return {
          ...value,
          value: moment(value.value).format('YYYY-MM-DD')
        }
      }
    }
    return value
  })

}

export function EditProfile({type}: {type: PROFILE_TYPE}) {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const profiles = useSelector(state => state.user.profiles)

  const profile = getProfile(profiles, type)

  const [values, setValues] = useState<PROFILE_VALUE[]>(profile ? convertFromValues(profile.values) : [])
  const [name, setName] = useState<string>(profile ? profile.name : '')
  const [publicPhotos, setPublicPhotos] = useState<PHOTO_TYPE[]>(profile ? profile.publicPhotos: [])
  const [privatePhotos, setPrivatePhotos] = useState<PHOTO_TYPE[]>(profile ? profile.privatePhotos: [])
  const [avatar, setAvatar] = useState<null | PHOTO_TYPE>(profile?.avatar ? {
    path: profile.avatar,
    adult: profile.adult,
    status: profile.adult_status
  } : null)
  const [error, setError] = useState<string | null>(null)
  const saveDisabled = useSelector((store) => store.application.editProfile.saveDisabled);

  if (profile === null) {
    return <div/>
  }

  const save = () => {

    for (let i = 0; i < values.length; ++i) {

      if ([404, 509, 619].indexOf(values[i].type_id) !== -1) {
        const {error: _error} = validateDate(values[i].value);
        if (_error !== null && error === null) {
          setError(_error)
          return
        }
      }

    }

    dispatch(profileUpdateAction({
      id: profile.id,
      values: convertToValues(values),
      publicPhotos,
      privatePhotos,
      name,
      avatar: avatar?.path,
      adult: avatar?.adult,
      adult_status: avatar?.status
    }))
    sendSuccessNotification(dispatch)
    navigate('/profile')

  }

  return (
    <div className="page-with-menu-inner">
      <Modal
        open={error !== null}
        onClose={() => {setError(null)}}
        content={(
          <AlertModal
            onClose={() => {setError(null)}}
            error={error ? error : ''}
          />
        )}
      />
      <div className="page-with-menu-top">
        <span className="page-with-menu-top-title">Edit Profile</span>
      </div>
      <div className="edit-profile">
        <div className="edit-profile-left">
          {avatar && (
            <EditProfilePhoto
              avatar={avatar}
              onChange={(_avatar) => {
                setAvatar(_avatar)
              }}
              profileType={profile.type}
            />
          )}
          <div className="edit-profile-photo-area">
            <span className="edit-profile-photo-area-label">Public Photos</span>
            <span className="edit-profile-photo-area-text">Your public photos can be seen by anyone. No nudes in here.</span>
            <EditProfilePhotos
              photos={publicPhotos}
              onChange={photos => {
                setPublicPhotos(photos)
              }}
              photoType={'public'}
              profileType={profile.type}
            />
          </div>
          <div className="edit-profile-photo-area">
            <span className="edit-profile-photo-area-label">Private Photos</span>
            <span className="edit-profile-photo-area-text">Your photos are private. You can select to whom you like to see them privately.</span>
            <EditProfilePhotos
              photos={privatePhotos}
              onChange={photos => {
                setPrivatePhotos(photos)
              }}
              photoType={'private'}
              profileType={profile.type}
            />
          </div>
        </div>
        <div className="edit-profile-right">
          <Tab
            label={'My name'}
            content={(
              <input
                type="text"
                value={name}
                onChange={(event) => {
                  setName(event.target.value)
                }}
                className={'edit-profile-tab-input'}
              />
            )}
          />
          <ProfileTabs
            type={type}
            onChangeValues={values => {setValues(values)}}
            values={values}
            onBoarding={false}
          />
        </div>
      </div>
      <div className="edit-profile-bottom">
        <NavLink to={'/profile'} className="edit-profile-cancel-link">Cancel Changes</NavLink>
        <button
          className="edit-profile-submit-button"
          onClick={save}
          disabled={saveDisabled}
        >
          Save
        </button>
      </div>
    </div>
  )

}
