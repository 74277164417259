import React from 'react'
import {Button} from "../../../components";
import {useNavigate} from "react-router-dom";
import LogoWithIcon from "../../../icons/LogoWithIcon";

export function ResetPasswordActivation() {

  const navigate = useNavigate()

  const submit = () => {
    navigate('/')
  }

  return (
    <div className="sign-up">
      <div className="sign-up-inner">
        <div className="sign-up-logo-with-icon">
          <LogoWithIcon/>
        </div>
        <span className="sign-up-title">Password changed </span>
        <span className="sign-up-text">You have successfully changed your password and can now log back in </span>
        <div className="sign-up-button">
          <Button label={'Log in'} onClick={submit}/>
        </div>
      </div>
    </div>
  )

}
