import React, {useEffect, useRef, useState} from 'react'
import Cropper, {ReactCropperElement} from "react-cropper";
import {useSelector} from "react-redux";
import {apiPhotoUpload} from "../../../../actions/photoController";
import {Modal} from "../../Modal/Modal";
import {PHOTO_TYPE} from "../../../../types/models/photo";
import {DialogModal} from "../../../DialogWindow/DialogModal";
import useModal from "../../../../hooks/useModal";

const blobToBase64 = async (blob: any): Promise<string> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

export function CropModal({photo, onCancel, onSubmit, isPrivate, allowAdult}: {
  photo: string | null
  onCancel: () => void
  onSubmit: (photo: PHOTO_TYPE) => void
  isPrivate: boolean
  allowAdult: boolean
}) {

  const token = useSelector(state => state.user.token)
  const cropperRef = useRef<ReactCropperElement>(null);
  const [loading, setLoading] = useState(false)
  const [photoObj, setPhotoObj] = useState<PHOTO_TYPE | null>(null);
  const [croppedImg, setCroppedImg] = useState<any>();
  const [informationModalOpen, openInformationModal, closeInformationModal, informationModalObj] = useModal()

  useEffect(() => {

    if (photo) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

  }, [photo])

  const submit = async () => {
    if (photoObj !== null) {
      onSubmit(photoObj)
      setPhotoObj(null)
      return
    }
    const cropper = cropperRef.current?.cropper;
    if (cropper && token) {

      cropper.getCroppedCanvas().toBlob(async (result) => {
        try {
          setLoading(true);
          setCroppedImg(await blobToBase64(result));
          const response = await apiPhotoUpload(token, result);
          if (response.adult) {
            if (!isPrivate && !allowAdult) {
              openInformationModal({
                text: 'This photo violates our Ts & Cs. Please choose another.'
              })
              return
            }
          }
          if (response.adult && !isPrivate) {
            openInformationModal({
              text: 'Your private photo will be sent for review',
              onSubmit: () => {
                closeInformationModal()
                setPhotoObj({
                  path: response.path,
                  adult: response.adult  && allowAdult && !isPrivate,
                  status: (!isPrivate && allowAdult && response.adult) ? 1 : 0
                })
              }
            })
          } else {
            setPhotoObj({
              path: response.path,
              adult: response.adult  && allowAdult && !isPrivate,
              status: (!isPrivate && allowAdult && response.adult) ? 1 : 0
            })
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }

      });

    }
  }

  const onPressCancel = () => {
    setPhotoObj(null)
    onCancel()
  }

  if (informationModalOpen) {
    return (
      <DialogModal
        open={informationModalOpen}
        onCancel={() => {
          onPressCancel()
          closeInformationModal()
        }}
        onSubmit={informationModalObj?.onSubmit}
        text={informationModalObj?.text}
      />
    )
  }

  if (photo !== null) {
    return (
      <Modal
        onClose={onCancel}
        open={true}
        content={(
          <>
            <div className="crop-modal-top">
              <span className="crop-modal-top-title">Crop your photo</span>
            </div>
            <div className="crop-modal-content">
              {loading ? (
                <div className="crop-modal-loader">
                  <span className="loader"/>
                </div>
              ) : (
                (photoObj === null ? (
                  <Cropper
                    src={photo}
                    className='cropper'
                    initialAspectRatio={16 / 16}
                    guides={false}
                    ref={cropperRef}
                  />
                ) : (
                  <div className="crop-modal-loader">
                    <img src={croppedImg} className="crop-modal-image"/>
                  </div>
                ))
              )}
            </div>
            <div className="modal-bottom">
              <button className="modal-cancel-button" onClick={onPressCancel}>Cancel</button>
              <button className="modal-submit-button" disabled={loading} onClick={submit}>Save</button>
            </div>
          </>
        )}
      />
    )
  }

  return <div/>

}
