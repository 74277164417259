import React, { useState } from "react";
import "./MyProfile.scss";
import { useSelector } from "react-redux";
import { getFullImageUrl, getProfile } from "../../utils";
import ProfilePageInfo from "./components/ProfilePageInfo";
import EditProfileLink from "./components/EditProfileLink";
import ProfilePhotos, { TITLES } from "./components/ProfilePhotos";
import useBreakpoints from "../../hooks/useBreakpoints";
import { ProfileSwitcher } from "../../components";
import { DropDownItem } from "../../components/DropDown/DropDown";
import {PhotoLabel} from "../../components/ui/PhotosInput/PhotosInput";

const PHOTO_BLOCKS_IDS = {
  public: "view-profile-public-photos",
  private: "view-profile-private-photos",
};

export function MyProfile() {
  const profiles = useSelector((state) => state.user.profiles);
  const activeType = useSelector((state) => state.user.activeType);

  const [tabsOpened, setTabsOpened] = useState<{ [key: string]: boolean }>({
    [PHOTO_BLOCKS_IDS.public]: false,
    [PHOTO_BLOCKS_IDS.private]: false,
  });

  const profile = getProfile(profiles, activeType);

  const { lgUp } = useBreakpoints();

  if (profile === null) {
    return <div />;
  }

  return (
    <div className={lgUp ? "page-with-menu-inner" : "page-without-menu-inner"}>
      <div className="profile-page">
        <div className="profile-page-left">
          {!lgUp && <ProfileSwitcher />}
          <div className="profile-page-main-photo">
            <PhotoLabel adult={profile.adult} adultStatus={profile.adult_status}/>
            <img
              src={getFullImageUrl(profile.avatar)}
              alt=""
              className="profile-page-main-photo-image"
            />
          </div>
          {lgUp && (
            <>
              {profile.publicPhotos.length > 0 && (
                <ProfilePhotos photos={profile.publicPhotos} type={"public"} text={false}/>
              )}
              {profile.privatePhotos.length > 0 && (
                <ProfilePhotos
                  photos={profile.privatePhotos}
                  type={"private"}
                />
              )}
            </>
          )}
        </div>
        <div className="profile-page-right">
          <div className="profile-page-right-top">
            <span className="profile-page-name">{profile.name}</span>
            {lgUp && <EditProfileLink type={profile.type} />}
          </div>
          <ProfilePageInfo profile={profile} />
          {!lgUp && (
            <>
              {profile.publicPhotos.length > 0 && (
                <DropDownItem
                  tabOpened={tabsOpened[PHOTO_BLOCKS_IDS.public]}
                  item={{
                    itemName: TITLES.public,
                    itemContent: (
                      <ProfilePhotos
                        photos={profile.publicPhotos}
                        type={"public"}
                      />
                    ),
                    id: PHOTO_BLOCKS_IDS.public,
                  }}
                  onToggleDropdownItem={() => {
                    setTabsOpened((state) => ({
                      ...state,
                      [PHOTO_BLOCKS_IDS.public]: !state[
                        PHOTO_BLOCKS_IDS.public
                      ],
                    }));
                  }}
                />
              )}
              {profile.privatePhotos.length > 0 && (
                <DropDownItem
                  tabOpened={tabsOpened[PHOTO_BLOCKS_IDS.private]}
                  item={{
                    itemName: TITLES.private,
                    itemContent: (
                      <ProfilePhotos
                        photos={profile.privatePhotos}
                        type={"private"}
                      />
                    ),
                    id: PHOTO_BLOCKS_IDS.private,
                  }}
                  onToggleDropdownItem={() => {
                    setTabsOpened((state) => ({
                      ...state,
                      [PHOTO_BLOCKS_IDS.private]: !state[
                        PHOTO_BLOCKS_IDS.private
                      ],
                    }));
                  }}
                />
              )}
              <EditProfileLink type={profile.type} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
