import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getTemplate } from "../../../utils";
import {PROFILE, USER_PROFILE} from "../../../types/models/profile";
import useBreakpoints from "../../../hooks/useBreakpoints";
import DropDown from "../../../components/DropDown/DropDown";
import moment from "moment";

export default function ProfilePageInfo({ profile }: { profile: PROFILE | USER_PROFILE}) {
  const templates = useSelector((state) => state.application.templates);

  const { lgUp } = useBreakpoints();

  const singleTemplate = useMemo(() => {
    const data = getTemplate(templates, profile.type, "single")
      .map((element: any) => {
        const blockIds = element.elements.map((_element: any) => _element.id);
        const blockValues = profile.values.filter(
          (value) => blockIds.indexOf(value.type_id) !== -1
        );
        return { ...element, blockValues };
      })
      .filter((element: any) => element.blockValues.length);

    return data;
  }, [profile, templates]);

  if (profile === null) {
    return null;
  }

  const getBlockContent = (element: any) => {
    return element.elements.map((block: any) => {
      const values = profile.values.filter(
        (value) => value.type_id === block.id
      );

      let age = null;
      let weight = null;
      let height = null;

      if (block.type === 'details') {
        const ageValue = profile.values.find(value => value.type_id === block.birthdate_id)
        if (ageValue) {
          age = moment().diff(ageValue.value, 'years');
        }
        const weightValue = profile.values.find(value => value.type_id === block.weight_id)
        if (weightValue) {
          weight = weightValue.value
        }
        const heightValue = profile.values.find(value => value.type_id === block.height_id)
        if (heightValue) {
          height = heightValue.value
        }
      }

      if (values.length > 0 || block.type === 'details') {
        return (
          <div
            className="profile-page-info-area-element"
            key={`${block.label}-${block.id}`}
          >
            {block.label.length > 0 && (
              <span className="profile-page-info-area-element-title">
                {block.label}
              </span>
            )}
            {block.type === "large_text" && (
              <span className="profile-page-info-area-element-text">
                {values[0].value}
              </span>
            )}
            {block.type === "details" && (
              <div className="profile-page-info-area-element-tips">
                {age ? <span className="profile-page-info-area-element-tip">{age} years</span> : null}
                {weight ? <span className="profile-page-info-area-element-tip">{weight} lbs</span> : null}
                {height ? <span className="profile-page-info-area-element-tip">{height} ft</span> : null}
              </div>
            )}
            {["tips", "value"].indexOf(block.type) !== -1 && (
              <div className="profile-page-info-area-element-tips">
                {values.map((value) => {
                  return (
                    <span
                      className="profile-page-info-area-element-tip"
                      key={`${value.value}-${value.type_id}`}
                    >
                      {value.value}
                    </span>
                  );
                })}
              </div>
            )}
          </div>
        );
      }
    });
  };

  return (
    <>
      {profile.location && (
        <span className="profile-page-location">
          <img
            src="/images/icons/profile/location.svg"
            alt=""
            className="profile-page-location-icon"
          />
          {profile.location}
        </span>
      )}
      <div className="profile-page-info">
        {lgUp ? (
          singleTemplate.map((element: any) => {
            return (
              <div
                className="profile-page-info-area"
                key={element.label.toLowerCase().replace(/ /g, "")}
              >
                <span className="profile-page-info-area-title">
                  {element.label}
                </span>
                {getBlockContent(element)}
              </div>
            );
          })
        ) : (
          <DropDown
            items={singleTemplate.map((element: any) => ({
              id: element.label.toLowerCase().replace(/ /g, "_"),
              itemName: element.label,
              itemContent: getBlockContent(element),
            }))}
          />
        )}
      </div>
    </>
  );
}
