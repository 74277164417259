import React from 'react'
import { OtpInput } from '../../../components'
import {useDispatch} from "react-redux";
import {userSignInAction} from "../../../reducers/user";
import {apiUserConfirmCheck, createUserFromResponse} from "../../../actions/userActions";
import {useNavigate} from "react-router-dom";
import {sendSmallNotification} from "../../../utils";
import LogoWithIcon from "../../../icons/LogoWithIcon";

export default function CodeConfirm({phone, onBack, confirmId}: {phone: string, onBack: () => void, confirmId: number}) {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onSubmit = async (code: string) => {

    const response = await apiUserConfirmCheck(confirmId, code)
    if (response.status === 'ok') {
      dispatch(userSignInAction({
        token: response.token,
        user: createUserFromResponse(response.user),
        profiles: response.profiles,
      }));
      navigate('/profile')
    } else {
      sendSmallNotification(dispatch,{
        title: 'Wrong verification code',
        message: 'Try to type it once again or resend the code.',
        type: 'error'
      })
    }

  }

  return (
    <div className="sign-up">
      <div className="sign-up-inner">
        <div className="sign-up-logo-with-icon">
          <LogoWithIcon/>
        </div>
        <span className="sign-up-title">Phone Verification</span>
        <span className="sign-up-text">Please enter the 4-digit verification code that was sent to <b>{phone.replaceAll(' ', '')}</b>. The code is valid for 30 minutes.</span>
        <OtpInput onSubmit={code => {
          onSubmit(code)
        }}/>
        <span className="sign-up-resend">Didn't get the code? <a href="/" className="sign-up-resend-link">Resend</a></span>
        <span className="sign-up-back" onClick={onBack}>Back</span>
      </div>
    </div>
  )

}
