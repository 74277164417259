import moment from "moment";
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { apiNotificationsGet } from "../../actions/notificationActions";
import { NOTIFICATION_TYPE } from "../../types/models/notification";
import './Notifications.scss';
import { Notification } from "./components/Notification";
import NotificationIcon from './icons/NotificationsIcon';

type NOTIFICATION_AREA_TYPE = {
  today: NOTIFICATION_TYPE[],
  archive: NOTIFICATION_TYPE[]
}

const groupNotifications = (result: NOTIFICATION_AREA_TYPE, notifications: NOTIFICATION_TYPE[]): NOTIFICATION_AREA_TYPE => {

  let dayStart = moment().startOf('day')

  notifications.forEach(notification => {

    if (moment(notification.created_at) > dayStart) {
      result.today.push(notification)
      return
    }

    result.archive.push(notification)


  })

  return result

}

const NotificationsArea = ({
  notifications, label
}: {
  notifications: NOTIFICATION_TYPE[],
  label: string
}) => {

  return (
    <div className="notifications-area">
      <span className="notifications-area-label">{label}</span>
      {notifications.map(notification => {
        return (
          <Notification notification={notification}/>
        )
      })}
    </div>
  )

}

export function Notifications() {

  const activeType = useSelector(state => state.user.activeType)
  const token = useSelector(state => state.user.token)

  const [notifications, setNotifications] = useState<NOTIFICATION_AREA_TYPE>({
    today: [],
    archive: []
  })
  const [loading, setLoading] = useState(true)
  const [offset, setOffset] = useState(0)
  const [isEnd, setIsEnd] = useState(false)

  useEffect(() => {
    loadNotifications()
  }, [])

  const loadNotifications = async () => {

    if (token && activeType) {
      setLoading(true)
      const response = await apiNotificationsGet(token, activeType, offset)
      setNotifications(groupNotifications(notifications, response))
      setLoading(false)
    }

  }

  const loadMore = async () => {

    if (token && activeType) {
      const response = await apiNotificationsGet(token, activeType, offset + 30)
      setNotifications(groupNotifications(notifications, response))
      setOffset(offset + 30)
      if (response.length === 0) {
        setIsEnd(true)
      }
    }

  }

  return (
    <div className="page-with-menu-inner">
      <div className="page-with-menu-top">
        <span className="page-with-menu-top-title">Notifications</span>
      </div>
      {loading ? (
        <div className="notifications-loader">
          <div className="loader"/>
        </div>
      ) : (
        <>
          {(notifications.today.length > 0 || notifications.archive.length > 0) ? (
            <div className="notifications">
              <span className="notifications-text">When someone sends <span className="profile-text-color">HEY</span> or requests to view your private photos, you'll see it here.</span>
              {notifications.today.length > 0 && (
                <NotificationsArea
                  notifications={notifications.today}
                  label={'Today'}
                />
              )}
              {notifications.archive.length > 0 && (
                <NotificationsArea
                  notifications={notifications.archive}
                  label={''}
                />
              )}
              {!isEnd && (
                <div className="notifications-bottom">
                  <span className="notifications-upload-more-link profile-text-color" onClick={loadMore}>Upload more information</span>
                </div>
              )}
            </div>
          ) : (
            <div className="notifications-empty">
              <div className="notifications-empty-icon">
                <NotificationIcon/>
              </div>
              <span className="notifications-empty-title">No notifications here yet</span>
              <span className="notifications-empty-text">When someone sends HEY or requests to view your private photos, you'll see it here.</span>
            </div>
          )}
        </>
      )}
    </div>
  )

}
