import UAParser from "ua-parser-js";

const BROWSERS = [ 'Chrome', 'Edge', 'Firefox', 'Safari', 'Mobile Safari' ]
const OS_es = [ 'Android', 'Windows', 'iOS', 'Mac OS' ];

const parser = new UAParser();
const parseResult = parser.getResult();
const currentOS = parseResult.os.name as OS;
const currentBrowser = parseResult.browser.name as Browser;

export type OS = 'Android' | 'Windows' | 'iOS' | 'Mac OS' | 'Other';
export type Browser = 'Chrome' | 'Edge' | 'Firefox' | 'Safari' | 'Other';

const getUserAgentInfo = () => {
  let os: OS = 'Other';
  let browser: Browser = 'Other';
  if (currentOS && OS_es.includes(currentOS)) {
    os = currentOS;
  }
  if (currentBrowser && BROWSERS.includes(currentBrowser)) {
    if (new RegExp(/Safari/gm).test(currentBrowser)) {
      browser = 'Safari'
    } else {
      browser = currentBrowser;
    }
  }
  return { os, browser, rawInfo: parseResult };
}

export default getUserAgentInfo;