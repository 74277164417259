import {ActionProps} from "./store";
import {USER_LOCATION_TYPE} from "../types/models/user";
import {PROFILE_TYPE, PROFILE_VALUE} from "../types/models/profile";
import {PHOTO_TYPE} from "../types/models/photo";

const ON_BOARDING_UPDATE = 'ON_BOARDING_UPDATE';
const ON_BOARDING_CLEAN = 'ON_BOARDING_CLEAN'

type ON_BOARDING_STEP_TYPE = 'profileType' | 'sex' | 'location' | 'name' | 'avatar' | 'publicPhotos' | 'privatePhotos' | 'birthdate' | 'about' | 'other'

export type ON_BOARDING_STATE_TYPE = {
  sex: 'male' | 'female' | null
  location: USER_LOCATION_TYPE
  profileType: PROFILE_TYPE | null
  name: string
  privatePhotos: PHOTO_TYPE[],
  publicPhotos: PHOTO_TYPE[],
  avatar: PHOTO_TYPE | null,
  step: ON_BOARDING_STEP_TYPE
  birthdate: string
  values: PROFILE_VALUE[],
  isOnboardingFinished: boolean,
}

export const ON_BOARDING_DEFAULT_LOCATION = {
  location: null,
  lat: '34.052235',
  lng: '-118.243683'
}

const defaultState: ON_BOARDING_STATE_TYPE = {
  sex: null,
  location: ON_BOARDING_DEFAULT_LOCATION,
  profileType: null,
  name: '',
  privatePhotos: [],
  publicPhotos: [],
  avatar: null,
  step: 'profileType',
  birthdate: '',
  values: [],
  isOnboardingFinished: false,
}

export const getOnBoardingPercent = (step: ON_BOARDING_STEP_TYPE) => {

  const steps = ['sex', 'location', 'profileType', 'name', 'avatar', 'publicPhotos', 'privatePhotos', 'birthdate', 'about', 'relationships', 'other']
  return (steps.indexOf(step) + 3) * 10;

}

export const onBoardingReducer = (state = defaultState, action: ActionProps) => {
  switch (action.type) {
    case ON_BOARDING_UPDATE:
      return {
        ...state,
        ...action.payload.values
      }
    case ON_BOARDING_CLEAN:
      return {
        ...state,
        name: '',
        publicPhotos: [],
        privatePhotos: [],
        avatar: null,
        step: action.payload.type !== null ? 'name' : 'profileType',
        profileType: action.payload.type,
        birthdate: '',
        values: [],
      }
    default:
      return state;
  }
};

export const onBoardingUpdateAction = (payload: any) => ({type: ON_BOARDING_UPDATE, payload});
export const onBoardingCleanAction = (payload: any) => ({type: ON_BOARDING_CLEAN, payload})
