import React, {useEffect, useRef, useState} from 'react'
import './PhoneInput.css'
import {countriesWithFlag, PhoneCountryType} from "./contants";
import InputMask from 'react-input-mask';
import {isEmpty} from "../../../utils";

export type PhoneType = {
  country: PhoneCountryType,
  phone: string
}

export const phoneToString = (phone: PhoneType) => {
  return `${phone.country.dialCode}${phone.phone}`
}

interface PhoneInputI {
  phone: PhoneType,
  error: string
  onChange: (phone: PhoneType) => void,
  onRef?: (ref: any) => void,
  onKeyDown?: (key: string) => void,
}

export function PhoneInput({
  phone,
  error,
  onChange,
  onRef,
  onKeyDown
}: PhoneInputI) {

  const phoneInputRef = useRef<null | HTMLInputElement>(null);

  const [submenuOpen,  setSubmenuOpen] = useState(false)

  useEffect(() => {
    phoneInputRef?.current?.focus();
  }, [phone]);

  useEffect(() => {
    if (phoneInputRef.current && onRef) {
      onRef(phoneInputRef)
    }
  }, [phoneInputRef.current])

  const changeCountry = (country: PhoneCountryType) => {

    onChange({
      country,
      phone: ''
    })
    setSubmenuOpen(false)
  }

  return (
    <div className='u-phone-input'>
      <label className="u-phone-input-label" htmlFor={'phoneInput'}>Phone Number</label>
      <div className={`u-phone-input-field${!isEmpty(error) ? ' error' : ''}`}>
        <div className="u-phone-input-field-selector" onClick={() => {setSubmenuOpen(!submenuOpen)}}>
          <span className="u-phone-input-field-flag">{phone.country.flag}</span>
          <img className="u-phone-input-arrow-down" src={'/images/icons/input/arrow-down.svg'}/>
          <span className="u-phone-input-field-code">{phone.country.dialCode}</span>
        </div>
        <InputMask
          id={'phoneInput'}
          mask={phone.country.mask}
          maskChar=" "
          className="u-phone-input-field-input"
          value={phone.phone}
          onChange={(event) => {
            onChange({
              ...phone,
              phone: event.target.value
            })
          }}
          onKeyDown={onKeyDown ? (e) => onKeyDown(e.key) : undefined}
          inputRef={(ref) => phoneInputRef.current = ref}
        />
      </div>
      {!isEmpty(error) ? (
        <div className="u-phone-input-error">
          <img className="u-phone-input-error-icon" src="/images/icons/input/error.svg" alt="Error icon"/>
          <span className="u-phone-input-error-message">{error}</span>
        </div>
      ) : ''}
      {submenuOpen && (
        <div className="u-phone-input-submenu">
          {countriesWithFlag.map(country => {
            return (
              <div className="u-phone-input-submenu-element" onClick={() => {changeCountry(country)}}>
                <span className="u-phone-input-submenu-element-flag">{country.flag}</span>
                <span className="u-phone-input-submenu-element-name">{country.en}</span>
                <span className="u-phone-input-submenu-element-code">{country.dialCode}</span>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )

}
