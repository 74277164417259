import { Header, SmallNotifications } from "../../components";
import { Footer } from "../../components/Footer/Footer";
import './MainLayout.css';

const MainLayout = ({ children }: any) => {

  return (
    <div className="main-layount-container">
      <SmallNotifications/>
      <div className="header-container">
        <Header/>
      </div>
      <div className="content-container">
        {children}
      </div>
      <Footer/>
    </div>
  )

}

export default MainLayout;
