import React from "react";
import {getFullImageUrl, getProfile, sendSmallNotification} from "../../../utils";
import {PROFILE_TYPE} from "../../../types/models/profile";
import {PrivatePhotoModal} from "../../../components/PrivatePhotoModal/PrivatePhotoModal";
import useModal from "../../../hooks/useModal";
import {Modal} from "../../../components/ui/Modal/Modal";
import CheckMark from "../icons/CheckMark";
import {Switcher} from "../../../components/ui/Switcher/Switcher";
import {apiProfileRemoveSharePhotoAccess, apiProfileSharePhotoAccess} from "../../../actions/profileActions";
import {useDispatch, useSelector} from "react-redux";
import {PHOTO_TYPE} from "../../../types/models/photo";

type PrivatePhotosType = {
  photos: PHOTO_TYPE[];
  privatePhotosStatus: 'allow' | 'request' | 'no_access',
  privatePhotosNum: number
  onChange: (privatePhotosStatus: 'allow' | 'request' | 'no_access') => void
  profileId: number
  sharePhotosStatus: 0 | 1
  onChangeSharePhotosStatus: (sharePhotosStatus: 0 | 1) => void
  type: PROFILE_TYPE
};


export default function PrivatePhotos({ photos, privatePhotosStatus, privatePhotosNum, onChange, profileId, sharePhotosStatus, onChangeSharePhotosStatus, type}: PrivatePhotosType) {

  const [modalOpen, openModal, closeModal] = useModal()
  const token = useSelector(state => state.user.token)

  const dispatch = useDispatch()

  const profiles = useSelector(state => state.user.profiles)
  const profile = getProfile(profiles, type)

  const sharePhotos = (checked: boolean) => {

    if (token) {

      if (checked) {
        apiProfileSharePhotoAccess(token, profileId)
        sendSmallNotification(dispatch, {
          title: 'Your share your private photos',
          type: 'success'
        })
      } else {
        apiProfileRemoveSharePhotoAccess(token, profileId)
        sendSmallNotification(dispatch, {
          title: 'Your remove access to view your private photos',
          type: 'success'
        })
      }
      onChangeSharePhotosStatus(checked ? 1 : 0)

    }

  }

  if (privatePhotosNum > 0) {
    return (
      <div className="profile-page-photo-area">
        <Modal
          open={modalOpen}
          content={(
            <PrivatePhotoModal
              privatePhotosStatus={privatePhotosStatus}
              onCancel={closeModal}
              onChange={onChange}
              profileId={profileId}
            />
          )}
          onClose={closeModal}
        />
        <span className="profile-page-photo-area-label">Private Photos</span>
        {profile && profile.privatePhotos.length > 0 ? (
          <div className="profile-page-photo-area-share">
            <Switcher
              label={'Allow view my private photos'}
              checked={sharePhotosStatus === 1}
              onChange={(checked) => {sharePhotos(checked)}}
            />
          </div>
        ): <div/>}
        {privatePhotosStatus !== 'allow' ? (
          <div className="profile-page-photo-area-elements">
            {privatePhotosStatus === 'request' ? (
              <span className="profile-page-photo-area-request-label profile-text-color"> <CheckMark/>Your request has been sent</span>
            ): <span/>}
            {Array.from(Array(privatePhotosNum).keys()).map(() => {
              return (
                <div className="profile-page-photo-area-element">
                  <img
                    onClick={openModal}
                    src={'/images/placeholders/bloorImage.png'}
                    alt=""
                    className="profile-page-photo-area-element-image profile-page-photo-area-element-private"
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="profile-page-photo-area-elements">
            {photos.map((photo) => {
              return (
                <div className="profile-page-photo-area-element">
                  <img
                    src={getFullImageUrl(photo.path)}
                    alt=""
                    className="profile-page-photo-area-element-image"
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  return <div/>
}
