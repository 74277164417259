import * as React from "react"
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M26.183 4.281a.843.843 0 0 1 1.033-.596c2.165.58 3.938 1.918 5.014 3.767a.844.844 0 0 1-1.463.844c-.844-1.46-2.258-2.518-3.986-2.981a.843.843 0 0 1-.598-1.034ZM11.015 31.962c-1.349-1.063-2.472-2.416-3.535-4.26a.844.844 0 1 0-1.463.844c1.176 2.035 2.432 3.541 3.953 4.74a.843.843 0 1 0 1.045-1.324Zm20.914-8.578a12.11 12.11 0 0 1-11.662 8.959 12.195 12.195 0 0 1-3.15-.422 12.018 12.018 0 0 1-7.344-5.625L4.43 17.04a3.656 3.656 0 0 1 3.33-5.484l-.704-1.228a3.656 3.656 0 0 1 5.869-4.282 3.655 3.655 0 0 1 6.454-.235l2.398 4.152a3.657 3.657 0 0 1 6.411-.141l2.531 4.385a12.018 12.018 0 0 1 1.21 9.171v.006Zm-2.672-8.334-2.531-4.384a1.97 1.97 0 0 0-3.41 1.968l1.406 2.436a.844.844 0 0 1-.308 1.153 4.781 4.781 0 0 0-1.751 6.53.844.844 0 1 1-1.461.845 6.48 6.48 0 0 1 1.665-8.368l-4.95-8.578a1.97 1.97 0 0 0-3.41 1.969l3.656 6.328a.844.844 0 1 1-1.461.844L11.92 7.51a1.97 1.97 0 0 0-3.41 1.97l5.343 9.254a.844.844 0 1 1-1.46.843l-3.095-5.357a1.97 1.97 0 1 0-3.41 1.968l5.345 9.263a10.406 10.406 0 0 0 18.024-10.406l-.001.004Z"
    />
  </svg>
)
export default SvgComponent
