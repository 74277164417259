import React, {useEffect, useRef, useState} from 'react'
import './OtpInput.css'

export function OtpInput({onSubmit}: {onSubmit: (code: string) => void}) {

  const [otpValues, setOtpValues] = useState(['', '', '', '']);
  const otpFieldsRef = useRef([]);

  useEffect(() => {
    // @ts-ignore
    otpFieldsRef.current[0].focus();
  }, [])

  const handleInput = (index: number, value: string) => {
    if (value.length > 1) {
      return;
    }
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);
    if (value.length === 1 && index < otpValues.length - 1) {
      // @ts-ignore
      otpFieldsRef.current[index + 1].focus();
    }
    if (index === 3) {
      const optVal = newOtpValues.join('');
      onSubmit(optVal);
    }
  };

  const handleBackspace = (index: number) => {
    if (otpValues[index] !== '') {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = '';
      setOtpValues(newOtpValues);
    } else if (index > 0) {
      // @ts-ignore
      otpFieldsRef.current[index - 1].focus();
    }
  };

  return (
    <div className="code-confirm">
      {otpValues.map((value, index) => {
        return (
          <input
            ref={(ref) => {
              // @ts-ignore
              otpFieldsRef.current[index] = ref;
            }}
            onKeyDown={(e) => {
              if (e.key === 'Backspace') {
                handleBackspace(index);
              }
            }}
            onClick={() => {
              if (index > 0) {
                for (let i = 0; i < index; ++i) {
                 if (otpValues[i] === '') {
                   // @ts-ignore
                   otpFieldsRef.current[i].focus();
                   return
                 }
                }
              }
            }}
            onChange={(event) => handleInput(index, event.target.value)}
            type="text"
            className="code-confirm-input"
            maxLength={1}
          />
        )
      })}
    </div>
  )

}

export default OtpInput
