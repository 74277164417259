import {PROFILE_TYPE} from "../types/models/profile";
import {PROFILE_TYPE_COLORS, PROFILE_TYPE_RGBA_COLOR} from "../constants";

export const getProfileColor = (type: PROFILE_TYPE | null) => {

  if (type === null) {
    return 'rgba(244, 241, 255, 1)'
  }

  return PROFILE_TYPE_COLORS[type]

}

export const getProfileRgbaColor = (type: PROFILE_TYPE | null) => {

  if (type === null) {
    return 'rgba(244, 241, 255, 1)'
  }

  return PROFILE_TYPE_RGBA_COLOR[type]

}
