import React from 'react'
import './Agreement.css'
import {PRIVACY_POLICY_URL, TERMS_OF_USE_URL} from "../../../constants";

export function Agreement({selected, onChange}: {
  selected: boolean,
  onChange: (selected: boolean) => void
}) {

  return (
    <div className="u-agreement">
      <div className="u-agreement-square" onClick={() => {onChange(!selected)}}>
        {selected && (
          <img src="images/icons/checkbox/checked.png" alt="" className="u-agreement-square-checkmark"/>
        )}
      </div>
      <span className="u-agreement-text">
        By creating an account, I agree to our{` `}
        <a className="u-agreement-text-link" href={TERMS_OF_USE_URL} target={"_blank"}>Terms of use</a> and{` `}
        <a href={PRIVACY_POLICY_URL} className="u-agreement-text-link" target={"_blank"}>Privacy Policy</a>
      </span>
    </div>
  )

}
