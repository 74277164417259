import React, {useEffect, useState} from 'react'
import {getFullImageUrl} from "../../utils";
import ProfilePhotos from "../MyProfile/components/ProfilePhotos";
import ProfilePageInfo from "../MyProfile/components/ProfilePageInfo";
import {USER_PROFILE} from "../../types/models/profile";
import {useParams} from "react-router-dom";
import {apiProfileLoad} from "../../actions/profileActions";
import {useDispatch, useSelector} from "react-redux";
import ProfileInteraction from "../MyProfile/components/ProfileInteraction";
import Loader from "../../components/ui/Loader/Loader";
import {ProfileBlockArea} from "../MyProfile/components/ProfileBlockArea";
import PrivatePhotos from "./components/PrivatePhotos";
import ImageIcon from "../../components/ui/PhotosInput/icons/ImageIcon";
import {userVisit} from "../../reducers/user";

export function UserPage() {

  const token = useSelector(state => state.user.token)
  const dispatch = useDispatch()

  const [profile, setProfile] = useState<USER_PROFILE | null>(null)
  const [view, setView] = useState<0 | 1>(0)
  const {id} = useParams()

  useEffect(() => {
    loadUser()
    visitUser()
  }, [id])

  const visitUser = () => {

    dispatch(userVisit({
      profile_id: id
    }))

  }

  const loadUser = async () => {

    if (token && id) {
      const response = await apiProfileLoad(token, id)
      setProfile({
        ...response,
        values: response.types
      })
    }

  }

  return (
    <div className="page-with-menu-inner">
      <div className="profile-page">
        {profile === null ? (
          <div className="profile-page-loader">
            <Loader/>
          </div>
        ) : (
          <>
            <div className="profile-page-left">
              {profile.adult && profile.adult_status !== 3 ? (
                <div className="profile-page-main-photo-empty">
                  <span className="user-photo-area-image-label">On review</span>
                  <ImageIcon/>
                </div>
              ) : (
                <img src={getFullImageUrl(profile.avatar)} alt="" className="profile-page-main-photo-image"/>
              )}
              {profile.block === 0 && (
                <>
                  <ProfileInteraction
                    profile={profile}
                    withChat={true}
                    onChangeNote={note => {
                      setProfile({
                        ...profile,
                        note
                      })
                    }}
                    onChangeBlock={block => {
                      setProfile({
                        ...profile,
                        block
                      })
                    }}
                  />
                  {profile.publicPhotos.length > 0 && (
                    <ProfilePhotos photos={profile.publicPhotos} type={'public'} text={false}/>
                  )}
                  <PrivatePhotos
                    photos={profile.privatePhotos}
                    privatePhotosStatus={profile.privatePhotosStatus}
                    privatePhotosNum={profile.privatePhotosNum}
                    onChange={(privatePhotosStatus => {
                      setProfile({
                        ...profile,
                        privatePhotosStatus
                      })
                    })}
                    profileId={profile.id}
                    type={profile.type}
                    sharePhotosStatus={profile.sharePhotosStatus}
                    onChangeSharePhotosStatus={(sharePhotosStatus) => {
                      setProfile({
                        ...profile,
                        sharePhotosStatus,
                      })
                    }}
                  />
                </>
              )}
            </div>
            <div className="profile-page-right">
              <div className="profile-page-right-top">
                <span className="profile-page-name">{profile.name}</span>
                {profile.block === 1 && <span className="profile-page-block-label">This user has been blocked</span>}
              </div>
              {profile.block === 1 && (
                <ProfileBlockArea
                  profileId={profile.id}
                  block={profile.block}
                  onChangeBlock={(block) => {
                    setProfile({
                      ...profile,
                      block
                    })
                  }}
                  onChangeProfileView={(_view) => {setView(_view)}}
                  view={view}
                />
              )}
              {(profile.block === 0 || view === 1) && (
                <ProfilePageInfo profile={profile}/>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )

}
