import React, {useState} from 'react'
import {RadioSwitcher} from "../../components";
import {registerTypes} from "../SignUp/constants";
import SignInEmail from "./components/SignInEmail";
import SignUpPhone from "../SignUp/components/SignUpPhone";
import {NavLink} from "react-router-dom";
import CodeConfirm from "../SignUp/components/CodeConfirm";
import EmailConfirm from "../SignUp/components/EmailConfirm";
import LogoWithIcon from "../../icons/LogoWithIcon";

export function SignIn() {

  const [type, setType] = useState<null | string>('email')

  const [confirmId, setConfirmId] = useState<null | number>(null)
  const [phone, setPhone] = useState<null | string>(null)

  const [emailConfirm, setEmailConfirm] = useState<null | string>(null)
  const [token, setToken] = useState<null | string>(null)

  if (emailConfirm && token) {
    return (
      <EmailConfirm
        email={emailConfirm}
        token={token}
        onBack={() => {
          setEmailConfirm(null)
          setToken(null)
        }}/>
    )
  }

  if (confirmId !== null && phone) {
    return (
      <CodeConfirm
        phone={phone}
        confirmId={confirmId}
        onBack={() => {
          setConfirmId(null)
          setPhone(null)
        }}
      />
    )
  }

  return (
    <div className="promo-page">
      <div className="promo-page-container">
        <div className="promo-page-left">
          <div className="promo-page-form">
            <div className="sign-up-logo-with-icon">
              <LogoWithIcon/>
            </div>
            <h3 className="sign-up-title">Sign In</h3>
            <div className="sign-up-switcher">
              <RadioSwitcher
                elements={registerTypes}
                selected={type}
                onChange={(_type) => setType(_type)}
              />
            </div>
            {type === 'email' && (
              <SignInEmail
                onSetActivationEmail={(email, token) => {
                  setEmailConfirm(email)
                  setToken(token)
                }}
              />
            )}
            {type === 'phone' && (
              <SignUpPhone
                needAgreement={false}
                onChange={(confirmId, phone) => {
                  setConfirmId(confirmId)
                  setPhone(phone)
                }}
              />
            )}
            <span className="sign-up-sign-in-link">
            <NavLink className="sign-up-sign-in-link-href" to="/register">Create account</NavLink>
            </span>
          </div>
        </div>
        <div className="promo-page-right">
          <img className="promo-page-right-image" src={"/images/iphone.png"}/>
        </div>
      </div>
    </div>
  )

}
