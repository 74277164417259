import * as React from "react"

const MenuIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1880_9116)">
    <path d="M4 6H20" stroke="#433F4E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 12H20" stroke="#433F4E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 18H20" stroke="#433F4E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_1880_9116">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
  </svg>
)
export default MenuIcon
