import React from 'react'
import {PhotosInput} from "../../../components/ui/PhotosInput/PhotosInput";
import {ProgressLine} from "./ProgressLine";
import {useDispatch, useSelector} from "react-redux";
import {onBoardingUpdateAction} from "../../../reducers/onboarding";
import {PHOTO_TYPE} from "../../../types/models/photo";

export function OnBoardingPublicPhotos() {

  const photos = useSelector(state => state.onBoarding.publicPhotos)
  const profileType = useSelector(state => state.onBoarding.profileType)

  const dispatch = useDispatch()

  const onBack = () => {
    dispatch(onBoardingUpdateAction({
      values: {
        step: 'name'
      }
    }))
  }

  const submit = () => {
    dispatch(onBoardingUpdateAction({
      values: {
        step: 'privatePhotos'
      }
    }))
  }

  const changePhotos = (photos: PHOTO_TYPE[]) => {
    dispatch(onBoardingUpdateAction({
      values: {
        publicPhotos: [...photos]
      }
    }))
  }

  return (
    <>
      <div className="on-boarding-top">
        <span className="on-boarding-back-link" onClick={onBack}>
          <img src='/images/icons/common/angle-left.svg' className="on-boarding-back-link-icon"/>
          <span className="on-boarding-back-link-label">Back</span>
        </span>
      </div>
      <ProgressLine/>
      <span className="on-boarding-title">Upload additional public photos</span>
      {profileType === 'fun' ? (
        <span className="on-boarding-text">You can upload up to 4 additional public photos or skip this step and add them on your profile. Go for it.</span>
      ) : (
        <span className="on-boarding-text">Upload up to 4 additional public photos. Remember, these are not adult photos, save those for your private photo album (next).</span>
      )}
      <span className="on-boarding-text">We accept JPGs and PNGs</span>
      {profileType && (
        <PhotosInput
          photos={photos}
          onChange={photos => {changePhotos(photos)}}
          maxLength={4}
          profileType={profileType}
          photoType={'public'}
        />
      )}
      <button
        className="on-boarding-submit-button"
        onClick={submit}
      >
        Continue
      </button>
      <span className="on-boarding-skip-link" onClick={submit}>Skip</span>
    </>
  )
}
