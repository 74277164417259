import React from 'react'
import {ProfileType} from "./ProfileType";
import {ProgressLine} from "./ProgressLine";
import {PROFILE_TYPES_LIST} from "../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {onBoardingUpdateAction} from "../../../reducers/onboarding";
import {PROFILE_TYPE} from "../../../types/models/profile";
import {userSignOutAction} from "../../../reducers/user";
import {USER_TYPE} from "../../../types/models/user";
import {useNavigate} from "react-router-dom";

const getNextStep = (user: USER_TYPE | null) => {

  if (user === null || user.sex == null) {
    return 'sex'
  }
  if (user.userLocation.location === null) {
    return 'location'
  }
  return 'name'
}

export function OnBoardingType() {

  const type = useSelector(state => state.onBoarding.profileType)
  const profiles = useSelector(state => state.user.profiles)
  const types = profiles.map(profile => profile.type)
  const user = useSelector(state => state.user.user)

  const dispatch = useDispatch()

  const logout = () => {
    dispatch(userSignOutAction())
  }

  const submit = () => {
    dispatch(onBoardingUpdateAction({
      values: {
        step: getNextStep(user)
      }
    }))
  }

  const changeType = (type: PROFILE_TYPE) => {
    dispatch(onBoardingUpdateAction({
      values: {
        profileType: type
      }
    }))
  }

  return (
    <>
      <div className="on-boarding-top">
        <div className="on-boarding-logout-link" onClick={logout}>
          <img src='/images/icons/menu/logout.svg' className="on-boarding-logout-link-icon"/>
          <span className="on-boarding-logout-link-label">Log Out</span>
        </div>
      </div>
      <ProgressLine/>
      <span className="on-boarding-title">Choose a Profile to get started!</span>
      <span className="on-boarding-text">To see and interact with others, you must have at least one profile. You don’t have to have all three, but most people do.</span>
      <span className="on-boarding-text">Select a Profile type</span>
      <div className="on-boarding-profile-types">
        {PROFILE_TYPES_LIST.filter(_type => types.indexOf(_type) === -1).map((_type) => {
          return (
            <ProfileType type={_type} checked={_type === type} onClick={() => changeType(_type)}/>
          )
        })}
      </div>
      <button
        disabled={type === null}
        className="on-boarding-submit-button"
        onClick={submit}
      >
        Continue
      </button>
    </>
  )
}
