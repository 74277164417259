import { FC } from "react"
import './BasicButton.scss';

type BasicButtonT = {
  title: string,
  variant?: 'filled' | 'outlined'
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const BasicButton: FC<BasicButtonT> = ({ title, variant, ...other }) => {
  return (
    <button
      {...other}
      className={`basic-button btn-${variant || 'filled'} ${other.className || ''}`}
    >
      {title}
    </button>
  )
}

export default BasicButton;