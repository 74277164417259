import React, {useEffect, useState} from 'react'
import './SmallProfile.css'
import LocationIcon from "../../icons/users/LocationIcon";
import {UserIcon} from "../../pages/Users/components/UserIcon";
import {useSelector} from "react-redux";
import {PROFILE} from "../../types/models/profile";
import {apiProfileLoad} from "../../actions/profileActions";
import {countDistance, getFullImageUrl, getTemplate} from "../../utils";
import Loader from "../ui/Loader/Loader";

export default function SmallProfile({profileId}: {profileId: number}) {

  const token = useSelector(state => state.user.token)
  const templates = useSelector(state => state.application.templates)

  const [profile, setProfile] = useState<PROFILE | null>(null)

  useEffect(() => {
    loadUser()
  }, [profileId])

  const loadUser = async () => {

    setProfile(null)
    if (token && profileId) {
      const response = await apiProfileLoad(token, profileId.toString())
      setProfile({
        ...response,
        values: response.types
      })
    }

  }

  if (profile === null) {
    return <Loader/>
  }

  const singleTemplate = profile ? getTemplate(templates, profile.type, 'single') : []

  return (
    <div className="small-profile">
      <div className="small-profile-image-area">
        <img src={getFullImageUrl(profile.avatar)} className="small-profile-image"/>
      </div>
      <div className="small-profile-info">
        <span className="small-profile-name profile-text-color">{profile.name}</span>
        {profile.distance && (
          <div className="small-profile-location-icon">
            <UserIcon icon={<LocationIcon/>} label={countDistance(profile.distance)}/>
          </div>
        )}
        {singleTemplate.map((block: any) => {

          const values = profile.values.filter(value => value.type_id === block.id)

          if (values.length > 0) {

            return (
              <div className="small-profile-area-element">
                {block.label.length > 0 && <span className="small-profile-area-element-title">{block.label}</span>}
                {block.type === 'large_text' && (
                  <span className="small-profile-area-element-text">{values[0].value}</span>
                )}
                {['tips', 'value'].indexOf(block.type) !== -1 && (
                  <div className="small-profile-area-element-tips">
                    {values.map(value => {
                      return (
                        <span className="small-profile-area-element-tip">{value.value}</span>
                      )
                    })}
                  </div>
                )}
              </div>
            )

          }

        })}
      </div>
    </div>
  )

}
