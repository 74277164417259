import React, {useState} from 'react'
import {Switcher} from "../../../components/ui/Switcher/Switcher";
import {useDispatch, useSelector} from "react-redux";
import {ExploreNewArea} from "./ExploreNewArea";
import {SafeForWork} from "./SafeForWork";
import {userUpdateAction} from "../../../reducers/user";
import {sendSuccessNotification} from "../../../utils";

export function Visibility() {

  const dispatch = useDispatch()

  const userLocation = useSelector(state => state.user.user?.userLocation)

  const initExplore = useSelector(state => state.user.user?.explore)
  const initSafeForWork = useSelector(state => state.user.user?.safeForWork)
  const initStealthMode = useSelector(state => state.user.user?.stealthMode)

  const [stealthMode, setStealthMode] = useState<0 | 1>(initStealthMode ? initStealthMode : 0)
  const [safeForWork, setSafeForWork] = useState(initSafeForWork ? initSafeForWork : null)
  const [explore, setExplore] = useState(initExplore ? initExplore : null)

  const changeStealthMode = (checked: boolean) => {
    setStealthMode(checked ? 1 : 0)
  }

  const cancelChanges = () => {
    setStealthMode(initStealthMode ? initStealthMode : 0)
    setSafeForWork(initSafeForWork ? initSafeForWork : null)
    setExplore(initExplore ? initExplore : null)
  }

  const save = () => {
    sendSuccessNotification(dispatch)
    dispatch(userUpdateAction({
      values: {
        stealthMode,
        explore,
        safeForWork
      }
    }))
  }

  return (
    <div className="visibility">
      <div className="visibility-element">
        <Switcher
          label={'Stealth Mode'}
          checked={stealthMode === 1}
          onChange={changeStealthMode}
          info={'In stealth mode, you will appear to be offline but may see nearby users yourself.'}
        />
      </div>
      {safeForWork ? (
        <SafeForWork userLocation={userLocation} safeForWork={safeForWork} onChange={(_safeForWork => setSafeForWork(_safeForWork))}/>
      ) : <div/>}
      {explore ? (
        <ExploreNewArea explore={explore} onChange={(_explore => setExplore(_explore))}/>
      ) : <div/> }
      <div className="visibility-bottom">
        <span className="visibility-bottom-link profile-text-color" onClick={cancelChanges}>Cancel Changes</span>
        <button className="visibility-bottom-button profile-button" onClick={save}>Save</button>
      </div>
    </div>
  )

}
