import {useDispatch, useSelector} from "react-redux";
import {applicationSetLightBoxAction} from "../../reducers/application";
import {useEffect, useState} from "react";
import FsLightbox from "fslightbox-react";


export const Lightbox = () => {

  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const photo = useSelector(state => state.application.lightBoxPhoto)

  useEffect(() => {
    setOpen(photo !== null)
  }, [photo])

  const onClose = () => {
    dispatch(applicationSetLightBoxAction({
      lightBoxPhoto: null
    }))
  }

  if (photo !== null) {
    return (
      <FsLightbox
        onClose={onClose}
        toggler={open}
        sources={[photo]}
        types={['image']}
      />
    )
  }

  return <div/>

}
