import React from 'react'
import {PROFILE_TYPE, PROFILE_VALUE} from "../../types/models/profile";
import {useSelector} from "react-redux";
import {getTemplate} from "../../utils";
import {Tab} from "../ui";
import {EditProfileBlock} from "../../pages/EditProfile/components/EditProfileBlock";
import {onChangeProfileValueType} from "../../pages";

type ProfileTabsProps = {
  type: PROFILE_TYPE,
  values: PROFILE_VALUE[],
  onChangeValues: (values: PROFILE_VALUE[]) => void
  onBoarding?: boolean
}

export const ProfileTabs = ({type, values, onChangeValues, onBoarding = false}: ProfileTabsProps) => {

  const templates = useSelector(state => state.application.templates)
  const editTemplate = getTemplate(templates, type, 'edit')

  const onChangeProfileValue: onChangeProfileValueType = (id, value, multiply) => {

    if (multiply) {

      const index = values.findIndex(_value => _value.type_id === id && _value.value === value)
      if (index === -1) {
        values.push({
          type_id: id,
          value
        })
      } else {
        values.splice(index, 1)
      }

    } else {

      const index = values.findIndex(_value => _value.type_id === id)
      if (index === -1) {
        values.push({
          type_id: id,
          value
        })
      } else {
        values[index].value = value
      }

    }

    onChangeValues([...values])

  }

  return (

   <>
     {editTemplate.map((template: any, index: number) => {
       return (
         <Tab
           label={template.name}
           initOpen={onBoarding && index === 0}
           content={
             <>
               {template.elements.map((element: any) => {

                 if (onBoarding && element.type === 'date') {
                   return <div/>
                 }

                 return (
                   <div className="edit-profile-tab-content-child">
                     <span className="edit-profile-tab-content-child-label">{element.label}</span>
                     {element.subLabel ? (
                       <span className="edit-profile-tab-content-child-sub-label">{element.subLabel}</span>
                     ) : null}
                     <EditProfileBlock
                       onChange={onChangeProfileValue}
                       values={values}
                       block={element}
                     />
                   </div>
                 )

               })}
             </>
           }
         />
       )
     })}
   </>

  )

}
