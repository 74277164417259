import {PROFILE, PROFILE_TYPE} from "../types/models/profile";

export const getProfile = (profiles: PROFILE[], type: PROFILE_TYPE | null) => {

  if (type === null) {
    return null
  }

  const profile = profiles.find(_profile => _profile.type === type)
  if (profile) {
    return profile
  }

  return null

}
