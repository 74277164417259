import React, {useState} from 'react'
import './FiltersModal.scss'
import {Modal} from "../ui/Modal/Modal";
import {useSelector} from "react-redux";
import {getTemplate} from "../../utils";
import {onChangeProfileValueType} from "../../pages";
import {SearchBlock} from "./components/SearchBlock";
import {Switcher} from "../ui/Switcher/Switcher";

export type SearchValue = {
  type_id: number,
  value: string
}

type FiltersModalProps = {
  open: boolean,
  onClose: () => void,
  values: SearchValue[],
  onChangeValues: (values: SearchValue[], onlyFavorites: boolean) => void
  onlyFavorites: boolean
}

export const FiltersModal = ({open, onClose, values: initValues, onChangeValues, onlyFavorites: initOnlyFavorites}: FiltersModalProps) => {

  const activeType = useSelector(state => state.user.activeType)
  const templates = useSelector(state => state.application.templates)
  const searchTemplate = getTemplate(templates, activeType, 'search')

  const [values, setValues] = useState<SearchValue[]>(initValues)
  const [onlyFavorites, setOnlyFavorites] = useState<boolean>(initOnlyFavorites)

  const onChangeSearchValue: onChangeProfileValueType = (id, value) => {

    const index = values.findIndex(_value => _value.type_id === id && _value.value === value)
    if (index === -1) {
      values.push({
        type_id: id,
        value
      })
    } else {
      values.splice(index, 1)
    }
    setValues([...values])

  }

  const clear = () => {
    setValues([])
    setOnlyFavorites(false)
  }

  const onSubmit = () => {
    onChangeValues(values, onlyFavorites)
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      content={(
        <div className="filters-modal">
          <span className="filters-modal-title">Filters</span>
          <div className="filters-elements">
            {
              searchTemplate.map((block: SearchBlock) => {
                if (block.type === 'tips' || block.type === 'list') {
                  return (
                    <div className="filters-element">
                       <span className="filters-element-label">{block.label}</span>
                       <div className="filters-element-content">
                         <SearchBlock
                           block={block}
                           values={values}
                           onChange={onChangeSearchValue}
                         />
                       </div>
                    </div>
                  )
                }
                return <div/>
              })
            }
            <div className="filters-element filters-switcher">
              <Switcher
                label={'Show only my favorites'}
                checked={onlyFavorites}
                onChange={(checked) => {setOnlyFavorites(checked)}}
              />
            </div>
          </div>
          <div className="modal-bottom">
            <span className="filters-clear-button profile-text-color" onClick={clear}>Clear</span>
            <button className="modal-submit-button profile-background-color" onClick={onSubmit}>Search</button>
          </div>
        </div>
      )}
    />
  )

}
