import { useDispatch, useSelector } from "react-redux";
import { InfoIcon } from "../../../components";
import LocationSelect from "../../../components/ui/LocationSelect/LocationSelect";
import { onBoardingUpdateAction } from "../../../reducers/onboarding";
import { userUpdateAction } from "../../../reducers/user";
import { USER_LOCATION_TYPE } from "../../../types/models/user";
import { ProgressLine } from "./ProgressLine";

export function OnBoardingLocation() {

  const dispatch = useDispatch()

  const location = useSelector(state => state.onBoarding.location)
  const sex = useSelector(state => state.onBoarding.sex)

  const onBack = () => {
    dispatch(onBoardingUpdateAction({
      values: {
        step: 'sex'
      }
    }))
  }

  const changeLocation = (_location: USER_LOCATION_TYPE) => {
    dispatch(onBoardingUpdateAction({
      values: {
        location: {
          ..._location
        }
      }
    }))
  }

  const submit = () => {
    dispatch(userUpdateAction({
      values: {
        userLocation: location,
        sex
      }
    }))
    dispatch(onBoardingUpdateAction({
      values: {
        step: 'name'
      }
    }))
  }

  return (
    <>
      <div className="on-boarding-top">
        <span className="on-boarding-back-link" onClick={onBack}>
          <img
            src='/images/icons/common/angle-left.svg'
            className="on-boarding-back-link-icon"
            alt=""
          />
          <span className="on-boarding-back-link-label">Back</span>
        </span>
      </div>
      <ProgressLine/>
      <span className="on-boarding-title">We need your location to show who is nearby</span>
      <span className="on-boarding-text">In order to use Hey.Hey.Hey we need you to confirm your location. Keep in mind you can always search for others in another town using the “Search” function.</span>
      <LocationSelect
        location={location}
        onChange={(_location) => {
          changeLocation(_location)
        }}
      />
      <button
        disabled={location.location === null}
        onClick={submit}
        className='on-boarding-submit-button'
      >
        Continue
      </button>
      <div className="on-boarding-info-link">
        <span className="on-boarding-info-link-label">I don’t want you to know.</span>
        <InfoIcon
          content={(
            <span>You cannot use the App if you do not give us permission to know your general location.</span>
          )}
        />
      </div>
    </>
  )
}
