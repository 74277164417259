import CalendarIcon from "./icons/CalendarIcon";
import InputMask from "react-input-mask";
import React, {useState} from "react";
import './DateField.scss'
import Calendar from "react-calendar";
import moment from "moment";
import 'react-calendar/dist/Calendar.css';

// const MAX_DATE = moment().subtract(18, 'years').toDate();

export const DateField = ({value, onChange}: {value: string, onChange: (value: string) => void}) => {

  // const [calendarOpen, setCalendarOpen] = useState(false)

  return (
    <div className="date-field">
      <InputMask
        id={'phoneInput'}
        mask={'99/99/9999'}
        maskChar="_"
        maskPlaceholder={'MM/DD/YYYY'}
        className="date-field-input"
        value={value}
        onChange={(event) => {
          onChange(event.target.value)
        }}
        placeholder={'MM/DD/YYYY'}
      />
      {/* <div className="date-field-icon" onClick={() => {setCalendarOpen(!calendarOpen)}}>
        <CalendarIcon/>
      </div>
      {calendarOpen && (
        <div className="date-field-submenu">
          <Calendar
            onChange={date => {            
              onChange(moment(date?.toString() || null).format('MM/DD/YYYY'))
              setCalendarOpen(false)
            }}
            locale={'en'}
            value={moment().toDate()}
            // maxDate={MAX_DATE}
            prev2Label={null}
            next2Label={null}
          />
        </div>
      )} */}
    </div>
  )

}
