import React, {useState} from 'react'
import SendIcon from "../../../icons/chat/SendIcon";
import {useDispatch, useSelector} from "react-redux";
import {chatSendMessageAction} from "../../../reducers/chat";
import {getCurrentTimestamp} from "../../../utils/getCurrentTimestamp";
import {getProfile} from "../../../utils";
import {apiChatSendTextMessage} from "../../../actions/chatActions";

export default function ChatSendArea() {

  const chatId = useSelector(state => state.chat.activeChatId)
  const profiles = useSelector(state => state.user.profiles)
  const activeType = useSelector(state => state.user.activeType)
  const token = useSelector(state => state.user.token)
  const profile = getProfile(profiles, activeType)

  const [text, setText] = useState<string>('')

  const dispatch = useDispatch()

  const sendMessage = () => {

    if (text.trim().length > 0 && profile && token && activeType && chatId) {
      apiChatSendTextMessage(token, activeType, chatId, text)
      dispatch(chatSendMessageAction({
        message: {
          timestamp: getCurrentTimestamp(),
          text,
          profile_id: profile.id,
          chatId
        },
      }))
      setText('')
    }

  }

  return (
    <form className="chat-window-send-area">
      <input
        className="chat-window-send-area-text-input"
        type='text'
        placeholder='Message'
        value={text}
        onChange={e => {
          setText(e.target.value)
        }}
      />
      <button
        className="chat-window-send-area-button profile-text-color"
        onClick={sendMessage}
        disabled={text.trim().length === 0}
      >
        <SendIcon/>
      </button>
    </form>
  )

}
