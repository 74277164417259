import React from "react";
import {onChangeProfileValueType} from "../EditProfile";
import {PROFILE_VALUE} from "../../../types/models/profile";

export function EditProfileLargeText({
  placeholder,
  values,
  blockId,
  onChange
} : {
  placeholder: string,
  values: PROFILE_VALUE[],
  blockId: number,
  onChange: onChangeProfileValueType
}) {

  const value = values.find(_value => _value.type_id === blockId)
  const strValue = value ? value.value : ''

  return (
    <div className="edit-profile-tab-textarea">
       <textarea
         className="edit-profile-tab-textarea-field"
         placeholder={placeholder}
         value={strValue}
         onChange={(event) => {
           onChange(blockId, event.target.value)
         }}
         maxLength={400}
       />
      <div className="edit-profile-tab-textarea-bottom">
        <span className="edit-profile-tab-textarea-limit">{strValue.length}/400</span>
      </div>
    </div>
  )


}
