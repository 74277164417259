export const ABOUT_URL = 'https://hello.heyheyheyapp.com'
export const BLOG_URL = 'https://hello.heyheyheyapp.com/blog'
export const CONTACT_US_URL = 'https://hello.heyheyheyapp.com/contact-us'
export const TERMS_OF_USE_URL = 'https://hello.heyheyheyapp.com/terms-of-use'
export const PRIVACY_POLICY_URL = 'https://hello.heyheyheyapp.com/privacy-policy'

export const PLAY_MARKET_URL = 'https://play.google.com/store/apps/details?id=com.heyheyhey'
export const APPLE_STORE_URL = 'https://apps.apple.com/us/app/heyheyhey/id1547445036'

export const API_URL = 'https://api.muslapp.com/api';
export const S3_FULL_URL = 'https://d2hf2zc57m4y81.cloudfront.net'
