import React, {useEffect, useState} from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ProfileSwitcher } from "../ProfileSwitcher/ProfileSwitcher";
import { AsideMenu } from "../AsideMenu/AsideMenu";
import "./Auth.scss";
import { apiApplicationGetData } from "../../actions/applicationActions";
import { applicationSetTemplatesAction } from "../../reducers/application";
import { MessagesBlock } from "../MessagesBlock/MessagesBlock";
import { apiChatsGet } from "../../actions/chatActions";
import {chatSetChatsAction, chatSetIsLoadingAction} from "../../reducers/chat";
import NavigationDrawer from "../NavigationDrawer/NavigationDrawer";
import ProfileSwitcherPopUp from "../ProfileSwitcher/ProfileSwitcherPopUp/ProfileSwitcherPopUp";
import useBreakpoints from "../../hooks/useBreakpoints";
import Pusher, {Channel} from "pusher-js";
import {getProfile} from "../../utils";
import {PROFILE_TYPE} from "../../types/models/profile";
import {apiUserGetData} from "../../actions/userActions";
import {userSetVisiblesAction} from "../../reducers/user";

type AuthProps = {
  withSidebar: boolean;
  sidebarType?: "default" | "messages";
  profileType?: PROFILE_TYPE
};

export default function Auth({
  withSidebar,
  sidebarType = "default",
  profileType
}: AuthProps) {
  const token = useSelector((state) => state.user.token);
  const activeType = useSelector((state) => state.user.activeType);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const profiles = useSelector((state) => state.user.profiles);
  const { lgUp } = useBreakpoints();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [channel, setChannel] = useState<null | Channel>(null)


  useEffect(() => {
    if (token !== null) {
      loadApplicationData();
      loadUserData();
    }
  }, [token]);

  useEffect(() => {

    if (activeType !== null) {
      loadChats();
      subscribeToChatChanges()
    }

  }, [activeType]);


  const subscribeToChatChanges = () => {

    if (channel) {
      channel.cancelSubscription();
      setChannel(null)
    }

    const pusher = new Pusher('a99bc80f5d8843dddf67', {
      cluster: 'mt1'
    });

    const profile = getProfile(profiles, activeType)
    const _channel = pusher.subscribe(`profile_channel_${profile?.id}`);
    setChannel(_channel)
    _channel.bind('chat_event', function() {
      loadChats()
    });

  }

  const loadUserData = async () => {

    if (token) {
      const response = await apiUserGetData(token)
      dispatch(userSetVisiblesAction({
        visibles: response.visibles
      }))
    }

  }

  const loadChats = async () => {
    if (token && activeType) {
      dispatch(chatSetIsLoadingAction({
        isLoading: true
      }))
      const chats = await apiChatsGet(token, activeType);
      dispatch(
        chatSetChatsAction({
          chats,
        })
      );
    }
  };

  const loadApplicationData = async () => {
    if (token) {
      const response = await apiApplicationGetData(token);
      dispatch(
        applicationSetTemplatesAction({
          templates: response.templates,
        })
      );
    }
  };

  if (!isLoggedIn && pathname !== '/login') {
    return <Navigate to='/login'/>;
  }

  if (profiles.length === 0 && pathname !== '/on-boarding' && pathname !== '/reset_password') {
    return <Navigate to="/on-boarding"/>;
  }

  if (withSidebar && lgUp) {
    return (
      <div className={`page-with-menu ${profileType ? profileType : activeType}`}>
        <div className="page-with-menu-container container">
          {lgUp && (
            <div className={`page-with-menu-aside page-with-menu-aside-${sidebarType}`}>
              {sidebarType === "default" && (
                <>
                  <ProfileSwitcher />
                  <AsideMenu />
                </>
              )}
              {sidebarType === "messages" && (
                <>
                  <ProfileSwitcher />
                  <MessagesBlock />
                </>
              )}
            </div>
          )}
          <div className={`page-with-menu-content page-with-menu-content-${sidebarType}`}>
            <Outlet />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`page-without-menu ${profileType ? profileType : activeType}`}>
      <NavigationDrawer />
      <ProfileSwitcherPopUp />
      <Outlet />
    </div>
  );
}
