import React, {useRef, useState} from 'react'
import {getFullImageUrl, getRandomString} from "../../../utils";
import {CropModal} from "../../../components/ui/PhotosInput/components/CropModal";
import {applicationSetLightBoxAction} from "../../../reducers/application";
import {useDispatch} from "react-redux";
import {PHOTO_TYPE} from "../../../types/models/photo";
import {PROFILE_TYPE} from "../../../types/models/profile";
import {PhotoLabel} from "../../../components/ui/PhotosInput/PhotosInput";

export const EditProfilePhoto = ({
  avatar,
  onChange,
  profileType
}: {
  avatar: PHOTO_TYPE
  onChange: (avatar: PHOTO_TYPE) => void
  profileType: PROFILE_TYPE
}) => {

  const inputFile = useRef<any>(null)
  const [inputFileKey, setInputFileKey] = useState<string>(getRandomString())
  const [photo, setPhoto] = useState<string | null>(null)

  const dispatch = useDispatch()

  const openFileUpload = () => {
    inputFile.current.click();
  }

  const onFileChange = async (event: any) => {

    if (event.target.files.length > 0) {
      setInputFileKey(getRandomString())
      setPhoto(URL.createObjectURL(event.target.files[0]))
    }

  }

  const openLightBox = () => {
    if (avatar) {
      dispatch(applicationSetLightBoxAction({
        lightBoxPhoto: getFullImageUrl(avatar.path)
      }))
    }
  }

  return (
    <div className="edit-profile-page-avatar">
      <CropModal
        photo={photo}
        onCancel={() => {
          setPhoto(null)
        }}
        onSubmit={_photo => {
          onChange(_photo)
          setPhoto(null)
        }}
        isPrivate={false}
        allowAdult={profileType === 'fun'}
      />
      <input
        accept={"image/jpeg, image/png"}
        type="file"
        key={inputFileKey}
        ref={inputFile}
        style={{display: "none"}}
        onChange={onFileChange}
      />
      <PhotoLabel adult={avatar.adult} adultStatus={avatar.status}/>
      <div className="photos-input-element-fade">
        <div className="photos-input-element-fade-icon" onClick={openLightBox}>
          <img src="/images/icons/photo/eye.svg" alt=""/>
        </div>
        <div className="photos-input-element-fade-icon" onClick={openFileUpload}>
          <img src="/images/icons/photo/trash.svg" alt=""/>
        </div>
      </div>
      <img src={getFullImageUrl(avatar.path)} alt="" className="edit-profile-page-avatar-img"/>
    </div>
  )

}
