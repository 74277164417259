import * as React from "react"
const NoteIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <g
      stroke="#433F4E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipPath="url(#a)"
    >
      <path d="m17.332 26.667 9.333-9.333M17.332 26.667v-8a1.333 1.333 0 0 1 1.333-1.333h8V8.001A2.667 2.667 0 0 0 24 5.334h-16a2.667 2.667 0 0 0-2.667 2.667v16a2.667 2.667 0 0 0 2.667 2.666h9.333Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default NoteIcon
