import React, {ReactElement} from "react";
import './InfoIcon.css'
import InfoIconImage from "./icons/InfoIconImage";

export function InfoIcon({content}: {content: ReactElement}) {

  return (
    <div className="info">
      <div className="info-icon">
        <InfoIconImage/>
      </div>
      <div className="info-text">
        {content}
      </div>
    </div>
  )

}
