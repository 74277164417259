import { ActionProps } from "./store";
import { getRandomString } from "../utils";

const APPLICATION_SET_TEMPLATES = "APPLICATION_SET_TEMPLATES";
const APPLICATION_SET_LIGHTBOX_PHOTO = "APPLICATION_SET_LIGHTBOX_PHOTO";
const APPLICATION_PUSH_NOTIFICATION = "APPLICATION_PUSH_NOTIFICATION";
const APPLICATION_POP_NOTIFICATION = "APPLICATION_POP_NOTIFICATION";
const APPLICATION_TOGGLE_NAV_DRAWER = "APPLICATION_TOGGLE_NAV_DRAWER";
const APPLICATION_TOGGLE_PROFILE_SWITCHER_POP_UP =
  "APPLICATION_TOGGLE_PROFILE_SWITCHER_POP_UP";
const DISABLE_EDIT_PROFILE_SAVE = "DISABLE_EDIT_PROFILE_SAVE";

export type SMALL_NOTIFICATION_TYPE = {
  id: number;
  title: string;
  message?: string;
  type: "error" | "success";
};

const defaultState = {
  templates: {
    friend: {
      single: [],
      edit: [],
      search: [],
    },
    flirt: {
      single: [],
      edit: [],
      search: [],
    },
    fun: {
      single: [],
      edit: [],
      search: [],
    },
  },
  lightBoxPhoto: null,
  notifications: [],
  navigationDrawer: {
    isOpened: false,
  },
  profileSwitcherPopUp: {
    isOpened: false,
  },
  editProfile: {
    saveDisabled: false,
  }
};

export const applicationReducer = (
  state = defaultState,
  action: ActionProps
) => {
  switch (action.type) {
    case APPLICATION_SET_TEMPLATES:
      return {
        ...state,
        templates: action.payload.templates,
      };
    case APPLICATION_SET_LIGHTBOX_PHOTO:
      return {
        ...state,
        lightBoxPhoto: action.payload.lightBoxPhoto,
      };
    case APPLICATION_PUSH_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            id: getRandomString(),
            ...action.payload,
          },
        ],
      };
    case APPLICATION_POP_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications.filter(
            (notification: SMALL_NOTIFICATION_TYPE) =>
              notification.id !== action.payload.id
          ),
        ],
      };
    case APPLICATION_TOGGLE_NAV_DRAWER:
      return {
        ...state,
        navigationDrawer: {
          isOpened: !state.navigationDrawer.isOpened,
        },
      };
    case APPLICATION_TOGGLE_PROFILE_SWITCHER_POP_UP:
      return {
        ...state,
        profileSwitcherPopUp: {
          isOpened: !state.profileSwitcherPopUp.isOpened,
        },
      };
    case DISABLE_EDIT_PROFILE_SAVE:
      return {
        ...state,
        editProfile: {
          ...state.editProfile,
          saveDisabled: action.payload,
        }
      };
    default:
      return state;
  }
};

export const applicationSetTemplatesAction = (payload: any) => ({
  type: APPLICATION_SET_TEMPLATES,
  payload,
});
export const applicationSetLightBoxAction = (payload: any) => ({
  type: APPLICATION_SET_LIGHTBOX_PHOTO,
  payload,
});
export const applicationPushNotificationAction = (payload: any) => ({
  type: APPLICATION_PUSH_NOTIFICATION,
  payload,
});
export const applicationPopNotificationAction = (payload: any) => ({
  type: APPLICATION_POP_NOTIFICATION,
  payload,
});
export const applicationToggleNavDrawer = () => ({
  type: APPLICATION_TOGGLE_NAV_DRAWER,
});
export const applicationToggleProfileSwitcherPopUp = () => ({
  type: APPLICATION_TOGGLE_PROFILE_SWITCHER_POP_UP,
});
export const applicationSetDisableEditProfileSaveBtn = (payload: boolean) => ({
  type: DISABLE_EDIT_PROFILE_SAVE,
  payload
});
