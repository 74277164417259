import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGE_TYPE } from "../../../types/models/chat";
import { getProfile } from "../../../utils";
import { apiChatGetMessages } from "../../../actions/chatActions";
import Loader from "../../../components/ui/Loader/Loader";
import { chatSetMessagesAction } from "../../../reducers/chat";
import Pusher from 'pusher-js';

// @ts-ignore
import timeago from 'epoch-timeago';

const ChatMessage = ({type, message}: {type: 1 | 2, message: MESSAGE_TYPE}) => {

  return (
    <div className={`chat-message chat-message-${type}`}>
      <div className={`chat-message-inner ${type === 2 ? 'profile-background-color' : ''}`}>
        <span className="chat-message-text">{message.text}</span>
        <div className="chat-message-bottom">
          <span className="chat-message-time">{timeago(message.timestamp * 1000)}</span>
        </div>
      </div>
    </div>
  )

}

export const ChatPageWindow = () => {

  const dispatch = useDispatch()

  const messagesEndRef = useRef(null);

  const token = useSelector(state => state.user.token)
  const messages = useSelector(state => state.chat.messages)
  const activeChatId = useSelector(state => state.chat.activeChatId)
  const profiles = useSelector(state => state.user.profiles)
  const activeType = useSelector(state => state.user.activeType)
  const profile = getProfile(profiles, activeType)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  useEffect(() => {
    if (profile) {

      const pusher = new Pusher('a99bc80f5d8843dddf67', {
        cluster: 'mt1'
      });

      const channel = pusher.subscribe(`profile_channel_${profile.id}`);
      channel.bind('chat_event', function(chatId: string) {
        if (parseInt(chatId) === activeChatId) {
          loadMessages(false)
        }
      });
    }
  }, [])

  useEffect(() => {
    loadMessages()
  }, [activeChatId])

  const loadMessages = async (loading = true) => {

    setLoading(loading)
    if (activeChatId && token) {
      const response = await apiChatGetMessages(token, activeChatId)
      dispatch(chatSetMessagesAction({
        messages: response
      }))
    } else {
      dispatch(chatSetMessagesAction({
        messages: []
      }))
    }
    setLoading(false)

  }

  const scrollToBottom = () => {
    setTimeout(() => {
      // @ts-ignore
      messagesEndRef.current?.scrollIntoView()
    }, 1)
  }

  if (profile === null) {
    return <div/>
  }

  return (
    <div className="chat-window">
      {loading ? (
        <Loader/>
      ) : (
        <div className="chat-window-wrapper">
          {messages.map(message => {
            return (
              <ChatMessage
                type={profile.id === message.profile_id ? 2 : 1}
                message={message}
              />
            )
          })}
          <div style={{height: '24px'}}/>
          <div ref={messagesEndRef} />
        </div>
      )}
    </div>
  )
}
