import * as React from "react"

const PrivateIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={66}
    height={65}
    fill="none"
    {...props}
  >
    <path
      stroke="#6E6A7A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M26.936 38.91a8.46 8.46 0 0 1-2.505-6.037A8.556 8.556 0 0 1 33 24.3c2.348 0 4.51.95 6.04 2.505M41.41 34.393a8.552 8.552 0 0 1-6.87 6.884"
    />
    <path
      stroke="#6E6A7A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M18.524 47.322c-4.298-3.375-7.938-8.304-10.576-14.45 2.665-6.172 6.33-11.128 10.654-14.53 4.299-3.401 9.258-5.248 14.398-5.248 5.17 0 10.126 1.874 14.452 5.3M53.174 24.35c1.863 2.475 3.502 5.332 4.88 8.52-5.327 12.342-14.741 19.776-25.052 19.776-2.337 0-4.642-.379-6.858-1.118M54.361 11.51 11.64 54.23"
    />
  </svg>
)
export default PrivateIcon
