
const TrashBinIcon = () => {
  return (
    <svg width="50" height="55" viewBox="0 0 50 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M43.8363 20.6436C43.8363 20.6436 42.3657 38.8842 41.5126 46.5677C41.1063 50.2375 38.8394 52.3879 35.1263 52.4556C28.0603 52.5829 20.9861 52.5911 13.9228 52.4421C10.3505 52.369 8.12151 50.1915 7.72339 46.5867C6.86484 38.8354 5.40234 20.6436 5.40234 20.6436" stroke="#433F4E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M47.5851 11.8991H1.65723" stroke="#433F4E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M38.7358 11.8996C36.6098 11.8996 34.7789 10.3964 34.3618 8.31374L33.7037 5.0204C33.2975 3.50103 31.9216 2.4502 30.3535 2.4502H18.8891C17.321 2.4502 15.9452 3.50103 15.5389 5.0204L14.8808 8.31374C14.4637 10.3964 12.6329 11.8996 10.5068 11.8996" stroke="#433F4E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default TrashBinIcon