import {applicationPushNotificationAction} from "../reducers/application";
import {Dispatch} from "react";

export const sendSmallNotification = (dispatch: Dispatch<any>, {title, message, type}: {
  title: string,
  message?: string
  type: 'error' | 'success'
}) => {
  dispatch(applicationPushNotificationAction({
    title,
    message,
    type
  }))
}

export const sendSuccessNotification = (dispatch: Dispatch<any>) => {
  sendSmallNotification(dispatch, {
    title: 'All changes successfully saved!',
    type: 'success'
  })
}
