import * as React from "react"
const VisibilityIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipPath="url(#a)"
    >
      <path d="M6.5 10.5a5.5 5.5 0 1 0 11 0 5.5 5.5 0 0 0-11 0ZM4.5 28c-.5-.5-.5-2.667-.5-2.667A5.333 5.333 0 0 1 9.333 20h5.334A5.333 5.333 0 0 1 20 25.333S20 27.5 19.5 28M20.332 5.174a5.333 5.333 0 0 1 0 10.333M23.124 20.234c1.525 0 2.603.993 2.876 1.266.273.273 1.124 1 1.5 2.5 0 0 1 3.346-.376 4.034-1.572.786-2.671.966-4.624.966" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default VisibilityIcon
