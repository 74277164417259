import React, {useState} from "react";
import {onChangeProfileValueType} from "../EditProfile";
import {EditProfileTips} from "./EditProfileTips";
import {EditProfileLargeText} from "./EditProfileLargeText";
import {PROFILE_VALUE} from "../../../types/models/profile";
import {EditProfileDate} from "./EditProfileDate";
import {EditProfileDetails} from "./EditProfileDetails";

interface EditProfileBlockI {
  values: PROFILE_VALUE[],
  block: any,
  onChange: onChangeProfileValueType,
}

export function EditProfileBlock({values, block, onChange}: EditProfileBlockI) {

  if (block.type === 'tips') {
    return (
      <EditProfileTips
        blockId={block.id}
        tips={block.elements}
        values={values}
        type={'multi'}
        onChange={onChange}
      />
    )
  }

  if (block.type === 'lists') {
    return (
      <EditProfileTips
        blockId={block.id}
        tips={block.elements}
        values={values}
        type={'single'}
        onChange={onChange}
      />
    )
  }

  if (block.type === 'date') {
    return (
      <div className="edit-profile-tab-date">
        <EditProfileDate
          blockId={block.id}
          values={values}
          onChange={onChange}
        />
      </div>
    )
  }

  if (block.type === 'details') {
    return (
      <div className="edit-profile-tab-date">
        <EditProfileDetails
          weightId={block.weight_id}
          heightId={block.height_id}
          values={values}
          onChange={onChange}
          onError={() => {}}
        />
      </div>
    )
  }

  if (block.type === 'large_text') {
    return (
      <EditProfileLargeText
        blockId={block.id}
        placeholder={block.placeholder}
        values={values}
        onChange={onChange}
      />
    )
  }

  return null;

}
