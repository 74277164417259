import {Button, TextInput} from "../../../components";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {sendSmallNotification, validateEmail, validatePassword} from "../../../utils";
import {apiUserLogin, createUserFromResponse} from "../../../actions/userActions";
import {userSignInAction} from "../../../reducers/user";
import {useDispatch} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";

export default function SignInEmail({onSetActivationEmail}: {onSetActivationEmail: (email: string, token: string) => void}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [submitLoading, setSubmitLoading] = useState(false)
  const [passwordRef, setPasswordRef] = useState<any>(null);

  const submitDisabled = useMemo(() => (
    !validatePassword(password) || !validateEmail(email) || password.length <= 6 || submitLoading
  ), [password, email, submitLoading])

  const submit = async () => {

    setSubmitLoading(true)
    const response = await apiUserLogin(email, password);
    if (!response.status) {
      setSubmitLoading(false)
      if (response.error === 'activation') {
        onSetActivationEmail(email, response.token)
        return
      }
      sendSmallNotification(dispatch,{
        title: 'Invalid email or password',
        message: 'Try to type it once again',
        type: 'error'
      })
      return
    }

    dispatch(userSignInAction({
      token: response.token,
      user: createUserFromResponse(response.user),
      profiles: response.profiles,
    }));
    navigate('/profile')

  }

  const onEmailInputKeyDown = (key: string) => {
    if (key === 'Enter' && passwordRef?.current) {
      passwordRef.current.focus();
    }
  }

  const onPasswordInputKeyDown = (key: string) => {
    if (key === 'Enter' && !submitDisabled) {
      submit()
    }
  }

  return (
    <>
      <div className="sign-up-field">
        <TextInput
          id={'email'}
          label={'Email'}
          type={'email'}
          value={email}
          onChange={setEmail}
          placeholder={'Enter your email'}
          onKeyDown={onEmailInputKeyDown}
        />
      </div>
      <div className="sign-up-field">
        <TextInput
          id={'password'}
          label={'Password'}
          type={'password'}
          value={password}
          onChange={setPassword}
          placeholder={'Enter your password'}
          onRef={setPasswordRef}
          onKeyDown={onPasswordInputKeyDown}
        />
      </div>
      <div className="sign-up-forgot-password">
        <NavLink to="/restore" className="sign-up-forgot-password-link">Forgot password?</NavLink>
      </div>
      <div className="sign-up-button">
        <Button
          label={'Log In'}
          onClick={submit}
          disabled={submitDisabled}
          loading={submitLoading}
        />
      </div>
    </>
  )

}
