import React, {useState} from "react";
import {onChangeProfileValueType} from "../EditProfile";
import {PROFILE_VALUE} from "../../../types/models/profile";

export function EditProfileTips({
  tips,
  values,
  blockId,
  type,
  onChange
}: {
  tips: string[],
  values: PROFILE_VALUE[],
  blockId: number,
  type: 'single' | 'multi',
  onChange: onChangeProfileValueType
}) {

  const strValues: string[] = values.filter(value => value.type_id === blockId).map(value => value.value)

  const checkActive = (tip: string) => {
    return strValues.indexOf(tip) !== -1
  }

  return (
    <div className="edit-profile-tab-tips">
      {tips.map(tip => {
        return (
          <span
            className={`edit-profile-tab-tips-element${checkActive(tip) ? ' active' : ''}`}
            onClick={() => {
              onChange(blockId, tip, type === 'multi')
            }}
          >
            {tip}
          </span>
        )
      })}
    </div>
  )


}
