import * as React from "react"
const LocationIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="none"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#a)"
    >
      <path d="M6.5 7.333a2 2 0 1 0 4 0 2 2 0 0 0-4 0Z" />
      <path d="m12.273 11.104-2.829 2.83a1.333 1.333 0 0 1-1.885 0l-2.829-2.83a5.334 5.334 0 1 1 7.543 0Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.5 0h16v16H.5z" />
      </clipPath>
    </defs>
  </svg>
)
export default LocationIcon
