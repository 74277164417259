import React from "react";

type StatsElementProps = {
  label: string,
  blocks: {
    icon: React.ReactElement,
    value: number,
    text: string
  }[]
}

export const StatsElement = ({label, blocks}: StatsElementProps) => {

  return (
    <div className="stats-element">
      <span className="stats-element-label">{label}</span>
      {blocks.map(block => {
        return (
          <div className="stats-element-content">
            <div className="stats-element-icon profile-text-color">
              {block.icon}
            </div>
            <div className="stats-element-right">
              <span className="stats-element-value">{block.value}</span>
              <span className="stats-element-text">{block.text}</span>
            </div>
          </div>
        )
      })}
    </div>
  )

}
