import React from 'react'
import './SearchInput.css'

export function SearchInput({value, onChange}: {value: string, onChange: (value: string) => void}) {

  return (
    <div className="search-area">
      <img src="images/icons/common/search-default.svg" alt="" className="search-area-icon"/>
      <input
        type="text"
        className="search-area-input"
        placeholder={'Search by username or #hashtags'}
        value={value}
        onChange={(event) => {
          onChange(event.target.value)
        }}
      />
    </div>
  )

}
