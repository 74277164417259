export const getTemplate = (templates: any, profileType: any, templateType: 'single' | 'edit' | 'search') => {

  if (templates) {

    if (templates[profileType]) {

      if (templates[profileType][templateType]) {
        return templates[profileType][templateType]
      }

    }

  }

  return null

}
