import React from "react";
import "./Button.scss";

export function Button({
  label,
  onClick,
  disabled,
  loading
}: {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
}) {
  return (
    <button
      disabled={disabled}
      className="u-button"
      onClick={onClick}
    >
      {loading && <img
        className="u-button-loader-icon"
        src="/images/icons/common/loader.svg"
        alt="Loading icon"
      />}

      {label}
    </button>
  );
}
