import axios from "axios";
import {API_URL} from "../constants";

export const apiNotificationsGet = (
  token: string,
  type: string,
  offset: number
): any => {

  return new Promise((resolve, reject) => {

    axios.get(`${API_URL}/v3/web/notifications.get?type=${type}&offset=${offset}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.notifications);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiNotificationsUpdateMessagesVisible = (
  token: string,
  profile_id: string
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/v2/notifications.update_messages_visible`, {
      profile_id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}
