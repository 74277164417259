import {ActionProps} from "./store";
import {CHAT_TYPE, MESSAGE_TYPE} from "../types/models/chat";

const SET_CHATS = 'SET_CHATS';
const SET_ACTIVE_CHAT = 'SET_ACTIVE_CHAT';
const CREATE_CHAT = 'CREATE_CHAT'
const SEND_MESSAGE = 'SEND_MESSAGE'
const SET_MESSAGES = 'SET_MESSAGES'
const SET_IS_LOADING = 'SET_IS_LOADING'

export type CHAT_STATE_TYPE = {
  chats: CHAT_TYPE[],
  activeChatId: null | number,
  messages: MESSAGE_TYPE[],
  isLoading: boolean
}

const defaultState: CHAT_STATE_TYPE = {
  chats: [],
  activeChatId: null,
  messages: [],
  isLoading: false
}

export const chatReducer = (state = defaultState, action: ActionProps) => {
  switch (action.type) {
    case SET_CHATS:
      return {
        ...state,
        chats: [...action.payload.chats],
        isLoading: false
      }
    case SET_ACTIVE_CHAT:
      return {
        ...state,
        activeChatId: action.payload.activeChatId,
        messages: []
      }
    case CREATE_CHAT:
      return {
        ...state,
        activeChatId: action.payload.chat.id,
        chats: [
          action.payload.chat,
          ...state.chats
        ],
        messages: []
      }
    case SET_MESSAGES:
      return {
        ...state,
        messages: [...action.payload.messages.sort((a: MESSAGE_TYPE, b: MESSAGE_TYPE) => {
          return a.timestamp - b.timestamp
        })]
      }
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      }
    case SEND_MESSAGE:
      return {
        ...state,
        messages: [
          ...state.messages,
          action.payload.message
        ],
        chats: [...state.chats.map(chat => {
          if (chat.id === action.payload.message.chatId) {
            return {
              ...chat,
              lastMessage: action.payload.message
            }
          }
          return chat
        })]
      }
    default:
      return state;
  }
};

export const chatSetChatsAction = (payload: any) => ({type: SET_CHATS, payload});
export const chatSetActiveAction = (payload: any) => ({type: SET_ACTIVE_CHAT, payload});
export const chatCreateAction = (payload: any) => ({type: CREATE_CHAT, payload});
export const chatSendMessageAction = (payload: any) => ({type: SEND_MESSAGE, payload});
export const chatSetMessagesAction = (payload: any) => ({type: SET_MESSAGES, payload});
export const chatSetIsLoadingAction = (payload: any) => ({type: SET_IS_LOADING, payload});
