import React, {useState} from 'react'
import LocationSelect from "../../../components/ui/LocationSelect/LocationSelect";
import {useDispatch, useSelector} from "react-redux";
import {USER_LOCATION_TYPE} from "../../../types/models/user";
import {userUpdateAction} from "../../../reducers/user";
import {sendSuccessNotification} from "../../../utils";

export function MyLocation() {

  const dispatch = useDispatch()

  const initLocation = useSelector(state => state.user.user?.userLocation)
  const [location, setLocation] = useState<USER_LOCATION_TYPE | null>(initLocation ? initLocation : null)

  const cancelChanges = () => {
    setLocation(initLocation ? initLocation : null)
  }

  const save = () => {
    sendSuccessNotification(dispatch)
    dispatch(userUpdateAction({
      values: {
        userLocation: location
      }
    }))
  }

  return (
    <div className="visibility">
      <div className="visibility-location-select">
        {location ? (
          <LocationSelect
            location={location}
            onChange={(_location) => {
              setLocation(_location)
            }}
          />
        ) : <div/>}
      </div>
      <div className="visibility-bottom">
        <span className="visibility-bottom-link profile-text-color" onClick={cancelChanges}>Cancel Changes</span>
        <button className="visibility-bottom-button profile-button" onClick={save}>Save</button>
      </div>
    </div>
  )

}
