import React, {useState} from 'react'
import {NavLink} from "react-router-dom";
import {NOTIFICATION_TYPE} from "../../../types/models/notification";
import {getFullImageUrl} from "../../../utils";
import {apiProfileDeclinePhotoAccess, apiProfileSharePhotoAccess} from "../../../actions/profileActions";
import {useSelector} from "react-redux";

const typeToTextMapped: Record<string, string> = {
  view: 'looked at your profile',
  favourite: 'favorited you',
  flex: 'sent you a hey',
  message: 'sent you a message',
  photo_request: 'want to see your private photos',
}

export function Notification({notification}: {notification: NOTIFICATION_TYPE}) {

  const profile = notification.profile;
  const [shareCompleted, setShareCompleted] = useState(!(notification.share?.status === 2))
  const token = useSelector(state => state.user.token)

  const approve = () => {

    if (token) {
      apiProfileSharePhotoAccess(token, notification.profile.id)
      setShareCompleted(true)
    }

  }

  const decline = () => {

    if (token) {
      apiProfileDeclinePhotoAccess(token, notification.profile.id)
      setShareCompleted(true)
    }

  }

  return (
    <div className="notification">
      <img
        src={getFullImageUrl(profile.avatar)}
        alt=""
        className="notification-profile-image"
      />
      <div className="notification-content">
        <NavLink to={`/peoples/${profile.id}`} className="notification-profile-name profile-text-color">{profile.name}</NavLink>
        <span className="notification-text">{typeToTextMapped[notification.type]}</span>
      </div>
      {notification.type === 'photo_request' && notification.share !== null && !shareCompleted && (
        <div className="notification-buttons">
          <button className="notification-button-decline profile-text-color" onClick={decline}>Decline</button>
          <button className="notification-button-allow profile-border-color profile-background-color" onClick={approve}>Allow</button>
        </div>
      )}
    </div>
  )

}
