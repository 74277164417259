import React, {useRef, useState} from 'react'
import './PhotosInput.scss'
import {getFullImageUrl, getRandomString} from "../../../utils";
import "cropperjs/dist/cropper.css";
import {CropModal} from "./components/CropModal";
import PlusIcon from "../../../icons/PlusIcon";
import {useDispatch} from "react-redux";
import {applicationSetLightBoxAction} from "../../../reducers/application";
import ImageIcon from "./icons/ImageIcon";
import {PHOTO_TYPE} from "../../../types/models/photo";
import {PROFILE_TYPE} from "../../../types/models/profile";

type PhotosInputProps = {
  photos: PHOTO_TYPE[],
  onChange: (photos: PHOTO_TYPE[]) => void,
  maxLength: number
  photoType: 'public' | 'private'
  profileType: PROFILE_TYPE
}

export function PhotosInput({photos, onChange, maxLength, photoType, profileType}: PhotosInputProps) {

  const inputFile = useRef<any>(null)
  const [photo, setPhoto] = useState<string | null>(null)
  const [inputFileKey, setInputFileKey] = useState<string>(getRandomString())

  const openFileUpload = () => {
    inputFile.current.click();
  }

  const onFileChange = async (event: any) => {

    if (event.target.files.length > 0) {
      setInputFileKey(getRandomString())
      setPhoto(URL.createObjectURL(event.target.files[0]))
    }

  }

  const onDelete = (index: number) => {
    photos.splice(index, 1)
    onChange([...photos])
  }

  return (
    <>
      <CropModal
        photo={photo}
        onCancel={() => {
          setPhoto(null)
        }}
        onSubmit={_photo => {
          onChange([
            ...photos,
            _photo
          ])
          setPhoto(null)
        }}
        isPrivate={photoType === 'private'}
        allowAdult={profileType === 'fun'}
      />
      {maxLength > 1 ? (
        <div className='photos-input'>
          <Photo photo={photos[0]} onUpload={openFileUpload} onDelete={() => onDelete(0)}/>
          <Photo photo={photos[1]} onUpload={openFileUpload} onDelete={() => onDelete(1)}/>
          <Photo photo={photos[2]} onUpload={openFileUpload} onDelete={() => onDelete(2)}/>
          <Photo photo={photos[3]} onUpload={openFileUpload} onDelete={() => onDelete(3)}/>
        </div>
      ) : (
        <>
          {photos.length > 0 ? (
            <div className="photos-input-single-photo">
              <Photo photo={photos[0]} onUpload={openFileUpload} onDelete={() => onDelete(0)}/>
            </div>
          ) : (
            <div className='photos-input-placeholder' onClick={openFileUpload}>
              <div className="photos-input-placeholder-icon">
                <ImageIcon/>
              </div>
              <span className="photos-input-placeholder-text">Click to upload photo</span>
              <span className="photos-input-placeholder-sub-text">We accept JPGs and PNGs</span>
            </div>
          )}
        </>
      )}
      <input
        accept={"image/jpeg, image/png"}
        type="file"
        key={inputFileKey}
        ref={inputFile}
        style={{display: "none"}}
        onChange={onFileChange}
      />
    </>
  )

}

export const PhotoLabel = ({adult, adultStatus}: {adult?: 0 | 1, adultStatus?: 0 | 1 | 2 | 3}) => {

  if (adult) {

    if (adultStatus === 1) {
      return <span className="photos-input-element-label on-review">On review</span>
    }

    if (adultStatus === 3) {
      return <span className="photos-input-element-label approved">Approved</span>
    }

  }

  return <div/>

}

export const Photo = ({photo, onUpload, onDelete}: {photo: PHOTO_TYPE | undefined, onUpload: () => void, onDelete: () => void}) => {

  const dispatch = useDispatch()

  const openLightBox = () => {
    if (photo) {
      dispatch(applicationSetLightBoxAction({
        lightBoxPhoto: getFullImageUrl(photo.path)
      }))
    }
  }

  if (photo) {

    return (
      <div className="photos-input-element">
        <PhotoLabel adult={photo.adult} adultStatus={photo.status}/>
        <div className="photos-input-element-fade">
          <div className="photos-input-element-fade-icon" onClick={openLightBox}>
            <img src="/images/icons/photo/eye.svg" alt=""/>
          </div>
          <div className="photos-input-element-fade-icon" onClick={onDelete}>
            <img src="/images/icons/photo/trash.svg" alt=""/>
          </div>
        </div>
        <div className="photos-input-element-photo" style={{backgroundImage: `url(${getFullImageUrl(photo.path)})`}}/>
      </div>
    )
  }

  return (
    <div className="photos-input-element" onClick={onUpload}>
      <PlusIcon/>
    </div>
  )

}
