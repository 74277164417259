import React from "react";
import {onChangeProfileValueType} from "../EditProfile";
import {PROFILE_VALUE} from "../../../types/models/profile";
import {DateField} from "../../../components/ui/DateField/DateField";

export function EditProfileDate({
  values,
  blockId,
  onChange
} : {
  values: PROFILE_VALUE[],
  blockId: number,
  onChange: onChangeProfileValueType
}) {

  const value = values.find(_value => _value.type_id === blockId)
  const strValue = value ? value.value : ''

  return (
    <DateField
      value={strValue}
      onChange={date => {
        onChange(blockId, date)
      }}
    />
  )


}
