import * as React from "react"

const EyeIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.107 8.035a2.108 2.108 0 1 1-4.215-.001 2.108 2.108 0 0 1 4.215 0Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.999 12.903c2.538 0 4.86-1.825 6.168-4.868-1.308-3.043-3.63-4.868-6.168-4.868H8c-2.538 0-4.86 1.825-6.168 4.868 1.308 3.043 3.63 4.868 6.168 4.868H8Z"
      clipRule="evenodd"
    />
  </svg>
)
export default EyeIcon
