import axios from "axios";
import Cookies from "js-cookie";
import {API_URL} from "../constants";
import {USER_TYPE} from "../types/models/user";
import {USER_RESPONSE} from "../types/responses/user";

export const createUserFromResponse = (user: USER_RESPONSE): USER_TYPE => {

  return {
    id: user.id,
    email: user.email,
    sex: user.sex,
    userLocation: {
      location: user.location,
      lat: user.lat,
      lng: user.lng
    },
    stealthMode: user.stealth_mode || 0,
    safeForWork: {
      enabled: user.safe_for_work || 0,
      lat: user.safe_for_work_lat ? user.safe_for_work_lat : user.lat,
      lng: user.safe_for_work_lng ? user.safe_for_work_lng : user.lng,
      begin: user.safe_for_work_begin || "07:00",
      end: user.safe_for_work_end || "22:00"
    },
    explore: {
      enabled: user.explore || 0,
      lat: user.explore_lat ? user.explore_lat : user.lat,
      lng: user.explore_lng ? user.explore_lng : user.lng
    }
  }

}

export const userSession = () => {

  return new Promise<null | {user: object, token: string, profiles: any[]}>(async (resolve, reject) => {

    const token = Cookies.get('token');

    if (token !== undefined) {

      try {

        const apiResponse = await apiUserSession(token);
        resolve({
          token: apiResponse.token,
          user: createUserFromResponse(apiResponse.user),
          profiles: apiResponse.profiles,
        });

      } catch (error) {
        resolve(null);
      }

    }

    resolve(null);

  })

}

export const apiUserSession = (token: string): any => {

  return new Promise((resolve, reject) => {

    axios.get(`${API_URL}/v2/user.session?hey=1`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserResendEmail = (token: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(`${API_URL}/user.resend`, {
      hey: 1
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserConfirmCreate = (phone: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(`${API_URL}/hey/user.create_confirm`, {
      phone
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserLogin = (email: string, password: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(`${API_URL}/v2/user.login`, {
      email,
      password,
      hey: 1,
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserSendResetPasswordEmail = (email: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(`${API_URL}/hey/user.send_reset_email`, {
      email,
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}


export const apiUserRegister = (email: string, password: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(`${API_URL}/v2/user.register`, {
      email,
      password,
      hey: 1,
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserConfirmCheck = (id: number, code: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(`${API_URL}/v2/hey/user.confirm_check`, {
      id,
      code,
      fcm: 'webapp'
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserGetData = (token: string): any => {

  return new Promise((resolve, reject) => {

    axios.get(`${API_URL}/v3/web/user.get_data`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserGetBadges = (token: string): any => {

  return new Promise((resolve, reject) => {

    axios.get(`${API_URL}/v2/user.get_badges`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.badges);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserChangePassword = (token: string, password: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(`${API_URL}/v3/web/user.change_password`, {
      password
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserGetShareInfo = (token: string, target_user_id: string): any => {

  return new Promise((resolve, reject) => {

    axios.get(`${API_URL}/v2/user.get_share_info?target_user_id=${target_user_id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.info);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserSendSupport = (
  token: string,
  app_crash: number,
  complaint: number,
  restore_the_access: number,
  text: string
  ): any => {

  return new Promise((resolve, reject) => {

    let data = new FormData();
    data.append('app_crash', app_crash.toString());
    data.append('complaint', complaint.toString());
    data.append('', restore_the_access.toString());
    data.append('text', text);

    axios.post(`${API_URL}/user.send_support`, data, {
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8;',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const convertToUserValues = (user: USER_TYPE) => {

  return {
    stealth_mode: user.stealthMode,
    explore: user.explore.enabled,
    explore_lat: user.explore.lat,
    explore_lng: user.explore.lng,
    safe_for_work: user.safeForWork.enabled,
    safe_for_work_begin: user.safeForWork.begin,
    safe_for_work_end: user.safeForWork.end,
    safe_for_work_lat: user.safeForWork.lat,
    safe_for_work_lng: user.safeForWork.lng,
    location: user.userLocation.location,
    lat: user.userLocation.lat,
    lng: user.userLocation.lng,
    sex: user.sex
  }

}

export const apiUserUpdate = (
  token: string,
  id: number,
  user: USER_TYPE
): any => {

  return new Promise((resolve, reject) => {

    axios.post(`${API_URL}/v3/web/user.update`, {
      id,
      ...convertToUserValues(user)
    }, {
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8;',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserCheckActivationCode = (activationCode: string): any => {

  return new Promise((resolve, reject) => {

    axios.get(`${API_URL}/v3/web/hey/user.check_activation_code?activation_code=${activationCode}`).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserCheckResetCode = (resetCode: string): any => {

  return new Promise((resolve, reject) => {

    axios.get(`${API_URL}/v3/web/hey/user.check_reset_code?reset_code=${resetCode}`).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}



