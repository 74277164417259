import axios, {CancelToken} from "axios";
import { API_URL } from "../constants";
import {PROFILE_TYPE, PROFILE_VALUE} from "../types/models/profile";
import {PHOTO_TYPE} from "../types/models/photo";
import {SearchValue} from "../components/FiltersModal/FiltersModal";

export const apiProfilesSearch = (
  cancelToken: CancelToken,
  token: string,
  type: string,
  limit: number,
  offset: number,
  search = '',
  values = '',
  onlyFavorite = false
): any => {

  return new Promise((resolve, reject) => {

    axios.get(`${API_URL}/v3/web/profiles.search?type=${type}&limit=${limit}&offset=${offset}&search=${search}&values=${values}&onlyFavorite=${+onlyFavorite}`, {
      cancelToken,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.profiles);
    }).catch(function (error) {
      if (!axios.isCancel(error)) {
        reject(error);
      }
    })

  })

}

export const apiProfilesFavorites = (
  token: string,
  type: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.get(`${API_URL}/v3/web/profiles_get_favorites?type=${type}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.profiles);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfilesBlocked = (
  token: string,
  type: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.get(`${API_URL}/v3/web/profiles_get_blocked?type=${type}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.profiles);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileLoad = (
  token: string,
  id: string
): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/v3/web/profile.get?profile_id=${id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.profile);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileGetElements = (
  token: string,
  type: string
): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/profile.get_elements_redesign?type=${type}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.elements);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileGetData = (
  token: string,
  profile_id: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/v2/profile.get_data?profile_id=${profile_id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileGet = (
  token: string,
  profile_id: number,
): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/v3/web/profile.get?profile_id=${profile_id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileUploadAvatar = (token: string, file: any): any => {

  return new Promise((resolve, reject) => {

    let data = new FormData();
    data.append("file", file);

    axios.post(API_URL + '/profile.upload_avatar', data, {
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8;',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfilePhotoCreate = (token: string, profile_id: string, file: any, type: 'private' | 'public'): any => {

  return new Promise((resolve, reject) => {

    let data = new FormData();
    data.append("file", file);
    data.append("profile_id", profile_id)
    data.append('type', type)

    axios.post(API_URL + '/v2/photo.create', data, {
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8;',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfilePublicPhotoCreate = (token: string, profile_id: string, file: any): any => {

  return new Promise((resolve, reject) => {

    let data = new FormData();
    data.append("file", file);
    data.append("profile_id", profile_id)

    axios.post(API_URL + '/v3/photo.create_public', data, {
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8;',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfilePhotoDelete = (token: string, profile_id: string, photo_id: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/photo.delete', {
      id: photo_id,
      profile_id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileCreate = (
  token: string,
  {
    name,
    avatar,
    adult,
    publicPhotos,
    privatePhotos,
    type,
    values,
  }: {
    name: string,
    publicPhotos: PHOTO_TYPE[],
    privatePhotos: PHOTO_TYPE[],
    avatar: string
    adult: 0 | 1
    type: PROFILE_TYPE,
    values: PROFILE_VALUE[]
  }
): any => {

  return new Promise((resolve, reject) => {

    axios.post(`${API_URL}/v3/web/profile.create`, {
      name,
      type,
      avatar: avatar,
      avatar_adult: adult,
      publicPhotos: JSON.stringify(publicPhotos.map(photo => JSON.stringify(photo))),
      privatePhotos: JSON.stringify(privatePhotos.map(photo => JSON.stringify(photo))),
      values: JSON.stringify(values.map(value => JSON.stringify(value)))
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.profile);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileUpdate = (
  token: string,
  id: number,
  {
    name,
    privatePhotos,
    publicPhotos,
    type,
    avatar,
    adult_status,
    values
  }: {
    name: string,
    privatePhotos: PHOTO_TYPE[],
    publicPhotos: PHOTO_TYPE[],
    type: PROFILE_TYPE,
    avatar: string,
    adult_status: 0 | 1 | 2 | 3
    values: PROFILE_VALUE[]
  }
): any => {

  return new Promise((resolve, reject) => {

    axios.post(`${API_URL}/v3/web/profile.update`, {
      id,
      name,
      type,
      avatar: avatar,
      avatar_adult: adult_status,
      publicPhotos: JSON.stringify(publicPhotos.map(photo => JSON.stringify(photo))),
      privatePhotos: JSON.stringify(privatePhotos.map(photo => JSON.stringify(photo))),
      values: JSON.stringify(values.map(value => JSON.stringify(value)))
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileDelete = (
  token: string,
  type: string
): any => {

  return new Promise((resolve, reject) => {

    axios.post(`${API_URL}/v3/web/profile.delete`, {
      type
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileEdit = (
  token: string,
  profile_id: string,
  name: string,
  image: string,
  random: 0 | 1,
  values: {
    type_id: string,
    value: string
  }[]
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/v2/profile.edit`, {
      name,
      profile_id,
      image,
      random,
      values: JSON.stringify(values.map(value => JSON.stringify(value)))
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileFlex = (
  token: string,
  profile_id: number
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/profile.flex`, {
      'target_profile_id': profile_id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileFavourite = (
  token: string,
  profile_id: number,
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/profile.favourite`, {
      'target_profile_id': profile_id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileBlock = (
  token: string,
  profile_id: number
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/profile.block`, {
      'target_profile_id': profile_id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileGetStatistics = (
  token: string,
  type: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/v2/profile.get_statistics?type=${type}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}


export const apiProfileGetPrivatePhotos = (
  token: string,
  profile_id: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/v2/profile.get_private_photos?profile_id=${profile_id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileRequestPhotoAccess = (
  token: string,
  profile_id: number,
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/profile.request_photo_access`, {
      target_profile_id: profile_id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileSharePhotoAccess = (
  token: string,
  profile_id: number
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/profile.share_photo_access`, {
      target_profile_id: profile_id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileRemoveSharePhotoAccess = (
  token: string,
  profile_id: number
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/profile.remove_share_photo_access`, {
      target_profile_id: profile_id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileDeclinePhotoAccess = (
  token: string,
  profile_id: number
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/profile.decline_photo_access`, {
      target_profile_id: profile_id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileGetNote = (
  token: string,
  target_profile_id: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/v2/profile.get_note?target_profile_id=${target_profile_id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.note);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileChangeNote = (
  token: string,
  target_profile_id: number,
  text: string
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/v2/profile.change_note`, {
      target_profile_id,
      text
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileChangeActiveType = (
  token: string,
  type: PROFILE_TYPE
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/profile.change_active`, {
      type
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}


export const apiProfileGetSearchSettings = (
  token: string,
  type: PROFILE_TYPE
): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/v3/web/search_settings.get?type=${type}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.values);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileUpdateSearchSettings = (
  token: string,
  type: PROFILE_TYPE,
  values: SearchValue[]
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/v3/web/search_settings.update`, {
      type,
      values: JSON.stringify(values.map(value => JSON.stringify(value)))
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.values);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileVisit = (
  token: string,
  profile_id: number
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/v3/web/profile.visit`, {
      profile_id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}


