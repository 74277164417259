import React from 'react'
import {InfoIcon} from "../InfoIcon/InfoIcon";
import './Switcher.css'

export type SwitcherProps = {
  label: string
  checked: boolean
  onChange: (checked: boolean) => void
  info?: string
}

export function Switcher({label, checked, onChange, info}: SwitcherProps) {

  return (
    <div className="switcher">
      <span className="switcher-label">{label}</span>
      {info && (
        <InfoIcon
          content={<span>{info}</span>}
        />
      )}
      <div className={`switcher-inner ${checked ? 'checked' : ''}`} onClick={() => {onChange(!checked)}}>
        <div className="switcher-inner-circle"/>
      </div>
    </div>
  )

}
