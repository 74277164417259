import React, {ReactElement, useEffect} from 'react'
import CloseIcon from "./icons/CloseIcon";
import './Modal.scss'

type ModalProps = {
  open: boolean,
  content: ReactElement,
  onClose: () => void,
}

export const Modal = ({open, content, onClose}: ModalProps) => {

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open])

  if (open) {
    return (
      <div className="modal-area">
        <div className="modal-fade" onClick={onClose}/>
        <div className="modal">
          <div className="modal-close" onClick={onClose}>
            <CloseIcon/>
          </div>
          {content}
        </div>
      </div>
    )
  }

  return <div/>

}
