import { SearchInput } from "../../../components";
import { FiltersModal, SearchValue } from "../../../components/FiltersModal/FiltersModal";
import useModal from "../../../hooks/useModal";
import FilterIcon from "../../../icons/FilterIcon";

type UsersTopProps = {
  search: string,
  values: SearchValue[],
  onlyFavorite: boolean,
  onChangeSearch: (search: string) => void,
  onChangeValues: (values: SearchValue[]) => void
  onChangeOnlyFavorite: (onlyFavorite: boolean) => void
}

export default function UsersTop({search, values, onChangeSearch, onChangeValues, onChangeOnlyFavorite, onlyFavorite}: UsersTopProps) {

  const [filtersModalOpen, openFiltersModal, closeFiltersModal] = useModal()

  return (
    <div className="users-top">
      <FiltersModal
        values={values}
        onChangeValues={(_values, _onlyFavorite) => {
          onChangeValues(_values)
          onChangeOnlyFavorite(_onlyFavorite)
        }}
        open={filtersModalOpen}
        onClose={closeFiltersModal}
        onlyFavorites={onlyFavorite}
      />
      <div className="users-top-filter-icon" onClick={openFiltersModal}>
        <div className="users-top-filter-icon-image profile-text-color">
          <FilterIcon/>
        </div>
        <span className="users-top-filter-icon-label profile-text-color">Filters</span>
        {(+onlyFavorite || values.length > 0) && (
          <div className="users-top-filter-icon-circle profile-background-color">
            <span className="users-top-filter-icon-circle-num">{+onlyFavorite + values.length}</span>
          </div>
        )}
      </div>
      <div className="users-top-search">
        <SearchInput
          value={search}
          onChange={_search => {onChangeSearch(_search)}}
        />
      </div>
    </div>
  )

}
