import React from 'react'
import {ProgressLine} from "./ProgressLine";
import {useDispatch, useSelector} from "react-redux";
import {onBoardingUpdateAction} from "../../../reducers/onboarding";
import {PROFILE_TYPE_LABELS} from "../../../constants";

export function OnBoardingSexSelect() {

  const dispatch = useDispatch()
  const type = useSelector(state => state.onBoarding.profileType)

  const onBack = () => {
    dispatch(onBoardingUpdateAction({
      values: {
        step: 'profileType'
      }
    }))
  }

  const selectSex = (sex: 'male' | 'female') => {
    dispatch(onBoardingUpdateAction({
      values: {
        sex,
        step: 'location'
      }
    }))
  }

  return (
    <>
      <div className="on-boarding-top">
        <span className="on-boarding-back-link" onClick={onBack}>
          <img src='/images/icons/common/angle-left.svg' className="on-boarding-back-link-icon"/>
          <span className="on-boarding-back-link-label">Back</span>
        </span>
      </div>
      <ProgressLine/>
      <span className="on-boarding-title">How do you identify?</span>
      {type && <span className="on-boarding-text">Let’s create your <span className="uppercase">{PROFILE_TYPE_LABELS[type]}</span> profile.</span>}
      <span className="on-boarding-text">Please answer the following questions.</span>
      <div className="on-boarding-buttons">
        <button className="on-boarding-button" onClick={() => {selectSex('male')}}>Male</button>
        <button className="on-boarding-button" onClick={() => {selectSex('female')}}>Female</button>
      </div>
    </>
  )
}
