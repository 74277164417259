import {PROFILE_TYPE_BIRTHDATE} from "../constants";
import {ON_BOARDING_STATE_TYPE} from "../reducers/onboarding";
import moment from "moment";

export const convertToValues = (onBoarding: ON_BOARDING_STATE_TYPE) => {

  let values = onBoarding.values

  if (onBoarding.profileType !== null && onBoarding.birthdate !== null) {
    values.push({
      type_id: PROFILE_TYPE_BIRTHDATE[onBoarding.profileType],
      value: moment(onBoarding.birthdate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    })
  }

  return values

}
