import React from 'react'
import './DialogModal.scss'
import PrivateIcon from "./icons/PrivateIcon";
import {Modal} from "../ui/Modal/Modal";

type DialogModalStatus = {
  onCancel: () => void,
  onSubmit?: () => void,
  text: string,
  open: boolean
}

export const DialogModal = ({open, onCancel, onSubmit, text}: DialogModalStatus) => {

  return (
    <Modal
      onClose={onCancel}
      open={open}
      content={(
        <div className="dialog-modal">
          <div className="dialog-modal-icon">
            <PrivateIcon/>
          </div>
          <span className="dialog-modal-text">{text}</span>
          {onSubmit && (
            <div className="modal-bottom">
              <button className="modal-cancel-button profile-border-color profile-text-color" onClick={onCancel}>Cancel</button>
              <button className="modal-submit-button profile-background-color" onClick={onSubmit}>Submit</button>
            </div>
          )}
        </div>
      )}
    />
  )

}
