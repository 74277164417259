import React from "react";
import { getFullImageUrl } from "../../../utils";
import {PHOTO_TYPE} from "../../../types/models/photo";
import {PhotoLabel} from "../../../components/ui/PhotosInput/PhotosInput";

type ProfilePhotosType = {
  photos: PHOTO_TYPE[];
  type: "private" | "public";
  text?: boolean
};

export const TITLES = {
  private: "Private Photos",
  public: "Public Photos",
};

const TEXTS = {
  private:
    "Your photos are private. You can select to whom you like to see them privately.\n",
  public: "Your public photos can be seen by anyone. No nudes in here.",
};

export default function ProfilePhotos({ photos, type, text = true }: ProfilePhotosType) {
  return (
    <div className="profile-page-photo-area">
      <span className="profile-page-photo-area-label">{TITLES[type]}</span>
      {text ? (
        <span className="profile-page-photo-area-text">{TEXTS[type]}</span>
      ) : <span/>}
      <div className="profile-page-photo-area-elements">
        {photos.map((photo) => {
          return (
            <div className="profile-page-photo-area-element">
              {text && photo.adult ? (
                <PhotoLabel adult={photo.adult} adultStatus={photo.status}/>
              ) : <span/>}
              <img
                src={getFullImageUrl(photo.path)}
                alt=""
                className="profile-page-photo-area-element-image"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
