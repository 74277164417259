import * as React from "react"
const FavoriteIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.25}
        d="m12.25 17.75-6.172 3.245 1.18-6.873-5-4.867 6.9-1 3.085-6.253 3.086 6.253 6.9 1-5 4.867 1.18 6.873-6.159-3.245Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.25 0h24v24h-24z" />
      </clipPath>
    </defs>
  </svg>
)
export default FavoriteIcon
