import {ABOUT_URL, APPLE_STORE_URL, BLOG_URL, CONTACT_US_URL, PLAY_MARKET_URL} from "../../constants";
import "./Header.scss";
import MenuIcon from "../../icons/MenuIcon";
import { useDispatch } from "react-redux";
import { applicationToggleNavDrawer } from "../../reducers/application";

export function Header() {
  const dispatch = useDispatch();

  const toggleNavigationDrawer = (e: any) => {
    e.stopPropagation();
    dispatch(applicationToggleNavDrawer());
  };

  return (
    <header className="header">
      <div className="header-container container">
        <a href="/" className="header-logo">
          <img
            className="header-logo-image"
            src="/images/logo.svg"
            alt="HeyHeyHey log"
          />
        </a>
        <div className="header-menu-container">
          <ul className="header-menu">
            <li>
              <a href={ABOUT_URL}>About Us</a>
            </li>
            <li>
              <a href={BLOG_URL}>Blog</a>
            </li>
            <li>
              <a href={CONTACT_US_URL}>Contact Us</a>
            </li>
          </ul>
          <div className="header-menu-apps">
            <a href={PLAY_MARKET_URL} target={"_blank"} className="header-menu-apps-link">
              <img src="/images/apps/google-play.svg" alt=""/>
            </a>
            <a href={APPLE_STORE_URL} target={"_blank"} className="header-menu-apps-link">
              <img src="/images/apps/app-store.svg" alt=""/>
            </a>
          </div>
        </div>
        <div className="menu-icon-container" onClick={toggleNavigationDrawer}>
          <MenuIcon />
        </div>
      </div>
    </header>
  );
}
