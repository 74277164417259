import './SmallNotifications.scss'
import ErrorIcon from "./icons/ErrorIcon";
import CloseIcon from './icons/CloseIcon';
import SuccessIcon from "./icons/SuccessIcon";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {applicationPopNotificationAction, SMALL_NOTIFICATION_TYPE} from "../../reducers/application";

const SmallNotification = ({notification}: {notification: SMALL_NOTIFICATION_TYPE}) => {

  const dispatch = useDispatch()

  useEffect(() => {

    setTimeout(() => {
      popNotification()
    }, 3000)

  }, [])

  const popNotification = () => {
    dispatch(applicationPopNotificationAction({
      id: notification.id
    }))
  }

  return (
    <div className={`small-notification ${notification.type === 'success' ? 'success' : 'error'}`}>
      <div className="small-notification-icon">
        {notification.type === 'error' && <ErrorIcon/>}
        {notification.type === 'success' && <SuccessIcon/>}
      </div>
      <div className="small-notification-content">
        <span className="small-notification-title">{notification.title}</span>
        {notification.message && <span className="small-notification-message">{notification.message}</span>}
      </div>
      <div className="small-notification-close-icon" onClick={popNotification}>
        <CloseIcon/>
      </div>
    </div>
  )
}

export const SmallNotifications = () => {
  const notifications = useSelector(state => state.application.notifications)

  return (
    <div className='small-notifications-container'>
      {notifications.map((notification: SMALL_NOTIFICATION_TYPE) => {
        return (
          <SmallNotification key={notification.id} notification={notification}/>
        )
      })}
    </div>
  )

}
