import React, { useMemo, useState } from "react";
import {onChangeProfileValueType} from "../EditProfile";
import {PROFILE_VALUE} from "../../../types/models/profile";
import {DateField} from "../../../components/ui/DateField/DateField";
import { useDispatch } from "react-redux";
import { applicationSetDisableEditProfileSaveBtn } from "../../../reducers/application";

const WEIGHT_REGEX = new RegExp(/^\d{1,3}(\.\d{1,2})?$/);

interface EditProfileDetailsI {
  values: PROFILE_VALUE[],
  weightId: number,
  heightId: number,
  onChange: onChangeProfileValueType,
  onError?: (error: string | null) => void, 
}

export function EditProfileDetails({ values, weightId, heightId, onChange, onError } : EditProfileDetailsI) {
  const [error, setError] = useState<{ weight?: string | null, height?: string | null }>({});
  const dispatch = useDispatch();

  const weightStrValue = useMemo(() => (
    values.find(_value => _value.type_id === weightId)?.value || ''
  ), [values]);

  const heightStrValue = useMemo(() => (
    values.find(_value => _value.type_id === heightId)?.value || ''
  ), [values]);

  const changeError = (key: string, value: string | null) => {
    setError({ ...error, [key]: value });
  }

  const onWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const error = WEIGHT_REGEX.test(value) ? null : 'Invalid weight value';
    dispatch(applicationSetDisableEditProfileSaveBtn(!!error));
    changeError('weight', error);
    onChange(weightId, value);
  }

  const onHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const error = WEIGHT_REGEX.test(value) ? null : 'Invalid height value';
    dispatch(applicationSetDisableEditProfileSaveBtn(!!error));
    changeError('height', error);
    onChange(heightId, e.target.value);
  }

  return (
    <div className="edit-profile-details">
      <div className="edit-profile-details-col">
        <span className="edit-profile-details-label">Weight</span>
        <div className="edit-profile-details-input">
          <input
            type="text"
            id="weight"
            value={weightStrValue}
            onChange={onWeightChange}
          />
          <span className="edit-profile-details-input-label">lbs</span>
        </div>
        {error.weight && (
          <span style={{ color: '#F03131' }}>
            {error.weight}
          </span>
        )}
      </div>
      <div className="edit-profile-details-col">
        <span className="edit-profile-details-label">Height</span>
        <div className="edit-profile-details-input">
          <input
            type="text"
            id="height"
            value={heightStrValue}
            onChange={onHeightChange}
          />
          <span className="edit-profile-details-input-label">ft</span>
        </div>
        {error.height && (
          <span style={{ color: '#F03131' }}>
            {error.height}
          </span>
        )}
      </div>
    </div>
  )


}
