import React from "react";
import './AlertModal.scss'
import BlockIcon from "./icons/BlockIcon";

type AlertModalProps = {
  error: string,
  onClose: () => void
}

export const AlertModal = ({error, onClose}: AlertModalProps) => {

  return (
    <div className="alert-modal">
      <div className="alert-modal-icon">
        <BlockIcon/>
      </div>
      <span className="alert-modal-text">{error}</span>
      <div className="modal-bottom modal-bottom-centered">
        <button className="modal-submit-button profile-background-color" onClick={onClose}>OK</button>
      </div>
    </div>
  )

}
